import logoUrl from 'tol@assets/logo/timeone_header.svg';
import colors from '../../colors';

export default ({ primary, secondary }) => ({
  fontColor: primary,
  fontColorSecondary: secondary,
  backgroundColor: colors.white,
  logo: {
    url: logoUrl,
    backgroundColor: primary,
  },
});
