import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';

import { RenderMonthElementContainer, selectStyle, selectYearStyle } from './styles';

const RenderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
  <RenderMonthElementContainer>
    <span>
      <Select
        value={{ label: month.format('MMM'), value: month.month() }}
        onChange={option => {
          onMonthSelect(month, option.value);
        }}
        styles={selectStyle}
        options={moment.monthsShort().map((label, value) => ({ label, value }))}
      />
    </span>
    <span>
      <Select
        value={{ label: month.year(), value: month.year() }}
        onChange={option => {
          onYearSelect(month, option.value);
        }}
        styles={selectYearStyle}
        options={Array(10)
          .fill(0)
          .map((_, key) => ({ label: moment().year() - key, value: moment().year() - key }))}
      />
    </span>
  </RenderMonthElementContainer>
);

export default RenderMonthElement;

RenderMonthElement.propTypes = {
  month: PropTypes.shape({}).isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onYearSelect: PropTypes.func.isRequired,
};
