import React from 'react';

export default ({ title, ...props }) => (
  <svg {...props} height="25" width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
    <path
      fill="currentColor"
      d="M237.3 148.2c-3.1-3.1-7.3-4.8-11.7-4.8s-8.6 1.7-11.7 4.8l-46 46V17.5C168 8.4 160.6 1 151.5 1 142.4 1 135 8.4 135 17.5v176.7l-46-46c-6.4-6.4-16.9-6.4-23.3 0-3.1 3.1-4.8 7.3-4.8 11.7s1.7 8.6 4.8 11.7l72 72c3.1 4.7 8.2 7.4 13.8 7.4 5.6 0 10.7-2.8 13.8-7.4l72-72c3.1-3.1 4.8-7.3 4.8-11.7s-1.7-8.6-4.8-11.7zM15 297c-6.6 0-12-5.4-12-12v-3c0-6.6 5.4-12 12-12h270c6.6 0 12 5.4 12 12v3c0 6.6-5.4 12-12 12H15z"
    />
    <path
      fill="currentColor"
      d="M285 273c5 0 9 4 9 9v3c0 5-4 9-9 9H15c-5 0-9-4-9-9v-3c0-5 4-9 9-9h270m0-6H15c-8.3 0-15 6.7-15 15v3c0 8.3 6.7 15 15 15h270c8.3 0 15-6.7 15-15v-3c0-8.3-6.7-15-15-15z"
    />
    {title && <title id={title}>{title}</title>}
  </svg>
);
