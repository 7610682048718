import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatPercentage } from 'tol@utils/format';

const MetricEvolutionLayout = styled.div`
  align-items: center;
  background: ${({ evolution }) => (evolution >= 0 ? '#8CC152' : '#F4626C')};
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: space-around;
  margin: 0.5rem;
  min-width: 8rem;
  max-width: 16rem;
  padding: 1.25rem;
  width: 30%;

  * {
    color: #fff;
  }

  .metric-evolution__name {
    text-transform: capitalize;
    color: #fff;
    font-weight: 300;
    letter-spacing: 0.05rem;
  }

  .metric-evolution__value {
    font-size: 3rem;
    margin: 0.5rem 0;
  }

  .metric-evolution__evolution {
    ${({ evolution }) => !evolution && `visibility: hidden;`};
  }

  ${({ customStyle }) => customStyle};
`;

function MetricEvolution(props) {
  const { name, value, evolution, formatEvolution, className, customStyle } = props;

  return (
    <MetricEvolutionLayout
      className={`metric-evolution metric-evolution_${name} metric-evolution--${
        evolution >= 0 ? 'positive' : 'negative'
      } ${className}`}
      value={value}
      evolution={evolution}
      customStyle={customStyle}
    >
      <p className="metric-evolution__name">{name}</p>
      <p className="metric-evolution__value">{value}</p>
      <p className="metric-evolution__evolution">{evolution && formatEvolution({ value: evolution })}</p>
    </MetricEvolutionLayout>
  );
}

MetricEvolution.defaultProps = {
  name: '',
  value: null,
  evolution: null,
  formatEvolution: formatPercentage,
  className: '',
  customStyle: null,
};

MetricEvolution.propTypes = {
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.number,
  evolution: PropTypes.number,
  formatEvolution: PropTypes.func,
  className: PropTypes.string,
  // style formatted for styled components inside ``
  customStyle: PropTypes.string,
};

export default MetricEvolution;
