import styled from 'styled-components';

import { utils } from 'tol@themes';

export const HeaderContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-content: space-around;
  background-color: ${({ theme }) => theme.input.datepicker.header.backgroundColor};
  display: flex;
  padding: 1rem 0;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const MonthYearContainer = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
`;

export const DayContainer = styled.span`
  font-size: 0.625rem;
  opacity: 0.75;
`;

export const DateDay = styled.h2`
  font-size: 2rem;
  font-weight: normal;
  margin: 0 0.25rem 0 0;
`;

export const HeaderColumnDateContainer = styled.div`
  display: flex;
  min-height: 2.75rem;
`;

export const HeaderColumnTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0.5;
`;

export const HeaderColumnContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  color: ${({ theme }) => theme.input.datepicker.header.fontColor};
  margin: 0 1.5rem;
  width: 100%;
`;

export default {
  HeaderContainer,
  DayContainer,
  MonthYearContainer,
  DateContainer,
  DateDay,
  HeaderColumnDateContainer,
  HeaderColumnTitle,
  HeaderColumnContainer,
};
