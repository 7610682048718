import colors from '../colors';

export default ({ primary }) => ({
  button: {
    active: {
      fontColor: colors.white,
      backgroundColor: primary,
    },
  },
});
