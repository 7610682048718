import { getMeApi, updateUserApi, updatePasswordApi, getUserResourcesApi } from './user.api';
import { errorHandler } from './utils';

export const getMe = () =>
  getMeApi()
    .then(errorHandler)
    .then(data => data && data.getMe);

export const updateUser = user =>
  updateUserApi(user)
    .then(errorHandler)
    .then(data => data && data.updateUser);

export const updatePassword = (id, { password }) => updatePasswordApi({ id, password }).then(errorHandler);

export const getUserResources = id =>
  getUserResourcesApi(id)
    .then(errorHandler)
    .then(data => data && data.getUserResources);

export default { getMe, updateUser, updatePassword, getUserResources };
