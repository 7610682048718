import { lighten } from 'polished';

import colors from '../colors';

export default ({ primary, secondary }) => ({
  header: {
    menu: {
      fontColor: secondary,
      backgroundColor: primary,
      svg: {
        fontColor: primary,
        hover: {
          fontColor: lighten(0.125, primary),
        },
      },
      author: {
        fontColor: colors.white,
        backgroundColor: secondary,
      },
      date: {
        fontColor: secondary,
      },
      since: {
        fontColor: primary,
      },
      title: {
        fontColor: primary,
      },
      content: {
        fontColor: primary,
      },
      status: {
        fontColor: colors.white,
        backgroundColor: secondary,
      },
      list: {
        fontColor: primary,
        backgroundColor: colors.white,
        hover: {
          backgroundColor: colors.lightGray,
        },
      },
    },
  },
});
