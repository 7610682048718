import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as Animated from 'react-select/lib/animated';

import DefaultInput from '../DefaultInput';
import selectStyle from './styles';
import { isRequiredSelect } from '../utils';

export default class InputSelect extends DefaultInput {
  handleChange = (defaultOnChange, defaultOnBlur) => selectedOption => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ target: { value: selectedOption } });
    }

    defaultOnChange({ target: { value: selectedOption } });

    if (defaultOnBlur) {
      defaultOnBlur();
    }
  };

  handleValidate = value => {
    const { validation, required } = this.props;

    if (required && isRequiredSelect(value)) {
      return isRequiredSelect(value);
    }

    return validation ? validation(value) : undefined;
  };

  handleIsActive = ({ value }, { active }) => active || Object.keys(value).length > 0;

  inputRender = (inputProps, meta) => {
    const { className, config, name, options, isSearchable, isMulti } = this.props;
    const { onChange, onFocus, onBlur } = inputProps;

    return (
      <Select
        {...config}
        id={name}
        name={name}
        clearable
        className={`tol-input__select select ${this.handleIsActive(inputProps, meta) ? 'active' : ''} ${className}`}
        classNamePrefix="select"
        placeholder=""
        options={options}
        isSearchable={isSearchable}
        isMulti={isMulti}
        components={Animated}
        styles={selectStyle}
        {...inputProps}
        onChange={this.handleChange(onChange, onBlur)}
        onFocus={this.handleFocus(onFocus)}
        onBlur={this.handleBlur(onBlur)}
      />
    );
  };
}

InputSelect.defaultProps = {
  className: '',
  config: null,
  isSearchable: false,
  isMulti: false,
};

InputSelect.propTypes = {
  className: PropTypes.string,
  config: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
};
