import styled from 'styled-components';
import { utils } from 'tol@themes';

const Paper = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.2rem;
  box-shadow: ${({ theme }) => theme.shadows.dp2};
  overflow: hidden;
  text-align: center;
  transition: all 0.25s;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.dp3};
  }
`;

export default Paper;
