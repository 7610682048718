import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class SidebarContent extends PureComponent {
  _handleBackdropClick = event => {
    const { onBackdropClick } = this.props;

    return onBackdropClick(event);
  };

  _createClassName() {
    const { className, isSidebarOpen, hasBackdrop } = this.props;
    const classNameProps = ` ${className}`;
    const classModifierVisible = isSidebarOpen ? ' sidebar-content--open' : ' sidebar-content--hidden';
    const classModifierDocked = !hasBackdrop ? ' sidebar-content--no-backdrop' : ' sidebar-content--with-backdrop';

    return `sidebar-content-layout${classNameProps}${classModifierVisible}${classModifierDocked}`;
  }

  render() {
    const { hasBackdrop, children, render } = this.props;
    const className = this._createClassName();

    if (hasBackdrop) {
      return (
        <div className={className}>
          <div
            className="sidebar-content-layout__backdrop"
            onClick={this._handleBackdropClick}
            onKeyPress={this._handleBackdropClick}
            role="button"
            tabIndex={-1}
          />
          <div className="sidebar-content">{children}</div>
        </div>
      );
    }

    return (
      <div className={className}>
        {children}
        {render()}
      </div>
    );
  }
}

SidebarContent.defaultProps = {
  children: null,
  className: null,
  hasBackdrop: true,
  isSidebarOpen: false,
  onBackdropClick: () => null,
  position: 'right',
  render: () => null,
  width: '25%',
};

SidebarContent.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  hasBackdrop: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
  onBackdropClick: PropTypes.func,
  position: PropTypes.string, // eslint-disable-line react/no-unused-prop-types, used in StyledSidebarContent
  render: PropTypes.func,
  width: PropTypes.string, // eslint-disable-line react/no-unused-prop-types, used in StyledSidebarContent
};
