import React from 'react';

export default ({ title, ...props }) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M330.667 384h-21.333c-5.891 0-10.667 4.776-10.667 10.667v74.667h-256V42.667h256v74.667c0 5.891 4.776 10.667 10.667 10.667h21.333c5.891 0 10.667-4.776 10.667-10.667V42.667C341.333 19.103 322.231 0 298.667 0h-256C19.103 0 0 19.103 0 42.667v426.667C0 492.898 19.103 512 42.667 512h256c23.564 0 42.667-19.102 42.667-42.667v-74.667c-.001-5.89-4.776-10.666-10.667-10.666z"
    />
    <path
      fill="currentColor"
      d="M508.542 248.135l-128-117.333c-3.125-2.844-7.656-3.625-11.5-1.896-3.875 1.698-6.375 5.531-6.375 9.76V160c0 3.021 1.281 5.906 3.531 7.927l74.151 66.74H138.667c-5.896 0-10.667 4.771-10.667 10.667v21.333c0 5.896 4.771 10.667 10.667 10.667h301.682l-74.151 66.74c-2.25 2.021-3.531 4.906-3.531 7.927v21.333c0 4.229 2.5 8.063 6.375 9.76 1.375.615 2.844.906 4.292.906 2.615 0 5.198-.969 7.208-2.802l128-117.333C510.75 261.844 512 258.99 512 256s-1.25-5.844-3.458-7.865z"
    />
    {title && <title id={title}>{title}</title>}
  </svg>
);
