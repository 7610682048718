import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { useSidebar } from 'tol@framework/Sidebar/useSidebar';
import { useBreadcrumb } from 'tol@framework/Breadcrumb/useBreadcrumb';
import ApplicationLayoutContext from './ApplicationLayout.context';
import { useApplicationLayoutLoader } from './useApplicationLayoutLoader';

function ApplicationLayoutProvider({ children, history }) {
  const context = {
    breadcrumb: useBreadcrumb(),
    router: { history },
    rightSidebar: useSidebar(),
    loader: useApplicationLayoutLoader(),
  };
  const [location, setLocation] = useState(null);

  function handleHistoryChange(nextLocation) {
    if (!location || (nextLocation && nextLocation.key !== location.key)) {
      context.rightSidebar.close();
      setLocation(nextLocation);
    }
  }

  useEffect(() => {
    if (history && history.listen) {
      return history.listen(handleHistoryChange);
    }

    return () => {};
  });

  return <ApplicationLayoutContext.Provider value={{ ...context }}>{children}</ApplicationLayoutContext.Provider>;
}

export default withRouter(ApplicationLayoutProvider);

ApplicationLayoutProvider.defaultProps = {
  history: {},
  children: null,
};

ApplicationLayoutProvider.propTypes = {
  history: PropTypes.shape({}),
  children: PropTypes.element,
};
