import React from 'react';
import PropTypes from 'prop-types';

import elements from 'tol@elements';
import Form from 'tol@blocks/Form';
import { SidebarHeader, SidebarContent, SidebarFooter } from 'tol@framework/Sidebar';
import { SideBarFormContainer, ButtonsContainer } from './styles';

const { Button } = elements;

export const FOOTER_BUTTONS_ID = 'sidebar-form-blocks-buttons';

export default function SidebarForm({ title, onSubmit, initialValues, fields }) {
  return (
    <SideBarFormContainer>
      <SidebarHeader>{title}</SidebarHeader>
      <SidebarContent>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          portalId={FOOTER_BUTTONS_ID}
          renderButtons={({ handleSubmit, form, submitting, pristine, invalid }) => (
            <ButtonsContainer>
              <Button
                primary
                appearance="cancel"
                className="form__last-button"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
                Reset
              </Button>
              <Button primary fill disabled={submitting || pristine || invalid} onClick={handleSubmit}>
                Submit
              </Button>
            </ButtonsContainer>
          )}
        >
          {fields}
        </Form>
      </SidebarContent>
      <SidebarFooter>
        <div id={FOOTER_BUTTONS_ID} />
      </SidebarFooter>
    </SideBarFormContainer>
  );
}

SidebarForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(PropTypes.element).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
