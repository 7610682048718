import { lighten } from 'polished';

import logoUrl from 'tol@assets/icon/user.svg';
import colors from '../colors';

export default ({ primary, secondary }) => ({
  header: {
    menu: {
      fontColor: primary,
      backgroundColor: colors.white,
      svg: {
        fontColor: colors.white,
        backgroundColor: secondary,
        hover: {
          backgroundColor: lighten(0.125, secondary),
        },
      },
      logo: {
        url: logoUrl,
        backgroundColor: primary,
      },
      hover: {
        backgroundColor: colors.lightGray,
      },
    },
  },
});
