import styled from 'styled-components';
import { lighten } from 'polished';

import { utils } from 'tol@themes';

export const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: 1;
  right: 0;
`;

export default styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  padding-top: 0.66rem;
  display: flex;
  width: 100%;

  &.DateRangePickerWithSubmit {
    .DayPicker {
      padding-bottom: 50px;
    }
  }

  &.DateRangePickerWithButton {
    flex-direction: column;

    button.DateRangePickerButton {
      margin: 0;
    }

    .DayPicker {
      position: absolute;
      top: 60px;
      z-index: 1;
    }
  }

  .DateRangePicker {
    display: flex;
    flex: 1;
    width: 100%;
    z-index: unset;

    .DateRangePickerInput,
    & > div {
      display: flex;
      flex: 1;
      width: 100%;
    }
  }

  .DateRangePickerInput {
    background: none;
    border-bottom: 1px solid;
    padding-right: 0;

    .DateInput {
      background: none;
      cursor: pointer;
      margin-right: 2rem;
      width: auto;

      input {
        background: none;

        &.DateInput_input__focused {
          border: none;
        }
      }
    }

    .DateRangePickerInput_arrow {
      align-items: center;
      display: flex;

      svg {
        width: 1rem;
      }
    }

    .DateRangePickerInput_arrow + .DateInput {
      margin-left: 1rem;
      margin-right: auto;
    }

    button {
      align-items: center;
      background: transparent;
      display: flex;
      justify-content: center;
      margin: 0.33rem;
      padding: 0 0.66rem;
      position: relative;
      right: 0;
      top: 0;
      transform: none;
      transition: all 0.25s ease;

      &.DateRangePickerInput_calendarIcon {
        order: 2;
      }

      svg {
        fill: ${({ theme }) => theme.input.datepicker.icon.backgroundColor};
        width: 16px;
      }

      &:hover {
        background-color: ${({ theme }) => lighten(0.6, theme.input.datepicker.icon.backgroundColor)};
        border-radius: 50%;
      }
    }
  }

  .DayPicker {
    border-radius: 2px;
    border: none;
    box-shadow: ${({ theme }) => theme.shadows.dp1};
    outline: none;

    .DayPicker_weekHeader {
      top: 3.25rem;
    }

    &:hover {
      border: none;
    }

    & > div {
      display: flex;
      flex-direction: column;

      .CalendarMonthGrid {
        border-collapse: separate;
        border-spacing: 1px;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;

        .CalendarMonth_caption {
          font-size: 0.875rem;
          padding-top: 15px;
        }

        .CalendarDay {
          border-radius: 100%;
          border: none;
          height: 80%;
          transition: all 0.1s ease-in-out;
          width: 80%;

          &:hover {
            border: none;
            background-color: ${({ theme }) => theme.input.datepicker.calendarDay.hover.backgroundColor};
          }

          &.CalendarDay__blocked_out_of_range {
            &:hover {
              background: none;
            }
          }

          &:active {
            border: none;
          }

          &:focus {
            outline: none;
          }
        }

        .CalendarDay__selected {
          background: ${({ theme }) => theme.input.datepicker.calendarDay.selected.backgroundColor};
        }

        .CalendarDay__selected_span,
        .CalendarDay__hovered_span {
          &:not(.CalendarDay__blocked_calendar) {
            background-color: ${({ theme }) => theme.input.datepicker.calendarDay.selected.backgroundColor};
            color: ${({ theme }) => theme.input.datepicker.calendarDay.selected.fontColor};
          }
        }
      }

      .DayPickerNavigation_button {
        border: none;
      }
    }
  }
`;
