import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/lib/Creatable';
import * as Animated from 'react-select/lib/animated';

import InputSelect from '../InputSelect';
import selectStyle from '../InputSelect/styles';
import { CreateLabel, ErrorLabel, SuccessLabel } from './components';

export default class InputSelectCreatable extends InputSelect {
  state = {
    isLoading: false,
    createResponse: null,
  };

  handleCreate = defaultOnChange => async inputValue => {
    const { onCreate } = this.props;

    this.setState({ isLoading: true });

    const createResponse = await onCreate(inputValue);

    this.setState({ createResponse, isLoading: false });

    setTimeout(() => {
      this.setState({
        createResponse: null,
        isLoading: false,
      });
    }, 1000);

    return defaultOnChange({
      target: {
        value: {
          label: inputValue,
          value: inputValue,
        },
      },
    });
  };

  isCreateResponseValid = createResponse => typeof createResponse === 'boolean';

  isMenuOpen = () => {
    const { createResponse, isLoading } = this.state;

    if (this.isCreateResponseValid(createResponse)) {
      return true;
    }

    if (isLoading) {
      return false;
    }

    return undefined;
  };

  renderCustomLabel = label => {
    const { createResponse } = this.state;

    return [
      {
        label: createResponse ? <SuccessLabel label={label} /> : <ErrorLabel label={label} />,
      },
    ];
  };

  inputRender = (inputProps, meta) => {
    const { className, config, name, options } = this.props;
    const { createResponse, isLoading } = this.state;
    const { onChange, onFocus, onBlur, value } = inputProps;

    return (
      <CreatableSelect
        {...config}
        className={`tol-input__select tol-input__selectcreatable select ${
          this.handleIsActive(inputProps, meta) ? 'active' : ''
        } ${className || ''}`}
        components={Animated}
        formatCreateLabel={inputValue => (
          <CreateLabel label={inputValue} onYesClick={() => this.handleCreate(onChange)(inputValue)} />
        )}
        id={name}
        isClearable
        isDisabled={isLoading || this.isCreateResponseValid(createResponse)}
        isLoading={isLoading}
        name={name}
        placeholder=""
        styles={selectStyle}
        {...inputProps}
        closeMenuOnSelect={this.isCreateResponseValid(createResponse) && false}
        menuIsOpen={this.isMenuOpen()}
        onChange={this.handleChange(onChange, onBlur)}
        onCreateOption={() => null}
        onFocus={this.handleFocus(onFocus)}
        onBlur={this.handleBlur(onBlur)}
        options={this.isCreateResponseValid(createResponse) ? this.renderCustomLabel(value.label) : options}
      />
    );
  };
}

InputSelectCreatable.defaultProps = {
  isSearchable: false,
  isMulti: false,
};

InputSelectCreatable.propTypes = {
  onCreate: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
};
