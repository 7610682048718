import { useState } from 'react';

export const applicationLayoutLoaderContext = {
  isLoading: false,
  start: () => {},
  stop: () => {},
  content: '',
  setContent: () => {},
};

export function useApplicationLayoutLoader() {
  const [loader, setLoader] = useState({
    isLoading: false,
    loaderTimestamp: null,
    content: '',
  });

  function setLoaderState(isLoading, content) {
    setLoader({
      isLoading,
      content: isLoading ? content : '',
    });

    return true;
  }

  return {
    ...loader,
    start: newContent => setLoaderState(true, newContent),
    stop: () => setLoaderState(false),
  };
}
