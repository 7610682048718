import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { LabelContainer, Label, LabelSpan, LabelError, Input, Optional } from './styles';

import { isRequired } from '../utils';

export default class DefaultInput extends PureComponent {
  inputRender = (inputProps, meta) => {
    const { name, type } = this.props;
    const { onChange, onFocus, onBlur } = inputProps;

    return (
      <Input
        error={this.handleIsInValide(meta)}
        className={this.handleIsActive(inputProps, meta) ? 'active' : null}
        id={name}
        name={name}
        type={type}
        {...inputProps}
        onChange={this.handleChange(onChange, onBlur)}
        onBlur={this.handleBlur(onBlur)}
        onFocus={this.handleFocus(onFocus)}
      />
    );
  };

  handleChange = (defaultOnChange, defaultOnBlur) => event => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(event);
    }

    if (defaultOnChange) {
      defaultOnChange(event);
    }

    // Here we to return default onBlur in onChange to prevent async onBlur validation which does not allow submit on Chrome
    // TODO: This hack need more investigation.
    if (defaultOnBlur) {
      defaultOnBlur(event);
    }
  };

  handleFocus = defaultOnFocus => event => {
    const { onFocus } = this.props;

    if (onFocus) {
      onFocus(event);
    }

    return defaultOnFocus && defaultOnFocus(event);
  };

  handleBlur = defaultOnBlur => event => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(event);
    }

    return defaultOnBlur && defaultOnBlur(event);
  };

  handleIsActive = ({ value }, { active }) => active || value.length > 0;

  handleIsInValide = ({ error, submitError, dirtySinceLastSubmit, touched }) =>
    touched && (error || (!dirtySinceLastSubmit && submitError));

  handleValidate = value => {
    const { validation, required } = this.props;

    const requiredTest = isRequired(value);

    if (required && requiredTest) {
      return requiredTest || undefined;
    }

    return validation ? validation(value) : undefined;
  };

  render() {
    const { name, label, required, className, withButton } = this.props;

    return (
      <Field
        name={name}
        subscription={{
          touched: true,
          active: true,
          data: true,
          initial: true,
          error: true,
          submitError: true,
          dirtySinceLastSubmit: true,
        }}
      >
        {({ meta }) => (
          <Field name={name} validate={this.handleValidate}>
            {({ input }) => (
              <LabelContainer className={`tol-input-container ${className}`}>
                {!withButton ? (
                  <Label
                    className={`tol-input ${this.handleIsActive(input, meta) ? 'active' : null}`}
                    error={this.handleIsInValide(meta)}
                    htmlFor={name}
                  >
                    <LabelSpan required={required} className={this.handleIsActive(input, meta) ? 'active' : null}>
                      {label}
                      {!required && <Optional className="to-defaultinput__option-label">(optional)</Optional>}
                    </LabelSpan>

                    {this.inputRender(input, meta)}
                  </Label>
                ) : (
                  <div>{this.inputRender(input, meta)}</div>
                )}
                {this.handleIsInValide(meta) ? (
                  <LabelError className="to-defaultinput__label-error">
                    {meta.error || (!meta.dirtySinceLastSubmit && meta.submitError)}
                  </LabelError>
                ) : (
                  ''
                )}
              </LabelContainer>
            )}
          </Field>
        )}
      </Field>
    );
  }
}

DefaultInput.defaultProps = {
  className: '',
  label: '',
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  required: false,
  type: 'text',
  validation: () => undefined,
  withButton: false,
};

DefaultInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  validation: PropTypes.func,
  withButton: PropTypes.bool,
};
