import applicationLayout from './applicationLayout';
import button from './button';
import colors from '../colors';
import footer from './footer';
import form from './form';
import header from './header';
import input from './input';
import headerNavbar from './headerNavbar';
import notification from './notification';
import pagination from './pagination';
import sidebarNav from './sidebarNav';
import shadows from '../shadows';
import snackbar from './snackbar';
import table from './table';
import user from './user';

const hover = ({ primary }) => ({
  fontColor: colors.white,
  backgroundColor: primary,
});

export default ({ primary, secondary }) => ({
  name: 'Default',
  applicationLayout,
  backgroundColor: colors.white,
  button: button({ primary, secondary }),
  colors,
  fontColor: primary,
  footer: footer({ primary, secondary }),
  form: form({ primary, secondary }),
  header: header({ primary, secondary }),
  headerNavbar: headerNavbar({ primary, secondary }),
  hover: hover({ primary, secondary }),
  input: input({ primary, secondary }),
  notification: notification({ primary, secondary }),
  pagination: pagination({ primary }),
  primary,
  secondary,
  shadows,
  sidebarNav: sidebarNav({ primary, secondary }),
  snackbar,
  table,
  user: user({ primary, secondary }),
});
