import React from 'react';
import PropTypes from 'prop-types';

import { COLUMN_DEFAULT_PROPERTIES } from 'tol@blocks/Table/constants';
import { Layout, RowValue, TotalLabel } from './styles';

const RowTotal = props => {
  const { labelWidth, values } = props;

  return (
    <Layout {...props}>
      <TotalLabel width={labelWidth}>Total</TotalLabel>
      {values.map((totalValue, index) => {
        const {
          align = COLUMN_DEFAULT_PROPERTIES.align,
          dataKey,
          width = COLUMN_DEFAULT_PROPERTIES.width,
          value = '',
          render,
        } = totalValue;

        if (render) {
          return render({ ...totalValue, index });
        }

        return (
          <RowValue
            align={align}
            width={width}
            key={dataKey}
            className={`${values[index + 1] && values[index + 1].value ? 'tol-row-value__has-next-value' : ''} ${
              value ? 'tol-row-value__has-value' : ''
            }`}
          >
            {value}
          </RowValue>
        );
      })}
    </Layout>
  );
};

export default RowTotal;

RowTotal.defaultProps = {
  className: '',
  labelWidth: COLUMN_DEFAULT_PROPERTIES.width,
};

RowTotal.propTypes = {
  className: PropTypes.string,
  labelWidth: PropTypes.number,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.oneOf(['left', 'center', 'right']),
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      render: PropTypes.func,
    })
  ).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
