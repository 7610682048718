import AddIcon from './Add';
import ArchivageIcon from './Archivage';
import ArrowBackIcon from './ArrowBack';
import ArrowIcon from './Arrow';
import BurgerIcon from './Burger';
import CalendarIcon from './Calendar';
import CheckIcon from './Check';
import CloneIcon from './Clone';
import CloseIcon from './Close';
import CodeIcon from './Code';
import CommentIcon from './Comment';
import DownloadIcon from './Download';
import EditIcon from './Edit';
import ExportIcon from './Export';
import FilterIcon from './Filter';
import GroupIcon from './Group';
import InformationIcon from './Information';
import LoadingIcon from './Loading';
import LogoutIcon from './Logout';
import MessageIcon from './Message';
import NotificationIcon from './Notification';
import RefreshIcon from './Refresh';
import SearchIcon from './Search';
import WarningIcon from './Warning';

export default {
  AddIcon,
  ArchivageIcon,
  ArrowBackIcon,
  ArrowIcon,
  BurgerIcon,
  CalendarIcon,
  CheckIcon,
  CloneIcon,
  CloseIcon,
  CodeIcon,
  CommentIcon,
  DownloadIcon,
  EditIcon,
  ExportIcon,
  FilterIcon,
  GroupIcon,
  InformationIcon,
  LoadingIcon,
  LogoutIcon,
  MessageIcon,
  NotificationIcon,
  RefreshIcon,
  SearchIcon,
  WarningIcon,
};
