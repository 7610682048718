import styled from 'styled-components';

export const SeparatorContainer = styled.span`
  padding: 0 5px;
`;

export const BreadcrumbContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;

  & > * {
    opacity: 0.65;

    &:last-child {
      opacity: 1;
    }
  }

  button {
    display: flex;
  }
`;
