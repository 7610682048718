import styled from 'styled-components';
import { lighten } from 'polished';

import { utils } from 'tol@themes';

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const MonthYearContainer = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
`;

export const DayContainer = styled.span`
  font-size: 0.625rem;
  opacity: 0.75;
`;

export const DateDay = styled.h2`
  font-size: 2rem;
  line-height: 4rem;
  font-weight: normal;
  margin: 0 0.25rem 0 0;
`;

export const HeaderColumnDateContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  display: flex;

  justify-content: center;
  min-height: 4rem;
  background: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.input.datepicker.header.backgroundColor},
    ${({ theme }) => lighten(0.2, theme.input.datepicker.header.backgroundColor)}
  );
  color: ${({ theme }) => theme.input.datepicker.header.fontColor};
`;

export const DatePickerContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  padding-top: 0.66rem;
  display: flex;
  width: 100%;

  .SingleDatePicker {
    display: flex;
    flex: 1;
    width: 100%;

    .SingleDatePickerInput,
    & > div {
      display: flex;
      flex: 1;
      width: 100%;
    }
  }

  .SingleDatePickerInput {
    background: none;
    padding-right: 0;

    .DateInput {
      background: none;

      input {
        background: none;

        &.DateInput_input__focused {
          border: none;
        }
      }
    }

    button {
      align-items: center;
      align-items: center;
      background: transparent;
      display: flex;
      justify-content: center;
      margin: 0.33rem;
      padding: 0 0.66rem;
      position: relative;
      right: 0;
      top: 0;
      transform: none;
      transition: all 0.25s ease;
      z-index: 1;

      &.SingleDatePickerInput_calendarIcon {
        order: 2;
      }

      svg {
        fill: ${({ theme }) => theme.input.datepicker.icon.backgroundColor};
        width: 1rem;
      }

      &:hover {
        background-color: ${({ theme }) => lighten(0.6, theme.input.datepicker.icon.backgroundColor)};
        border-radius: 50%;
      }
    }
  }

  .DayPicker {
    border-radius: 2px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    outline: none;

    &:hover {
      border: none;
    }

    & > div {
      display: flex;
      flex-direction: column-reverse;

      /* .DayPicker__header {
        background: linear-gradient(
          to bottom right,
          ${({ theme }) => theme.input.datepicker.header.backgroundColor},
          ${({ theme }) => lighten(0.2, theme.input.datepicker.header.backgroundColor)}
        );
        color: ${({ theme }) => theme.input.datepicker.header.fontColor};
        flex-grow: 1;
        padding: 0.75rem;
        width: calc(100% - 1.5rem);

        .DayPicker__header__year {
          font-size: 1rem;
          font-weight: lighter;
          margin: 0.25rem 0;
          min-height: 1.25rem;
          opacity: 0.8;
        }

        .DayPicker__header__date {
          color: ${({ theme }) => theme.input.datepicker.header.fontColor};
          font-weight: lighter;
          font-size: 1.75rem;
          min-height: 2rem;
        }
      } */

      .CalendarMonthGrid {
        border-collapse: separate;
        border-spacing: 1px;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;

        .CalendarDay {
          border-radius: 100%;
          border: none;
          height: 80%;
          transition: all 0.1s ease-in-out;
          width: 80%;

          &:hover {
            border: none;
            background-color: ${({ theme }) => theme.input.datepicker.calendarDay.hover.backgroundColor};
          }

          &.CalendarDay__blocked_out_of_range {
            &:hover {
              background: none;
            }
          }

          &:active {
            border: none;
          }

          &:focus {
            outline: none;
          }
        }

        .CalendarDay__selected {
          background: ${({ theme }) => theme.input.datepicker.calendarDay.selected.backgroundColor};
        }

        .CalendarDay__selected_span {
          background-color: ${({ theme }) => theme.input.datepicker.calendarDay.selected.backgroundColor};
          color: ${({ theme }) => theme.input.datepicker.calendarDay.selected.fontColor};
        }
      }

      .DayPickerNavigation_button {
        border: none;
      }
    }
  }
`;

export default {
  DatePickerContainer,
  DayContainer,
  MonthYearContainer,
  DateContainer,
  DateDay,
  HeaderColumnDateContainer,
};
