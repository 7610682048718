import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { Form as ReactForm } from 'react-final-form';

import Button from 'tol@elements/Button';
import Portal from 'tol@utils/Components/Portal';
import { FormButtonStyled } from './styles';

export const renderDefaultButtons = ({ handleSubmit, form, submitting, pristine, invalid, dirtySinceLastSubmit }) => (
  <FormButtonStyled>
    <Button
      primary
      appearance="cancel"
      className="form__last-button"
      onClick={form.reset}
      disabled={submitting || pristine}
    >
      Reset
    </Button>
    <Button primary fill disabled={submitting || pristine || (invalid && !dirtySinceLastSubmit)} onClick={handleSubmit}>
      Submit
    </Button>
  </FormButtonStyled>
);

renderDefaultButtons.defaultProps = {
  form: {},
  submitting: null,
  pristine: null,
  invalid: null,
  dirtySinceLastSubmit: null,
  handleSubmit: () => {},
};

renderDefaultButtons.propTypes = {
  form: PropTypes.shape({}),
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  dirtySinceLastSubmit: PropTypes.bool,
  handleSubmit: PropTypes.func,
};
export default class Form extends PureComponent {
  render() {
    const { children, initialValues, mutators, onSubmit, renderButtons, className, render, portalId } = this.props;

    return (
      <ReactForm
        onSubmit={onSubmit}
        mutators={mutators}
        initialValues={initialValues}
        render={({ handleSubmit, ...rest }) => (
          <form className={className} onSubmit={handleSubmit}>
            <div>
              {render && render({ handleSubmit, ...rest })}
              {children}
            </div>
            {portalId ? (
              <Portal portalId={portalId}>{renderButtons({ handleSubmit, ...rest })}</Portal>
            ) : (
              renderButtons({ handleSubmit, ...rest })
            )}
          </form>
        )}
      />
    );
  }
}

Form.defaultProps = {
  className: '',
  initialValues: {},
  mutators: {},
  portalId: null,
  renderButtons: renderDefaultButtons,
  render: () => {},
  children: null,
};

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  initialValues: PropTypes.shape({}),
  mutators: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  portalId: PropTypes.string,
  renderButtons: PropTypes.func,
  render: PropTypes.func,
};
