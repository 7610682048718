import styled from 'styled-components';

import { utils } from 'tol@themes';

export const HeaderContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 10px;
`;

export const FixContainer = styled.div`
  position: fixed;
`;

export const HeaderChildrenContainer = styled.div`
  flex: 1;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

export const FooterContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  border-top: 1px solid ${({ theme }) => theme.primary};
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;

  svg {
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export default {
  HeaderContainer,
  HeaderChildrenContainer,
  OptionsContainer,
  FixContainer,
  SidebarContainer,
  FooterContainer,
};
