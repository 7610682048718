import { lighten } from 'polished';

import colors from '../colors';

const hover = ({ primary, secondary }) => ({
  fontColor: { primary: colors.white, secondary: colors.white },
  backgroundColor: { primary, secondary },
});

const fill = ({ primary, secondary }) => ({
  fontColor: { primary: colors.white, secondary: colors.white },
  backgroundColor: { primary, secondary },
  hover: {
    fontColor: { primary: colors.white, secondary: colors.white },
    backgroundColor: { primary: lighten(0.125, primary), secondary: lighten(0.125, secondary) },
  },
});

const border = ({ primary, secondary }) => ({
  type: 'solid',
  size: '1px',
  color: { primary, secondary },
});

const disabled = {
  fontColor: { primary: colors.white, secondary: colors.white },
  backgroundColor: { primary: colors.gray, secondary: colors.gray },
};

const cancel = ({ primary, secondary }) => ({
  fontColor: { primary, secondary },
  backgroundColor: 'transparent',
  hover: {
    fontColor: { primary: colors.white, secondary: colors.white },
    backgroundColor: { primary, secondary },
  },
  disabled: {
    fontColor: { primary: colors.gray, secondary: colors.gray },
    backgroundColor: { primary: 'transparent', secondary: 'transparent' },
  },
});

export default ({ primary, secondary }) => ({
  fontColor: { primary, secondary },
  backgroundColor: { primary: 'transparent', secondary: 'transparent' },
  border: border({ primary, secondary }),
  cancel: cancel({ primary, secondary }),
  disabled,
  fill: fill({ primary, secondary }),
  hover: hover({ primary, secondary }),
});
