import styled, { css } from 'styled-components';

import { utils } from 'tol@themes';

export default styled.div.attrs(({ theme, user }) => ({
  theme: utils.themeFallback(theme),
  user,
}))`
  position: relative;
  margin: 0 1rem;

  &:hover {
    .header__header-right__user-layout__user-profile {
      background-color: ${({ theme }) => theme.user.header.menu.svg.hover.backgroundColor};
    }

    .header__header-right__user-layout__menu {
      opacity: 1;
      top: 3rem;
      visibility: visible;
    }
  }

  ${({ user, theme }) => userProfile(user, theme.user.header.menu.svg)};
  ${({ user, theme }) => userMenu(user, theme.user.header.menu)};
`;

function userProfile(user, svg) {
  return css`
    .header__header-right__user-layout__user-profile {
      ${user.photoUrl ? `background-image: url("${user.photoUrl}")` : `background-color: ${svg.backgroundColor}`};
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      display: block;
      height: 2.5rem;
      overflow: hidden;
      width: 2.5rem;

      svg {
        fill: ${svg.fontColor};
        margin-top: 0.5rem;
        width: 85%;
        height: 85%;
      }
    }
  `;
}

function userMenu(user, menu) {
  return css`
    .header__header-right__user-layout__menu {
      background-color: ${menu.backgroundColor};
      color: ${menu.fontColor};
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      border-radius: 2px;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 2.75rem;
      transition: all 0.25s ease;
      visibility: hidden;
      z-index: 3;

      li {
        font-size: 1rem;

        a,
        button,
        div {
          padding: 0.75rem;
        }

        button {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          margin: 0;
          width: 8rem;

          svg {
            color: ${menu.fontColor};
            height: 1rem;
            width: 1rem;
          }

          span {
            display: block;
            height: 100%;
            margin-left: 1rem;
            text-align: left;
            text-transform: capitalize;
            width: 100%;
          }

          &:hover {
            background-color: ${menu.hover.backgroundColor};
            color: ${menu.hover.fontColor};

            svg {
              fill: ${menu.hover.fontColor};
              height: 1rem;
              width: 1rem;
            }
          }
        }
      }
    }
  `;
}
