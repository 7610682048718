import styled from 'styled-components';

function getFlexDirection(menuPosition) {
  let flexDirection;

  switch (menuPosition) {
    case 'top':
      flexDirection = 'column';
      break;
    case 'bottom':
      flexDirection = 'column-reverse';
      break;
    case 'left':
      flexDirection = 'row';
      break;
    case 'right':
      flexDirection = 'row-reverse';
      break;
    default:
      flexDirection = 'row';
      break;
  }

  return flexDirection;
}

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ menuPosition }) => getFlexDirection(menuPosition)};
`;

export default { TabsContainer };
