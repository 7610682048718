import { lighten } from 'polished';
import colors from '../colors';

export default ({ primary }) => ({
  header: {
    fontColor: primary,
    lightenFontColor: lighten(0.6, primary),
    borderColor: primary,
    lightBorderColor: lighten(0.6, primary),
    active: {
      fontColor: colors.white,
      backgroundColor: primary,
    },
  },
});
