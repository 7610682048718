import styled from 'styled-components';
import { transparentize, lighten } from 'polished';

import { utils, colors } from 'tol@themes';
import { SidebarContainer } from 'tol@framework/Sidebar';

const ContentWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ErrorContainer = styled.header`
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
`;

const CenteredContainer = styled.div`
  margin-top: 10rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SideBarFormContainer = styled(SidebarContainer)`
  .tol-sidebar__footer {
    > div > div {
      width: 100%;
    }
  }

  .tol-sidebar__content {
    > form {
      margin-left: 1rem;
      margin-right: 1rem;
      width: 100%;

      > div {
        display: flex;
        flex-direction: column;
      }
    }

    > form > div > label {
      margin-bottom: 0.5rem;

      &.tol-generate-form__select {
        width: 130px;
        margin: 0 0 0.5rem 0;

        .select__single-value,
        .select__multi-value,
        .select__option {
          font-size: small;
        }
      }

      &.tol-generate-form__autocomplete {
        max-width: 250px;
        margin: 0 0 0.5rem 0;

        .autocomplete__single-value,
        .autocomplete__multi-value,
        .autocomplete__menu-notice,
        .autocomplete__menu-notice--no-options,
        .autocomplete__option {
          font-size: small;
        }
      }

      &.tol-input__checkbox {
        .input-check-mark {
          height: 0.7rem;
          width: 0.7rem;

          &::after {
            height: 0.5rem;
            width: 0.2rem;
            left: 0.2rem;
          }
        }
      }
    }

    & > span {
      font-size: small;
    }
  }
`;

const TableBottomMessage = styled.div`
  text-align: center;
`;

const TableContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  margin-bottom: 1rem;
  text-align: center;
  flex: 1 1 auto;

  .tol-dashboard__row-total {
    span {
      border-right: 1px solid ${({ theme }) => theme.table.header.backgroundColor};

      &.tol-row-value__has-next-value,
      &.tol-row-value__has-value {
        border-right: 1px solid ${({ theme }) => theme.table.container.border.color};
      }

      &:last-child {
        border: none;
      }
    }
  }

  &#tolDashboardTableContainer {
    > div {
      margin-bottom: 1rem;
    }

    .ReactVirtualized__Table__row {
      &:hover {
        background-color: ${({ theme }) => transparentize(0.05, theme.primary)};
        color: ${colors.white};

        .ReactVirtualized__Table__rowColumn {
          border-right: 1px solid ${({ theme }) => lighten(0.5, theme.table.container.border.color)};

          &:last-child {
            border: none;
          }
        }
      }
    }

    .ReactVirtualized__Table__rowColumn,
    .ReactVirtualized__Table__headerColumn {
      border-right: 1px solid ${({ theme }) => theme.table.container.border.color};

      &:last-child {
        border: none;
      }
    }

    .ReactVirtualized__Table__rowColumn,
    .ReactVirtualized__Table__headerColumn,
    .ReactVirtualized__Table__sortableHeaderColumn,
    .tol-dashboard__row-total > span {
      height: calc(100% - 7px);
      padding-top: 7px;
    }
  }
`;

const PeriodPickerContainer = styled.div`
  margin-left: auto;

  .DateRangePickerInput {
    border: none;
  }

  .DateRangePickerWithButton {
    padding-top: 0;

    .DateRangePicker {
      margin-bottom: 0 !important;
    }

    .DayPicker {
      right: 0;
    }
  }

  label {
    margin: 0.75rem 0 0.25rem;

    &::after {
      display: none;
    }
  }
`;

const PeriodPickerFormContainer = styled.div`
  form {
    display: flex;
    align-items: flex-end;
  }

  .DateInput_input {
    font-size: 0.9rem;
  }

  .form__last-button {
    display: none;
  }

  .DateRangePickerInput_calendarIcon_svg {
    margin-bottom: 0.4rem;
  }
`;

const HeaderContainer = styled.header.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;

  h3 {
    margin-bottom: auto;
  }

  .dashboard-header__controls {
    margin-top: auto;
    & > * {
      color: ${({ theme }) => theme.button.fill.backgroundColor.primary};
      width: 1.25rem;
      margin-left: 1rem;

      &:hover {
        color: ${({ theme }) => theme.button.fill.hover.backgroundColor.primary};
      }
    }
  }

  .button__loading {
    cursor: default;
  }
`;

export {
  ContentWrapper,
  CenteredContainer,
  ErrorContainer,
  ButtonsContainer,
  SideBarFormContainer,
  TableContainer,
  TableBottomMessage,
  PeriodPickerContainer,
  PeriodPickerFormContainer,
  HeaderContainer,
};
