export default {
  canNotGo,
  displayPageNumber,
};

function canNotGo(state) {
  return state === false || state === undefined;
}

function displayPageNumber(offset, numberToDisplayPerPage, pageNumber) {
  return offset > numberToDisplayPerPage ? offset / numberToDisplayPerPage + pageNumber + 1 : pageNumber + 1;
}
