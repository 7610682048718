import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LogoutIcon from 'tol@assets/icon/Logout';
import EditIcon from 'tol@assets/icon/Edit';
import UserHeaderMenuStyled from './styles';

export default class UserHeaderMenu extends PureComponent {
  handleLogoutClick = () => {
    const { onLogout } = this.props;

    if (onLogout) {
      onLogout();
    }
  };

  render() {
    const { user } = this.props;

    return (
      <UserHeaderMenuStyled user={user} className="header__header-right__user-layout">
        <button type="button" className="header__header-right__user-layout__user-profile">
          {!user.photoUrl && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
            </svg>
          )}
        </button>

        <ul className="header__header-right__user-layout__menu header__header-right__user-layout__menu_visible">
          {user.name && (
            <li className="header__header-right__user-layout__menu__item">
              <div>
                <span>{user.name}</span>
              </div>
              <hr />
            </li>
          )}
          <div id="portal__header-right__user-dropdown" />
          {user.editable && (
            <li className="header__header-right__user-layout__menu__item">
              <button type="button">
                <EditIcon />
                <span>edit profile</span>
              </button>
            </li>
          )}
          <li className="header__header-right__user-layout__menu__item">
            <button
              type="button"
              className="header__header-right__user-layout__menu__item__logout"
              onClick={this.handleLogoutClick}
            >
              <LogoutIcon />
              <span>log out</span>
            </button>
          </li>
        </ul>
      </UserHeaderMenuStyled>
    );
  }
}

UserHeaderMenu.defaultProps = {
  onLogout: null,
};

UserHeaderMenu.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    editable: PropTypes.any,
    photoUrl: PropTypes.string,
  }).isRequired,
  onLogout: PropTypes.func,
};
