import React from 'react';
import PropTypes from 'prop-types';

import SidebarForm from './SidebarForm';

function cleanValues(values) {
  return Object.assign(
    {},
    ...Object.entries(values)
      .filter(([, value]) => value && value.length > 0)
      .map(([key, value]) => ({ [key]: value }))
  );
}

function FilterTitle() {
  return (
    <>
      <h3>Filters</h3>
      <small>Choose the filters you want to display:</small>
    </>
  );
}

function Filters(props) {
  const { filters, fields, onSubmit } = props;

  return (
    <SidebarForm
      title={<FilterTitle />}
      onSubmit={values => onSubmit(cleanValues(values))}
      initialValues={filters}
      fields={fields}
    />
  );
}

Filters.defaultProps = {
  onSubmit: () => {},
  fields: [],
  filters: {},
  children: [], // eslint-disable-line
};

Filters.propTypes = {
  onSubmit: PropTypes.func,
  filters: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), label: PropTypes.string })
    )
  ),
  fields: PropTypes.arrayOf(PropTypes.element),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]), // eslint-disable-line
};

export default Filters;
