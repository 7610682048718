import styled from 'styled-components';

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  button {
    font-size: 1rem;
    margin: 0 1rem;
  }
`;

const GreenSelectActionButton = styled.button`
  color: var(--green);
`;

const RedSelectActionButton = styled.button`
  color: var(--red);
`;

export { LabelContainer, GreenSelectActionButton, RedSelectActionButton };
