import styled from 'styled-components';
import { utils } from 'tol@themes';

export const Container = styled.div`
  width: 100%;
`;

export const MenuContainer = styled.div`
  min-width: max-content;
  display: flex;
  flex-direction: ${({ menuPosition }) => (isFlexColumn(menuPosition) ? 'row' : 'column')};
  padding: ${({ menuPosition }) => getMenuPadding(menuPosition)};
`;

export const MenuButton = styled.button.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  text-indent: 0.25rem;
  font-size: 0.875rem;
  padding: ${({ menuPosition }) => getMenuTabPadding(menuPosition)};
  text-align: left;
  ${({ menuPosition, theme }) => getMenuBorder(menuPosition, theme)}

  &.active {
    font-weight: 700;
  }

  &:last-child {
    box-shadow: none;
    border: none;
  }
`;

function isFlexColumn(menuPosition) {
  return ['top', 'bottom'].includes(menuPosition);
}

function getMenuPadding(menuPosition) {
  let padding;
  const paddingSize = '1rem';

  switch (menuPosition) {
    case 'top':
      padding = `0 0 ${paddingSize} 0`;
      break;
    case 'bottom':
      padding = `${paddingSize} 0 0 0`;
      break;
    case 'left':
      padding = `0 ${paddingSize} 0 0`;
      break;
    case 'right':
      padding = `0 0 0 ${paddingSize}`;
      break;
    default:
      padding = `0 ${paddingSize} 0 0`;

      break;
  }
  return padding;
}

function getMenuBorder(menuPosition, theme) {
  let border;
  const borderStyle = 'solid';
  const borderSize = '1px';
  const borderColor = theme.colors.gray;

  const verticalBorder = `border-right: ${borderStyle} ${borderSize} ${borderColor}`;
  const horizontalBorder = `border-bottom: ${borderStyle} ${borderSize} ${borderColor}`;

  switch (menuPosition) {
    case 'top':
      border = verticalBorder;
      break;
    case 'bottom':
      border = verticalBorder;
      break;
    case 'left':
      border = horizontalBorder;
      break;
    case 'right':
      border = horizontalBorder;
      break;
    default:
      border = horizontalBorder;

      break;
  }
  return border;
}

function getMenuTabPadding(menuPosition) {
  let padding;

  switch (menuPosition) {
    case 'top':
      padding = '0.25rem 0.75rem';
      break;
    case 'bottom':
      padding = '0.25rem 0.75rem';
      break;
    case 'left':
      padding = '0.75rem 5rem 0.75rem 0';
      break;
    case 'right':
      padding = '0.75rem 0 0.75rem 5rem';
      break;
    default:
      padding = '0.75rem 5rem 0.75rem 0';

      break;
  }
  return padding;
}
