import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import randomStringId from 'tol@utils/random';

import { BreadcrumbContainer, SeparatorContainer } from './styles';

const Breadcrumb = ({ className, separator, breadcrumb }) =>
  breadcrumb && breadcrumb.displayBreadcrumb ? (
    <BreadcrumbContainer className={className}>
      {breadcrumb.items.length > 0 ? (
        <button
          type="button"
          onClick={() => {
            breadcrumb.setItems([]);
            return breadcrumb.homeOnClick && breadcrumb.homeOnClick();
          }}
        >
          <Link to="/">{breadcrumb.homeTitle}</Link>
        </button>
      ) : (
        <span>{breadcrumb.homeTitle}</span>
      )}

      {[...breadcrumb.items].map(({ name, to, onClick }, index) => (
        <Fragment key={randomStringId()}>
          {index === 0 ? <SeparatorContainer>{separator}</SeparatorContainer> : ''}

          {index !== [...breadcrumb.items].length - 1 ? (
            <>
              <button type="button" onClick={onClick}>
                <Link to={to}>{name}</Link>
              </button>
              <SeparatorContainer>{separator}</SeparatorContainer>
            </>
          ) : (
            <span>{name}</span>
          )}
        </Fragment>
      ))}
    </BreadcrumbContainer>
  ) : (
    ''
  );

Breadcrumb.defaultProps = {
  className: '',
  separator: '>',
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  separator: PropTypes.string,
  breadcrumb: PropTypes.shape({
    displayBreadcrumb: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    setItems: PropTypes.func.isRequired,
    homeOnClick: PropTypes.func,
    homeTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  }).isRequired,
};

export default Breadcrumb;
