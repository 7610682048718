import React from 'react';
import PropTypes from 'prop-types';

import SidebarForm from './SidebarForm';
import MESSAGES from './constants/messages';

const DATE_OPTIONS = [
  { value: false, label: 'None' },
  { value: 'day', label: 'Day' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
];

function convertValuesIntoColumns(values) {
  const { date, ...otherValues } = values;

  return (date.value !== false ? [date.value] : []).concat(
    Object.entries(otherValues)
      .filter(([, value]) => !!value)
      .map(([key]) => key)
  );
}

function convertColumnsIntoValues(columns) {
  return Object.assign(
    { date: DATE_OPTIONS[0] },
    ...columns.map(column => {
      const date = DATE_OPTIONS.find(option => option.value === column);

      return date ? { date } : { [column]: true };
    })
  );
}

function GroupTitle() {
  return (
    <>
      <h3>Groups</h3>
      <small>Choose the columns you want to display:</small>
    </>
  );
}

function Groups(props) {
  const { groups, fields, onSubmit } = props;
  const handleSubmit = values => {
    const selectedGroups = convertValuesIntoColumns(values);

    if (selectedGroups.length === 0) {
        return alert(MESSAGES.NO_COLUMNS_WARNING); // eslint-disable-line
    }
    return onSubmit(selectedGroups);
  };

  return (
    <SidebarForm
      title={<GroupTitle />}
      onSubmit={handleSubmit}
      initialValues={convertColumnsIntoValues(groups)}
      fields={fields}
    />
  );
}

Groups.defaultProps = {
  onSubmit: () => {},
  fields: [],
  groups: [],
  children: [], // eslint-disable-line
};

Groups.propTypes = {
  onSubmit: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.arrayOf(PropTypes.element),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]), // eslint-disable-line
};

export default Groups;
