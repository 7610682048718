import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import HeaderStyled from './styles';
import { HeaderBlockLeft, HeaderBlockRight } from './Components/Blocks';

export default class Header extends PureComponent {
  render() {
    const { className, headerBlockLeftChildren, children } = this.props;

    return (
      <HeaderStyled className={`header-layout header-layout ${className}`}>
        <HeaderBlockLeft {...this.props}>{headerBlockLeftChildren()}</HeaderBlockLeft>
        {children}
        <HeaderBlockRight {...this.props} />
      </HeaderStyled>
    );
  }
}

Header.defaultProps = {
  className: '',
  headerBlockLeftChildren: () => null,
};

Header.propTypes = {
  className: PropTypes.string,
  headerBlockLeftChildren: PropTypes.func,
};
