import ApolloClient from 'apollo-boost';

import { getConfig } from 'tol@framework/TimeOneConfig/configStore';
import apolloBoostDefaultConfig from 'tol@utils/apolloBoostDefaultConfig';
import { CustomError } from 'tol@utils/errors';
import token from 'tol@utils/token';

let grapqhQLClient;

export function getGraphqlClient() {
  if (grapqhQLClient) {
    return grapqhQLClient;
  }

  const {
    urls: { graphqlUserUrl, authenticationUrl },
  } = getConfig();

  const apolloConfig = {
    ...apolloBoostDefaultConfig(authenticationUrl),
    uri: graphqlUserUrl,
    request: operation => {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token.get()}`,
        },
      });
    },
  };

  grapqhQLClient = new ApolloClient(apolloConfig);
  grapqhQLClient.defaultOptions = {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  return grapqhQLClient;
}

export function errorHandler({ data, errors = [] }) {
  const error = errors[0] && errors[0].extensions && errors[0].extensions.response;

  if (error) {
    const { detail = null, violations = null } = JSON.parse(error.body);

    const formatViolations =
      violations && violations.reduce((acc, value) => ({ ...acc, [value.propertyPath]: value.message }), {});

    throw new CustomError({ type: error.status, message: detail, violations: formatViolations });
  }

  return data;
}

export default { getGraphqlClient, errorHandler };
