import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ANCHOR_RIGHT } from 'react-dates/constants';

import elements from 'tol@elements';
import Form from 'tol@blocks/Form';
import { PeriodPickerContainer, PeriodPickerFormContainer } from './styles';
import getPresets from './periodPresets';

const { InputDateRangePicker } = elements;

class PeriodPicker extends Component {
  constructor(props) {
    super(props);
    this.state = { key: null, period: {} };
  }

  static getDerivedStateFromProps(props, state) {
    const { period } = props;
    const { period: oldPeriod } = state;

    if (
      !oldPeriod.startDate ||
      !oldPeriod.endDate ||
      (!oldPeriod.startDate.isSame(period.startDate, 'day') || !oldPeriod.endDate.isSame(period.endDate, 'day'))
    ) {
      return { period, key: `${period.startDate.unix()}, ${period.endDate.unix()}` };
    }

    return null;
  }

  render() {
    const { onSubmit, displayFormat, lastAvailableDay } = this.props;
    const { period, key } = this.state;

    return (
      <PeriodPickerContainer>
        <PeriodPickerFormContainer>
          <Form onSubmit={onSubmit} initialValues={{ period }} renderButtons={() => {}}>
            <InputDateRangePicker
              key={key}
              withButton
              withSubmit
              config={{
                anchorDirection: ANCHOR_RIGHT,
                showClearDates: false,
                isDayBlocked: day => lastAvailableDay.diff(day, 'days') < 0,
                displayFormat,
              }}
              presets={getPresets(lastAvailableDay)}
              name="period"
              label=""
            />
          </Form>
        </PeriodPickerFormContainer>
      </PeriodPickerContainer>
    );
  }
}

PeriodPicker.defaultProps = {
  period: {},
  onSubmit: () => {},
  displayFormat: 'YY MMM D',
  lastAvailableDay: moment(),
};

PeriodPicker.propTypes = {
  displayFormat: PropTypes.string,
  period: PropTypes.shape({ startDate: moment.isMoment, endDate: moment.isMoment }),
  onSubmit: PropTypes.func,
  lastAvailableDay: PropTypes.shape({}),
};

export default PeriodPicker;
