import { COLUMN_DEFAULT_PROPERTIES } from 'tol@blocks/Table/constants';
import formats from './constants';

export default columns =>
  columns.map(column => {
    const { format = {}, label, slug } = column;
    const { align, name, width = COLUMN_DEFAULT_PROPERTIES.width } = format;

    let newAlign = align;

    if (!align) {
      if (formats[name] && formats[name].align) {
        newAlign = formats[name].align;
      } else {
        newAlign = COLUMN_DEFAULT_PROPERTIES.align;
      }
    }

    return { align: newAlign, label, dataKey: slug, width: parseInt(width, 10) };
  });
