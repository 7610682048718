import styled from 'styled-components';
import colors from 'tol@themes/colors';

export const RenderMonthElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const selectStyle = {
  control: base => ({
    ...base,
    background: 'none',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    minWidth: '4.5rem',
    minHeight: '2rem',
    transition: 'all 0.5s ease',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: base => ({
    ...base,
    textTransform: 'capitalize',
  }),
  valueContainer: base => ({
    ...base,
  }),
  menu: base => ({
    ...base,
    backgroundColor: colors.white,
    borderRadius: 2,
    boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',
    padding: 0,
    marginTop: 25,
    height: 180,
    '& > div': {
      height: 180,
    },
  }),
  option: (base, state) => ({
    ...base,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '0.75rem 1.25rem',
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.025)' : colors.white,
    cursor: state.isFocused ? 'pointer' : 'inherite',
    color: state.isSelected ? 'rgba(0, 0, 0, 0.25)' : 'rgb(67, 75, 84)',
    ':active': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '2px',
  }),
};

export const selectYearStyle = {
  ...selectStyle,
  control: base => ({
    ...base,
    background: 'none',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    minWidth: '5rem',
    minHeight: '2rem',
    transition: 'all 0.5s ease',
    ':hover': {
      cursor: 'pointer',
    },
  }),
};

export default { RenderMonthElementContainer, selectStyle, selectYearStyle };
