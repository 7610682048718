import React from 'react';

import ApplicationLayoutContext from 'tol@framework/ApplicationLayout/ApplicationLayout.context';

const Sidebar = () => (
  <ApplicationLayoutContext.Consumer>
    {({ rightSidebar }) => (
      <div className={`default-layout__sidebar ${rightSidebar.isOpen ? 'is-open' : ''}`}>{rightSidebar.content}</div>
    )}
  </ApplicationLayoutContext.Consumer>
);

export default Sidebar;
