import styled from 'styled-components';

import { utils } from 'tol@themes';
import { COLUMN_DEFAULT_PROPERTIES } from 'tol@blocks/Table/constants';

const Layout = styled.div.attrs(({ theme, width, height }) => ({
  theme: utils.themeFallback(theme),
  width: `${width}px`,
  height: `${height}px`,
}))`
  align-items: center;
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.table.header.backgroundColor};
  border-bottom: 0.06rem solid ${({ theme }) => theme.table.container.border.color};
  border-top: 0.06rem solid ${({ theme }) => theme.table.container.border.color};
  box-shadow: ${({ theme }) => theme.shadows.dp1};
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  height: ${({ height }) => height};
  overflow: hidden;
  position: relative;
`;

const RowValue = styled.span.attrs(({ width, align }) => ({
  width: width || COLUMN_DEFAULT_PROPERTIES.width,
  align: align || COLUMN_DEFAULT_PROPERTIES.align,
}))`
  font-weight: 700;
  text-align: ${({ align }) => align};
  min-width: ${({ width }) => `calc(${width}px - 20px)`};
  width: 100%;
  flex: 0 1 ${({ width }) => `${width}px`};
  overflow: hidden;
  height: calc(100% - 12.5px);
  padding: 0 10px;
  align-items: center;
  padding-top: 12.5px;
  flex-grow: 1;

  &:first-child {
    text-align: center;
  }

  &:last-child {
    margin-right: 1.25rem;
    min-width: ${({ width }) => `calc(${width}px - 35px)`};
  }
`;

const TotalLabel = styled(RowValue).attrs(({ width }) => ({
  width: width || COLUMN_DEFAULT_PROPERTIES.width,
}))`
  width: ${({ width }) => `${width}px`};
`;

export { Layout, RowValue, TotalLabel };
