import styled from 'styled-components';

export const InputCheckBoxWprapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    div {
      margin: 0;
    }
  }
`;

export default { InputCheckBoxWprapper };
