import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Menu from './components/Menu';
import Content from './components/Content';
import { TabsContainer } from './styles';

function isActiveKeyValid(activeKey, tabs) {
  return tabs.some(tab => tab.key === activeKey);
}

function getDefaultActiveKey(activeKey, tabs) {
  if (activeKey && isActiveKeyValid(activeKey, tabs)) {
    return activeKey;
  }

  return tabs[0] && tabs[0].key;
}

function getTabsHash(tabs) {
  return tabs.reduce((tabsHash, tab) => {
    const hash = { ...tabsHash };

    hash[tab.key] = tab;

    return hash;
  }, {});
}

function getLegalMenuPosition(position) {
  return ['top', 'right', 'bottom', 'left'].includes(position) ? position : 'left';
}

const Tabs = ({ activeKey, tabs, menuPosition, className }) => {
  const [state, setState] = useState({
    activeKey: getDefaultActiveKey(activeKey, tabs),
    menuPosition: getLegalMenuPosition(menuPosition),
    tabs,
    tabsHash: getTabsHash(tabs),
  });

  return (
    <TabsContainer menuPosition={state.menuPosition} className={className}>
      <Menu {...state} onClick={key => setState({ ...state, activeKey: key })} />
      <Content {...state} />
    </TabsContainer>
  );
};

Tabs.defaultProps = {
  menuPosition: 'left',
  activeKey: null,
  className: '',
};

Tabs.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
      component: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    })
  ).isRequired,
};

export default Tabs;
