import React from 'react';
import { Link } from 'react-router-dom';

import { getConfig } from 'tol@framework/TimeOneConfig/configStore';
import Portal from 'tol@utils/Components/Portal';
import { routePath } from '../TimeOneRoutes/constants';

function UserInformationsLink() {
  const {
    modules: { userInformations },
  } = getConfig();

  return userInformations ? (
    <Portal portalId="portal__header-right__user-dropdown">
      <li>
        <Link to={routePath.userInformations} type="button">
          Informations
        </Link>
      </li>
    </Portal>
  ) : null;
}

export default UserInformationsLink;
