import styled from 'styled-components';

const WizardButtonContainerStyled = styled.div`
  display: flex;

  button {
    &.form__last-button {
      margin-left: auto;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }
  }
`;

export { WizardButtonContainerStyled as default, WizardButtonContainerStyled };
