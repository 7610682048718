import React from 'react';
import PropTypes from 'prop-types';
import Table from 'tol@blocks/Table';
import Pagination from 'tol@blocks/Pagination';
import RowTotal from 'tol@blocks/Dashboard/components/RowTotal';

import { CenteredContainer, TableContainer, TableBottomMessage } from './styles';

import MESSAGES from './constants/messages';

function DashboardTable(props) {
  const { items, keys, totalItems, order, onOrderChange } = props;

  const emptyRows = new Array({});

  const handleOrderChange = ({ sortBy, sortDirection }) => {
    onOrderChange({ order: { slug: sortBy, direction: sortDirection } });
  };

  if (!items) {
    return (
      <CenteredContainer>
        <h4>{MESSAGES.NO_DATA_TO_DISPLAY}</h4>
      </CenteredContainer>
    );
  }
  if (items.length === 0) {
    return (
      <TableContainer id="tolDashboardTableContainer">
        <Table keys={keys} items={emptyRows} rowHeight={32} headerHeight={32} />
      </TableContainer>
    );
  }
  return (
    <>
      <TableContainer id="tolDashboardTableContainer">
        <Pagination
          fetchData={() => ({ items, totalItem: items.length })}
          options={{
            limit: 1000,
          }}
          render={pagination => (
            <Table
              rowHeight={32}
              headerHeight={32}
              items={pagination.items}
              keys={keys}
              sort={handleOrderChange}
              sortBy={order.slug}
              sortDirection={order.direction}
              renderTableFooter={({ width, height }) =>
                totalItems && (
                  <RowTotal
                    labelWidth={totalItems.labelWidth}
                    values={totalItems.total}
                    width={width}
                    height={height}
                    className="tol-dashboard__row-total"
                  />
                )
              }
            />
          )}
        />
      </TableContainer>
      <TableBottomMessage>{MESSAGES.ROWS_LIMIT}</TableBottomMessage>
    </>
  );
}

DashboardTable.defaultProps = {
  items: [],
  totalItems: null,
  keys: [],
  order: {},
  onOrderChange: () => {},
};

DashboardTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  totalItems: PropTypes.shape({ labelWidth: PropTypes.number, total: PropTypes.arrayOf(PropTypes.object) }),
  keys: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.shape({ slug: PropTypes.string, direction: PropTypes.oneOf(['ASC', 'DESC']) }),
  onOrderChange: PropTypes.func,
};

export default DashboardTable;
