import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FormButtonsContainer, SaveButton } from './styles';

function FormButtons({ submitting, pristine, invalid, dirtySinceLastSubmit }) {
  return (
    <FormButtonsContainer>
      <Link to="/">{'< Home'}</Link>
      <SaveButton type="submit" fill disabled={submitting || pristine || (invalid && !dirtySinceLastSubmit)}>
        Save
      </SaveButton>
    </FormButtonsContainer>
  );
}

FormButtons.defaultProps = {
  form: {},
  submitting: null,
  pristine: null,
  invalid: null,
  dirtySinceLastSubmit: null,
};

FormButtons.propTypes = {
  form: PropTypes.shape({}),
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  dirtySinceLastSubmit: PropTypes.bool,
};

export default FormButtons;
