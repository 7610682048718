import React, { useReducer } from 'react';

export const sidebarContext = {
  isOpen: false,
  content: <div />,
  toggle: () => {},
  open: () => {},
  close: () => {},
  setSidebarContent: () => {},
};

function sidebarReducer(state, action) {
  switch (action.type) {
    case 'open':
      return { ...state, isOpen: true, content: action.content || state.content };
    case 'close':
      return { ...state, isOpen: false, content: <div /> };
    case 'toggle':
      return { ...state, isOpen: !state.isOpen };
    case 'setSidebarContent':
      return { ...state, content: action.content };
    default:
      return state;
  }
}

export function useSidebar() {
  const [sidebar, dispatch] = useReducer(sidebarReducer, {
    isOpen: false,
    content: <div />,
  });

  return {
    ...sidebar,
    open: content => dispatch({ type: 'open', content }),
    close: () => dispatch({ type: 'close' }),
    toggle: () => dispatch({ type: 'toggle' }),
    setSidebarContent: content => dispatch({ type: 'setSidebarContent', content }),
  };
}
