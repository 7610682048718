import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import assets from 'tol@assets';
import ApplicationLayoutContext from 'tol@framework/ApplicationLayout/ApplicationLayout.context';
import { getMe, getUserResources } from '../../services/user.service';
import { PlatformTitle, ResourceTypeContainer, ResourceTypeTitle, ResourceContainer, Content } from './styles';
import { Container } from '../styles';

const { Performance, LeadManagement } = assets.logos;

export function DisplayResourcesTitle() {
  return <div> My accounts </div>;
}

export function DisplayResources() {
  const { loader } = useContext(ApplicationLayoutContext);
  const [userId, setUserId] = useState();
  const [resources, setResources] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(
    () => {
      const fetchUser = async () => {
        try {
          loader.start();
          const me = await getMe();

          setUserId(me.id);
        } catch (error) {
          setErrorMessage(error.message);
          loader.stop();
          console.error(error);
        }
      };

      fetchUser();
    },
    [errorMessage]
  );

  useEffect(
    () => {
      const fetchResources = async () => {
        try {
          const fetchedResources = await getUserResources(userId);

          setResources(fetchedResources);
        } catch (error) {
          setErrorMessage(error.message);
          console.error(error);
        } finally {
          loader.stop();
        }
      };

      if (userId) {
        fetchResources();
      }
    },
    [userId]
  );

  return (
    <Container>
      {resources ? resources.map(resource => <Platform key={resource.platform.id} {...resource} />) : <Content />}
    </Container>
  );
}

function Platform({ platform, resources }) {
  const plaformLogoMapping = {
    1: Performance,
    2: LeadManagement,
  };

  return (
    <Content>
      <PlatformTitle>
        {plaformLogoMapping[platform.id]} {platform.name}
      </PlatformTitle>
      <ResourceContainer>
        {resources &&
          Object.entries(omit(resources, '__typename')).map(
            ([resourceType, accounts]) =>
              accounts &&
              accounts.length && <ResourceType key={resourceType} resourceType={resourceType} accounts={accounts} />
          )}
      </ResourceContainer>
    </Content>
  );
}

Platform.propTypes = {
  platform: PropTypes.shape({}).isRequired,
  resources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function ResourceType({ resourceType, accounts }) {
  const accountMapping = {
    publisher: PublisherDisplay,
    advertiser: AdvertiserDisplay,
  };

  return (
    <ResourceTypeContainer key={resourceType}>
      <ResourceTypeTitle>{resourceType}</ResourceTypeTitle>
      {accounts.map(account => accountMapping[resourceType]({ key: account.id, account }))}
    </ResourceTypeContainer>
  );
}

ResourceType.propTypes = {
  resourceType: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function PublisherDisplay({ account }) {
  return <span>{`${account.firstname} ${account.lastname} (${account.legacyId || account.id})`}</span>;
}

PublisherDisplay.propTypes = {
  account: PropTypes.shape({}).isRequired,
};

function AdvertiserDisplay({ account }) {
  return <span>{`${account.name} (${account.legacyId || account.id})`}</span>;
}

AdvertiserDisplay.propTypes = {
  account: PropTypes.shape({}).isRequired,
};
