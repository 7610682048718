import React from 'react';

import Loader from 'tol@elements/Loader';
import ApplicationLayoutContext from './ApplicationLayout.context';
import { ApplicationLayoutLoader } from './styles';

const ApplicationLoader = () => (
  <ApplicationLayoutContext.Consumer>
    {({ loader }) => (
      <ApplicationLayoutLoader className={`default-layout__loader ${loader.isLoading ? 'active' : ''}`}>
        <Loader>{loader.content}</Loader>
      </ApplicationLayoutLoader>
    )}
  </ApplicationLayoutContext.Consumer>
);

export default ApplicationLoader;
