import React, { Component } from 'react';
import PropTypes from 'prop-types';

import icons from 'tol@assets/icon';
import PAGINATION_CONSTANTS from './constants';
import GraphQLPaginationIterator from './GraphQLPaginationIterator';
import PaginationIterator from './PaginationIterator';
import utils from './utils';
import PaginationStyled from './styles';

const { canNotGo, displayPageNumber } = utils;
const { ArrowBackIcon, ArrowIcon } = icons;

export default class Pagination extends Component {
  constructor(props) {
    super(props);

    const engine = {
      limitOffset: PaginationIterator,
      grapQLCursor: GraphQLPaginationIterator,
      default: PaginationIterator,
    };

    this.state = {
      currentDataDisplay: {},
      iterableData: {},
      currentPage: 0,
      isFetchingData: false,
      PaginationEngine: engine[props.paginationEngineType] || engine.default,
      error: undefined,
    };
  }

  componentDidMount = async () => {
    this.setState({ isFetchingData: true });

    this._fetchIterableData();
  };

  static getDerivedStateFromProps(props, state) {
    if (state.fetchData !== props.fetchData) {
      return {
        fetchData: props.fetchData,
        limit: props.options.limit || PAGINATION_CONSTANTS.itemPerPage * PAGINATION_CONSTANTS.maxPageToShow,
        offset: props.options.offset || 0,
        numberToDisplayPerPage: props.options.numberToDisplayPerPage || PAGINATION_CONSTANTS.itemPerPage,
      };
    }

    return null;
  }

  componentDidUpdate = async prevState => {
    const { fetchData } = this.props;

    if (prevState.fetchData !== fetchData) {
      await this.setState({ isFetchingData: true, offset: 0, currentPage: 0 });

      this._fetchIterableData();
    }
  };

  _fetchIterableData = async () => {
    try {
      const { PaginationEngine, numberToDisplayPerPage, limit, offset } = this.state;
      const { fetchData } = this.props;

      const iterableData = await PaginationEngine.init({ numberToDisplayPerPage, limit, offset }, fetchData);
      const currentDataDisplay = iterableData.initialData();
      const newOffset = iterableData.getOffset();

      this.setState({ iterableData, currentDataDisplay, offset: newOffset, isFetchingData: false });

      return { iterableData, currentDataDisplay, offset: newOffset };
    } catch (error) {
      this.setState({ error: error.message });
    }

    return true;
  };

  next = async () => {
    try {
      const { currentDataDisplay, iterableData } = this.state;
      const lastPaginationDisplayIndex = iterableData.getAllFetchedData().length - 1;

      if (iterableData.getCurrentIndex() === lastPaginationDisplayIndex) {
        this.setState({ isFetchingData: true });
      }

      const nextData = await iterableData.next();
      const currentPage = iterableData.getCurrentIndex();
      const offset = iterableData.getOffset();

      return (
        !canNotGo(currentDataDisplay.hasNextPage) &&
        this.setState({ iterableData, currentDataDisplay: nextData, currentPage, offset, isFetchingData: false })
      );
    } catch (error) {
      this.setState({ error: error.message });
    }

    return true;
  };

  previous = async () => {
    try {
      const { currentDataDisplay, iterableData } = this.state;
      const firstPaginationDisplayIndex = 0;

      if (iterableData.getCurrentIndex() === firstPaginationDisplayIndex) {
        this.setState({ isFetchingData: true });
      }

      const previousData = await iterableData.previous();
      const currentPage = iterableData.getCurrentIndex();
      const offset = iterableData.getOffset();

      return (
        !canNotGo(currentDataDisplay.hasPreviousPage) &&
        this.setState({ iterableData, currentDataDisplay: previousData, currentPage, offset, isFetchingData: false })
      );
    } catch (error) {
      this.setState({ error: error.message });
    }

    return true;
  };

  goTo = pageNumber => {
    const { iterableData } = this.state;
    const data = iterableData.goTo(pageNumber);
    const currentPage = iterableData.getCurrentIndex();

    return this.setState({ currentDataDisplay: data, currentPage });
  };

  needPagination = () => {
    const { iterableData, currentDataDisplay } = this.state;
    const chuncks = iterableData && iterableData.getAllFetchedData();

    return chuncks && (chuncks.length > 1 || (currentDataDisplay.hasPreviousPage && chuncks.length === 1));
  };

  render() {
    const {
      currentDataDisplay,
      currentPage,
      isFetchingData,
      iterableData,
      numberToDisplayPerPage,
      offset,
      error,
    } = this.state;
    const { render, dataFormat, loader } = this.props;

    return error ? (
      <span>{error}</span>
    ) : (
      <PaginationStyled>
        {currentDataDisplay.value ? (
          <div className="tolPagination">
            {isFetchingData && loader()}
            {render && (
              <div style={{ height: '100%' }}>
                {render({ items: dataFormat(currentDataDisplay.value), isFetchingData })}
              </div>
            )}

            {this.needPagination() && (
              <div className="tolPagination__block">
                <button
                  type="button"
                  onClick={() => !isFetchingData && this.previous()}
                  className="tolPagination__icon"
                  disabled={!currentDataDisplay.hasPreviousPage}
                >
                  <ArrowBackIcon />
                </button>
                {new Array(iterableData.getAllFetchedData().length)
                  .fill(0)
                  .map((val, index) => index)
                  .map(pageNumber => (
                    <button
                      className={`tolPagination__button ${pageNumber === currentPage && 'active'}`}
                      type="button"
                      key={pageNumber}
                      onClick={() => pageNumber !== currentPage && this.goTo(pageNumber)}
                    >
                      {displayPageNumber(offset, numberToDisplayPerPage, pageNumber)}
                    </button>
                  ))}
                <button
                  type="button"
                  onClick={() => !isFetchingData && this.next()}
                  className="tolPagination__icon"
                  disabled={!currentDataDisplay.hasNextPage}
                >
                  <ArrowIcon />
                </button>
              </div>
            )}
          </div>
        ) : (
          loader()
        )}
      </PaginationStyled>
    );
  }
}

Pagination.defaultProps = {
  dataFormat: data => data,
  loader: () => '',
  paginationEngineType: 'limitOffset',
  options: {},
};

Pagination.propTypes = {
  fetchData: PropTypes.func.isRequired,
  dataFormat: PropTypes.func,
  render: PropTypes.func.isRequired,
  loader: PropTypes.func,
  options: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    numberToDisplayPerPage: PropTypes.number,
  }),
  paginationEngineType: PropTypes.oneOf(['limitOffset', 'grapQLCursor']),
};
