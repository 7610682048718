import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker, DayPickerRangeController } from 'react-dates';
import { START_DATE } from 'react-dates/constants';
import moment from 'moment';
import _ from 'lodash';
import 'react-dates/initialize';

import ArrowIcon from 'tol@assets/icon/Arrow';
import DefaultInput from '../DefaultInput';
import Button from '../../Button';
import DateRangeStyled, { ButtonContainer } from './styles';
import { isRequiredDateRangePicker } from '../utils';
import { Header, PresetsList, RenderMonthElement } from './components';
import defaultPresets from './defaultPresets';

import 'react-dates/lib/css/_datepicker.css';

export default class InputDateRangePicker extends DefaultInput {
  constructor(props) {
    super(props);

    this.state = {
      startDate: '',
      endDate: '',
      focusedInput: null,
    };
  }

  handleChange = (defaultOnChange, defaultOnBlur, { startDate: previousStartDate, endDate: previousEndDate }) => ({
    startDate: changeStartDate,
    endDate: changeEndDate,
  }) => {
    const startDate = !changeStartDate && !changeEndDate ? null : changeStartDate || previousStartDate;
    const endDate = !changeStartDate && !changeEndDate ? null : changeEndDate || previousEndDate;
    const { onChange } = this.props;

    if (onChange) {
      onChange({ target: { value: { startDate, endDate } } });
    }

    this.setState({
      startDate,
      endDate,
    });

    defaultOnChange({ target: { value: { startDate, endDate } } });

    if (defaultOnBlur) {
      defaultOnBlur();
    }
  };

  handleFocus = (defaultOnFocus, defaultOnBlur) => focusedInput => {
    const { onFocus, onBlur } = this.props;

    this.setState({ focusedInput });

    if (focusedInput) {
      if (onFocus) {
        onFocus({ target: { value: focusedInput } });
      }

      return defaultOnFocus && defaultOnFocus({ target: { value: focusedInput } });
    }

    if (onBlur) {
      onBlur(focusedInput);
    }

    return defaultOnBlur && defaultOnBlur({ target: { value: focusedInput } });
  };

  handleValidate = value => {
    const { validation, required } = this.props;

    if (required && isRequiredDateRangePicker(value)) {
      return isRequiredDateRangePicker(value);
    }

    return validation ? validation(value) : undefined;
  };

  handleIsActive = ({ value }, { active }) => active || Object.keys(value).length > 0;

  openDateRangePicker = () => {
    const { focusedInput } = this.state;

    return !focusedInput && this.setState({ focusedInput: START_DATE });
  };

  closeDateRangePicker = () => {
    this.setState({ focusedInput: null });
  };

  getPresetsItems = () => {
    const { presets } = this.props;

    if (Array.isArray(presets)) {
      return presets;
    }

    return defaultPresets;
  };

  clearDateRangePicker = () => this.setState({ startDate: null, endDate: null, clear: true });

  renderCalendarInfo = (inputProps, initialDates = { startDate: null, endDate: null }) => () => {
    const { onChange, onBlur, value } = inputProps;
    const { startDate, endDate } = value;
    const { presets, presetsMenuPosition, withSubmit } = this.props;

    return (
      <div className="DayPicker__calendar-info">
        <Header endDate={endDate || ''} startDate={startDate || ''} />
        {presets && (
          <PresetsList
            items={this.getPresetsItems()}
            onPresetClick={({ startDate: presetStartDate, endDate: presetEndDate }) =>
              this.handleChange(onChange, onBlur, value)({
                startDate: presetStartDate,
                endDate: presetEndDate,
              })
            }
            position={presetsMenuPosition}
          />
        )}
        {withSubmit && (
          <ButtonContainer>
            <Button
              primary
              appearance="cancel"
              onClick={() => this.handleChange(onChange, onBlur, value)(initialDates)}
            >
              Reset
            </Button>
            <Button
              type="submit"
              primary
              fill
              disabled={!(startDate && endDate)}
              onClick={() =>
                setTimeout(() => {
                  this.closeDateRangePicker();
                }, 0)
              }
            >
              Apply
            </Button>
          </ButtonContainer>
        )}
      </div>
    );
  };

  setConfig = (inputProps, meta, config) => ({
    anchorDirection: 'left',
    calendarInfoPosition: 'top',
    customArrowIcon: <ArrowIcon />,
    daySize: 34,
    displayFormat: 'DD/MM/YYYY',
    hideKeyboardShortcutsPanel: true,
    firstDayOfWeek: 1,
    inputIconPosition: 'after',
    keepOpenOnDateSelect: true,
    key: 'tolDateRangePicker',
    noBorder: true,
    numberOfMonths: 2,
    readOnly: false,
    renderCalendarInfo: this.renderCalendarInfo(inputProps, meta.initial),
    renderMonthElement: RenderMonthElement,
    showClearDates: true,
    showDefaultInputIcon: true,
    isOutsideRange: () => false,
    initialVisibleMonth: () => moment().subtract(1, 'month'),
    ...config,
  });

  getInitalDate = date => (date ? moment(date) : null);

  isEmptyOrReset = (date, { startDate, endDate }) => !(date && (startDate || endDate));

  getButtonLabel = (initilalDates = { startDate: null, endDate: null }) => {
    const { startDate, endDate } = this.state;
    const {
      config: { displayFormat },
    } = this.props;

    if ((!startDate && !initilalDates.startDate) || (!endDate && !initilalDates.endDate)) {
      return 'Dates';
    }

    const startDateString = startDate ? startDate.format(displayFormat) : initilalDates.startDate.format(displayFormat);
    const endDateString = endDate ? endDate.format(displayFormat) : initilalDates.endDate.format(displayFormat);

    return `${startDateString} ${startDateString && endDateString ? ` - ${endDateString}` : ' '}`;
  };

  inputRender = (inputProps, meta) => {
    const { focusedInput, startDate, endDate } = this.state;
    const { name, config, withButton, withSubmit } = this.props;
    const {
      onChange,
      onFocus,
      onBlur,
      value: { startDate: InitialStartDate = null, endDate: InitialEndDate = null },
    } = inputProps;

    const configGenerated = this.setConfig(inputProps, meta, config);
    const dateRangeControllerProps = _.omit(configGenerated, [
      'endDatePlaceholderText',
      'anchorDirection',
      'customArrowIcon',
      'displayFormat',
      'inputIconPosition',
      'readOnly',
      'showClearDates',
      'showDefaultInputIcon',
    ]);

    return (
      <DateRangeStyled
        id={name}
        name={name}
        className={`tol-input__daterangepicker ${this.handleIsActive(inputProps, meta) ? 'active' : null} ${
          withSubmit ? 'DateRangePickerWithSubmit' : ''
        } ${withButton ? 'DateRangePickerWithButton' : ''}`}
      >
        {withButton ? (
          <div style={{ marginBottom: 16 }} className="DateRangePicker DateRangePicker_1">
            <div>
              <Button
                primary
                fill={!!startDate || !!meta.initial}
                type="button"
                className="DateRangePickerButton"
                onClick={this.openDateRangePicker}
              >
                {this.getButtonLabel(meta.initial)}
              </Button>
            </div>
            {focusedInput && (
              <DayPickerRangeController
                startDate={
                  this.isEmptyOrReset(startDate, inputProps.value)
                    ? this.getInitalDate(InitialStartDate)
                    : moment(startDate)
                }
                endDate={
                  this.isEmptyOrReset(endDate, inputProps.value) ? this.getInitalDate(InitialEndDate) : moment(endDate)
                }
                onDatesChange={this.handleChange(onChange, onBlur, inputProps.value)}
                focusedInput={focusedInput}
                onOutsideClick={this.closeDateRangePicker}
                onFocusChange={this.handleFocus(onFocus, onBlur)}
                {...dateRangeControllerProps}
              />
            )}
          </div>
        ) : (
          <DateRangePicker
            startDate={
              this.isEmptyOrReset(startDate, inputProps.value)
                ? this.getInitalDate(InitialStartDate)
                : moment(startDate)
            }
            startDateId={startDate ? `${name}_${startDate}` : name}
            startDatePlaceholderText=""
            endDate={
              this.isEmptyOrReset(endDate, inputProps.value) ? this.getInitalDate(InitialEndDate) : moment(endDate)
            }
            endDateId={endDate ? `${name}_${endDate}` : name}
            endDatePlaceholderText=""
            onDatesChange={this.handleChange(onChange, onBlur, inputProps.value)}
            focusedInput={focusedInput}
            onFocusChange={this.handleFocus(onFocus, onBlur)}
            {...configGenerated}
          />
        )}
      </DateRangeStyled>
    );
  };
}

InputDateRangePicker.defaultProps = {
  config: {},
  presets: null,
  presetsMenuPosition: 'left',
  withButton: false,
  withSubmit: false,
};

InputDateRangePicker.propTypes = {
  config: PropTypes.shape({}),
  presets: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
        startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
      })
    ),
  ]),
  withSubmit: PropTypes.bool,
  presetsMenuPosition: PropTypes.oneOf(['left', 'right']),
  withButton: PropTypes.bool,
};
