import colors from '../colors';

export default {
  backgroundColor: colors.white,
  header: {
    backgroundColor: '#ececec',
  },
  container: {
    border: {
      color: 'rgba(48, 55, 71, 0.3)',
    },
    child: {
      odd: {
        backgroundColor: colors.white,
      },
      even: {
        backgroundColor: '#fafafa',
      },
      hover: {
        backgroundColor: colors.graySecondary,
      },
    },
  },
};
