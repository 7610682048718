import React from 'react';
import PropTypes from 'prop-types';

import icons from 'tol@assets/icon';
import ApplicationLayoutContext from 'tol@framework/ApplicationLayout/ApplicationLayout.context';
import { HeaderContainer, HeaderChildrenContainer, OptionsContainer } from './styles';

const { CloseIcon } = icons;

export default function Header({ children }) {
  return (
    <ApplicationLayoutContext.Consumer>
      {({ rightSidebar }) => (
        <HeaderContainer className="tol-sidebar__header">
          <HeaderChildrenContainer>{children}</HeaderChildrenContainer>
          <OptionsContainer>
            <CloseIcon onClick={rightSidebar.close} />
          </OptionsContainer>
        </HeaderContainer>
      )}
    </ApplicationLayoutContext.Consumer>
  );
}

Header.defaultProps = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};
Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};
