import React from 'react';
import DefaultIcon from './DefaultIcon';

export default props => (
  <DefaultIcon
    {...props}
    viewBox="0 0 448 448"
    d="M412 188H260V36c0-19.9-16.1-36-36-36s-36 16.1-36 36v152H36c-19.9 0-36 16.1-36 36s16.1 36 36 36h152v152c0 19.9 16.1 36 36 36s36-16.1 36-36V260h152c19.9 0 36-16.1 36-36s-16.1-36-36-36z"
  />
);
