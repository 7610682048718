import colors from 'tol@themes/colors';

const autocompleteStyle = {
  control: base => ({
    ...base,
    background: 'none',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    minWidth: '8rem',
    paddingTop: '1rem',
    transition: 'all 0.5s ease',
  }),
  valueContainer: base => ({
    ...base,
  }),
  menu: base => ({
    ...base,
    borderRadius: 2,
    boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',
    zIndex: 2,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.025)' : colors.white,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    color: state.isSelected ? 'rgba(0, 0, 0, 0.25)' : 'rgb(67, 75, 84)',
    padding: '0.75rem 1.25rem',
    ':active': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: colors.timeone.primary,
    borderRadius: '0 2px 2px 2px',
    color: colors.white,
  }),
  multiValueLabel: base => ({
    ...base,
    color: colors.white,
    fontWeight: 'lighter',
    margin: '0 .25rem',
  }),
  multiValueRemove: base => ({
    ...base,
    color: colors.white,
    cursor: 'pointer',
    transition: 'all 0.25s ease',
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  }),
};

export default autocompleteStyle;
