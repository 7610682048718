import styled from 'styled-components';

import { utils } from 'tol@themes';

const LoaderContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  background-color: ${({ theme }) => theme.primary};
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export default LoaderContainer;
