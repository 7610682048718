function log(...args) {
  return canLog() && console.log.apply(null, args);
}

function info(...args) {
  return canLog() && console.info.apply(null, args);
}

function warn(...args) {
  return canLog() && console.warn.apply(null, args);
}

function error(...args) {
  return canLog() && console.error.apply(null, args);
}

function canLog() {
  return process.env.NODE_ENV === 'development';
}

export default {
  log,
  info,
  warn,
  error,
};
