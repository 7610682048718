import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MetricLayout = styled.div`
  align-items: center;
  background: ${({ color }) => color || '#FAFAFA'};
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: space-around;
  margin: 0.5rem;
  min-width: 8rem;
  max-width: 16rem;
  padding: 1.25rem;
  width: 30%;

  * {
    color: #fff;
  }

  .metric__name {
    text-transform: capitalize;
    color: #fff;
    font-weight: 300;
    letter-spacing: 0.05rem;
  }

  .metric__value {
    font-size: 3rem;
    margin: 0.5rem 0;
  }

  ${({ customStyle }) => customStyle};
`;

function Metric(props) {
  const { name, value, currency, color, className, customStyle } = props;

  return (
    <MetricLayout className={`metric metric_${name} ${className}`} color={color} customStyle={customStyle}>
      <p className="metric__value">{value + currency}</p>
      <p className="metric__name">{name}</p>
    </MetricLayout>
  );
}

Metric.defaultProps = {
  name: '',
  value: '',
  currency: '',
  color: '#BADA55',
  className: '',
  customStyle: null,
};

Metric.propTypes = {
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.number,
  currency: PropTypes.string,
  // this is not a colorName, you need to give an actual color rgb, hex, ...
  // you can also give a css variable "var(--foo)"
  color: PropTypes.string,
  className: PropTypes.string,
  // style formatted for styled components inside ``
  customStyle: PropTypes.string,
};

export default Metric;
