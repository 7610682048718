import { useState, useEffect } from 'react';

function useMedia(mediaQueries, defaultValue) {
  const mediaQueryLists = mediaQueries.map(query => window.matchMedia(query));
  const getValue = () => {
    const index = mediaQueryLists.findIndex(mediaQueryList => mediaQueryList.matches);

    return mediaQueries[index] ? mediaQueries[index] : defaultValue;
  };

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);

    mediaQueryLists.forEach(mediaQueryList => mediaQueryList.addListener(handler));

    return () => mediaQueryLists.forEach(mediaQueryList => mediaQueryList.removeListener(handler));
  }, []);

  return value;
}

export default useMedia;
