import styled from 'styled-components';

import { utils } from '../../../themes';

export default styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  padding: 0.6rem 0;
  transition: all 0.15s ease-in-out;
  width: 100%;
  background-color: ${({ theme, type }) => theme.snackbar[type] && theme.snackbar[type].backgroundColor};

  & * {
    align-items: center;
    display: flex;
    margin: 0;
  }
`;
