import utils from 'tol@utils';

const { createTableKeys, formatTableData, generateFormFields, formatTableTotalValues } = utils;

function formatPayload(payload) {
  const { period, filters, ...restPayload } = payload;

  if (filters) {
    restPayload.filters = Object.assign(
      {},
      ...Object.entries(filters || {}).map(([key, filter]) => ({ [key]: filter.map(v => v.value) }))
    );
  }
  return {
    ...restPayload,
    period: {
      startDate: period.startDate.format('YYYY-MM-DD'),
      endDate: period.endDate.format('YYYY-MM-DD'),
    },
  };
}

export const getConfig = fetchConfig => () =>
  fetchConfig().then(async ({ default: defaultColumns, columns, filters }) => {
    const groupFields = await generateFormFields(
      columns
        .filter(column => !['day', 'month', 'year', 'date'].includes(column.slug))
        .map(column => ({ ...column, type: 'checkbox' }))
    );
    const filterFields = await generateFormFields(filters.map(filter => ({ ...filter, type: 'autocomplete' })));

    return { defaultColumns, groupFields, filterFields };
  });

export const getData = fetchData => payload => {
  const formatedPayload = formatPayload(payload);

  return fetchData(formatedPayload).then(({ columns, data, settings, order, total }) => {
    const keys = createTableKeys(columns);
    const items = formatTableData({ columns, data }, settings);
    const totalItems = formatTableTotalValues({ columns, total }, settings);

    return { keys, items, totalItems, order };
  });
};

export const getExportDownloadURL = exportConfig => payload => {
  const { requestExportFile, getExportStatus, EXPORT_DOWNLOAD_URL } = exportConfig;
  const formatedPayload = formatPayload(payload);

  return new Promise(async (resolve, reject) => {
    const { computationId } = await requestExportFile(formatedPayload);

    const computationChecker = setInterval(async () => {
      try {
        const { isReady, downloadToken } = await getExportStatus(computationId);

        if (isReady) {
          clearInterval(computationChecker);
          resolve({ downloadURL: `${EXPORT_DOWNLOAD_URL}/${downloadToken}` });
        }
      } catch (error) {
        clearInterval(computationChecker);
        reject(error);
      }
    }, 1000);
  });
};

export default { getConfig, getData, getExportDownloadURL };
