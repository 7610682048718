import React from 'react';
import DefaultIcon from './DefaultIcon';

export default props => (
  <DefaultIcon
    {...props}
    viewBox="0 0 220.001 220.001"
    d="M0 220l59.34-6.14-53.197-53.199zM132.018 34.787l53.197 53.197L69.568 203.631 16.37 150.434 132.018 34.787zm80.678 25.715c9.738-9.738 9.742-25.527 0-35.268l-17.93-17.93c-9.738-9.74-25.529-9.738-35.268 0l-17.346 17.347 53.199 53.196 17.345-17.345z"
  />
);
