const FORMATS_PROPERTIES = {
  default: {
    align: 'left',
    currency: 'USD',
    locale: 'en-US',
  },
  int: {
    align: 'right',
    set: '0,0',
  },
  float: {
    align: 'right',
    set: '0,0.00',
  },
  currency: {
    align: 'right',
    set: '$0,0',
  },
  percentage: {
    align: 'right',
    set: '0.000%',
  },
  date: {
    align: 'center',
    set: 'l',
  },
};

export default FORMATS_PROPERTIES;
