import React, { useContext, useEffect } from 'react';
import ApplicationLayoutContext from 'tol@framework/ApplicationLayout/ApplicationLayout.context';

function withBreadcrumb(ComposedComponent, crumbs = []) {
  return function SetCrumb(props) {
    const { breadcrumb } = useContext(ApplicationLayoutContext);

    useEffect(() => {
      breadcrumb.setItems(crumbs);
    });

    return <ComposedComponent {...props} />;
  };
}

export default withBreadcrumb;
