import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import assets from 'tol@assets';
import TabsContext from './Tabs.context';
import Tabs from '../../components/blocks/Tabs';
import {
  DisplayResources,
  DisplayResourcesTitle,
  UpdateInformations,
  UpdateInformationsTitle,
  UpdatePassword,
  UpdatePasswordTitle,
} from './components';
import { Container, Snackbar } from './styles';

const { CheckIcon } = assets.icons;

const tabs = [
  {
    key: '1',
    title: <UpdateInformationsTitle />,
    component: <UpdateInformations />,
  },
  {
    key: '2',
    title: <UpdatePasswordTitle />,
    component: <UpdatePassword />,
  },
  {
    key: '3',
    title: <DisplayResourcesTitle />,
    component: <DisplayResources />,
  },
];

export default function UserInformationsPage() {
  return (
    <TabsProvider>
      <UserInformation />
    </TabsProvider>
  );
}

function UserInformation() {
  const { snackbar } = useContext(TabsContext);

  return (
    <>
      <Container>
        <Tabs tabs={tabs} />
      </Container>
      {snackbar.active && (
        <Snackbar type="success">
          <>
            <CheckIcon /> {snackbar.message}
          </>
        </Snackbar>
      )}
    </>
  );
}

function TabsProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    message: '',
    active: false,
  });

  function customSetsnackbar(state) {
    if (state.active) {
      setTimeout(() => {
        setSnackbar({
          message: '',
          active: false,
        });
      }, 3000);

      setSnackbar(state);
    }
  }
  return <TabsContext.Provider value={{ snackbar, setSnackbar: customSetsnackbar }}>{children}</TabsContext.Provider>;
}

TabsProvider.defaultProps = {
  children: null,
};

TabsProvider.propTypes = {
  children: PropTypes.element,
};
