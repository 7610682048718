import colors from '../colors';

export default {
  error: {
    backgroundColor: colors.red,
  },
  info: {
    backgroundColor: colors.blue,
  },
  success: {
    backgroundColor: colors.green,
  },
  warning: {
    backgroundColor: colors.orange,
  },
};
