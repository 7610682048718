import React from 'react';
import PropTypes from 'prop-types';

import icons from 'tol@assets/icon';
import LabelContainer from './styles';

const { CheckIcon } = icons;

const SuccessLabel = ({ className, label }) => (
  <LabelContainer className={className}>
    <div>
      <span>
        <strong>{label}</strong> Added
      </span>
      <CheckIcon />
    </div>
    Added
  </LabelContainer>
);

export default SuccessLabel;

SuccessLabel.defaultProps = {
  className: '',
  label: '',
};

SuccessLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};
