import React from 'react';
import PropTypes from 'prop-types';
import { Async } from 'react-select';
import _ from 'lodash';
import * as Animated from 'react-select/lib/animated';

import DefaultInput from '../DefaultInput';
import autocompleteStyle from './styles';
import { isRequiredSelect } from '../utils';

export default class InputAutocomplete extends DefaultInput {
  loadOptions = (inputValue, callback) => {
    const { searchOptions } = this.props;

    searchOptions(inputValue).then(result => {
      callback(result);
    });
  };

  debouncedLoadOptions = _.debounce(this.loadOptions, 1000);

  getOptions = (input, callback) => {
    if (_.isEmpty(input)) {
      return callback([]);
    }

    return this.debouncedLoadOptions(input, callback);
  };

  handleChange = (defaultOnChange, defaultOnBlur) => selectedOption => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ target: { value: selectedOption } });
    }

    defaultOnChange({ target: { value: selectedOption } });

    if (defaultOnBlur) {
      defaultOnBlur();
    }
  };

  handleValidate = value => {
    const { validation, required } = this.props;

    if (required && isRequiredSelect(value)) {
      return isRequiredSelect(value);
    }

    return validation ? validation(value) : undefined;
  };

  handleIsActive = ({ value }, { active }) => active || Object.keys(value).length > 0;

  inputRender = (inputProps, meta) => {
    const { name, isMulti, noOptionsMessage, loadingMessage } = this.props;
    const { onChange, onFocus, onBlur } = inputProps;

    return (
      <Async
        id={name}
        name={name}
        clearable
        className={`tol-input__autocomplete autocomplete ${this.handleIsActive(inputProps, meta) ? 'active' : ''}`}
        classNamePrefix="autocomplete"
        placeholder=""
        cacheOptions
        loadingMessage={loadingMessage}
        noOptionsMessage={noOptionsMessage}
        loadOptions={this.getOptions}
        isMulti={isMulti}
        components={Animated}
        styles={autocompleteStyle}
        {...inputProps}
        onChange={this.handleChange(onChange, onBlur)}
        onFocus={this.handleFocus(onFocus)}
        onBlur={this.handleBlur(onBlur)}
      />
    );
  };
}

InputAutocomplete.defaultProps = {
  isMulti: false,
  loadingMessage: () => 'Searching...',
  noOptionsMessage: () => 'No result',
};

InputAutocomplete.propTypes = {
  searchOptions: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  loadingMessage: PropTypes.func,
  noOptionsMessage: PropTypes.func,
};
