import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import NotificationIcon from 'tol@assets/icon/Notification';
import randomStringId from 'tol@utils/random';
import NotificationHeaderMenuStyled from './styles';

export default class NotificationHeaderMenu extends PureComponent {
  render() {
    const { notifications } = this.props;

    return (
      <NotificationHeaderMenuStyled className="header__header-right__notifications">
        <button type="button">
          <NotificationIcon className="header__header-right__notifications__icon" />
          {notifications.length > 0 && (
            <div className="header__header-right__notifications__status">
              <span>{notifications.length}</span>
            </div>
          )}
        </button>
        <ul className="header__header-right__notifications__index">
          {notifications.length > 0 ? (
            notifications.map(notification => (
              <li key={randomStringId()} className="header__header-right__notifications__index__item notification">
                <div href={notification.url}>
                  <p>
                    <span className="notification__author">{notification.author}</span>
                    <span className="notification__date">{notification.dateTime}</span>
                    <span className="notification__since">{` `} &#8226; 15min</span>
                  </p>
                  <p className="notification__title">{notification.title}</p>
                  <p className="notification__content">{notification.content}</p>
                </div>
              </li>
            ))
          ) : (
            <li className="header__header-right__notifications__index__item notification">
              {`You don't have any notification.`}
            </li>
          )}
        </ul>
      </NotificationHeaderMenuStyled>
    );
  }
}

NotificationHeaderMenu.defaultProps = {
  notifications: [],
};

NotificationHeaderMenu.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      content: PropTypes.string,
      dateTime: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};
