import styled from 'styled-components';
import { transparentize } from 'polished';
import { utils } from 'tol@themes';

export default styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  background-color: ${({ theme }) => theme.applicationLayout.children.backgroundColor};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .default-layout__content {
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;

    .default-layout__sidebar {
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: ${({ theme }) => theme.shadows.dp2};
      display: flex;
      flex-direction: column;
      height: calc(100% - 1px);
      margin-top: 1px;
      transition: all 0.5s;
      width: 0;
      max-width: 300px;

      &.is-open {
        width: 25%;
      }
    }

    .default-layout__children {
      padding: 1rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: auto;
    }
  }
`;

export const ApplicationLayoutLoader = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-items: center;
  background-color: ${({ theme }) => transparentize(0.15, theme.applicationLayout.children.backgroundColor)};
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  height: 0;
  transition: height 0.01ms linear 0.5s, opacity 0.5s ease-out;
  width: 100%;
  z-index: 1000;
  opacity: 0;
  overflow: hidden;

  svg,
  &.active {
    transition: opacity 0.5s ease-out;
    height: 100%;
    opacity: 1;
  }
`;
