import styled from 'styled-components';
import { lighten } from 'polished';

import { utils } from 'tol@themes';
import Paper from '../../../components/elements/Paper';
import Button from '../../../components/elements/Button';
import TocInputText from '../../../components/elements/Inputs/InputText';

export const Content = styled(Paper).attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  border: ${({ error, theme }) => (error ? `solid ${theme.colors.error} 2px` : 'none')};
  font-size: 0.875rem;
  padding: 10px;
  text-align: left;
`;

export const Container = styled.div`
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SaveButton = styled(Button).attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.button.disabled.backgroundColor.primary : theme.colors.green};
  border: none;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => lighten(0.125, theme.colors.green)};
  }
`;

export const Title = styled.div`
  font-size: 1.25rem;
  padding-bottom: 0.5rem;
`;

export const FormButtonsContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-items: center;
  display: flex;
  margin-top: 2rem;

  a {
    color: ${({ theme }) => theme.colors.gray};
  }

  button {
    margin: 0;
    margin-left: auto;
  }
`;

export const InputText = styled(TocInputText)`
  flex: 0 40%;
  padding-right: 2rem;
`;

export const ErrorMessage = styled.span.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  color: ${({ theme }) => theme.colors.error};
  font-size: small;
  margin: 0.75rem 0.5rem 0.25rem;
`;

export default {
  InputContainer,
  SaveButton,
  FormButtonsContainer,
  Content,
  Container,
  Title,
  ErrorMessage,
};
