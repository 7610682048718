import TimeOneRoutes from './TimeOneRoutes';
import TimeOneConfig from './TimeOneConfig';
import { setFrameworkConfig } from './TimeOneConfig/configStore';
import ApplicationLayout, { ApplicationLayoutContext } from './ApplicationLayout';
import Dashboard from './Dashboard';
import { withBreadcrumb } from './Breadcrumb';
import IsAuthenticate from './IsAuthenticate';
import { SidebarHeader, SidebarContent, SidebarFooter, SidebarContainer } from './Sidebar';

export default {
  IsAuthenticate,
  TimeOneRoutes,
  TimeOneConfig,
  ApplicationLayout,
  ApplicationLayoutContext,
  Dashboard,
  withBreadcrumb,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  SidebarContainer,
  setFrameworkConfig,
};
