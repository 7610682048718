import styled from 'styled-components';

import { utils } from 'tol@themes';

export default styled.nav.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  background-color: ${({ theme }) => theme.headerNavbar.backgroundColor};
  padding: 0 2rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  & > ul {
    display: flex;
    color: #fff;

    & > li {
      display: block;
      position: relative;
      overflow: visible;
      transition: all 0.15s ease;

      & > a {
        opacity: 0.9;
        justify-content: center;
        transition: all 0.25s;
      }

      svg {
        height: 1rem;
        width: 1rem;
      }

      li {
        align-items: center;
        display: flex;
        padding-right: 1rem;
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0.7rem 1rem;
        align-items: center;
        font-size: 0.75rem;
        box-sizing: border-box;
        text-transform: capitalize;
        cursor: pointer;
        white-space: nowrap;
      }

      &:hover,
      &.nav-menu--active {
        background-color: ${({ theme }) => theme.headerNavbar.active.backgroundColor};
        color: ${({ theme }) => theme.headerNavbar.active.fontColor};
      }

      &:hover {
        & > a {
          opacity: 1;
          background-color: inherit;
        }

        & > ul,
        & > ul > li > ul > svg {
          display: block;
          visibility: visible;
        }

        & > ul > li > ul {
          display: block;
          visibility: hidden;
        }
      }

      ul {
        position: absolute;
        display: none;
        width: 17.5rem;
        text-align: left;
        background: #fff;

        /* prettier-ignore */
        box-shadow:
          0 6px 10px 0 rgba(0, 0, 0, 0.14),
          0 1px 18px 0 rgba(0, 0, 0, 0.12),
          0 3px 5px -1px rgba(0, 0, 0, 0.2);
      }

      & > ul {
        top: 2rem;
        left: 0.75rem;
        z-index: 5;
        color: var(--font-color);

        li {
          border-bottom: 2px solid transparent;
          transition: border 0.2s ease;

          &:hover {
            background-color: ${({ theme }) => theme.headerNavbar.subEntry.hover.backgroundColor};
            transition: all 0.4s ease;
          }
        }

        & > li {
          position: relative;

          &:hover > ul {
            visibility: visible;
          }

          & > ul {
            top: 0.5rem;
            left: 17rem;
          }
        }
      }
    }
  }

  ${({ customStyle }) => customStyle};
`;
