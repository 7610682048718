import logoUrl from 'tol@assets/logo/lead_management.svg';
import themeGenerator from '../default';
import colors from '../colors';

const defaultTheme = themeGenerator(colors.lmt);

export default {
  ...defaultTheme,
  name: 'Lead Management Technologies',
  header: {
    ...defaultTheme.header,
    logo: {
      url: logoUrl,
    },
  },
};
