import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defaultHeaderRenderer, Column, Table as VTable, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

import randomStringId from 'tol@utils/random';
import TableStyled from './styles';
import { COLUMN_DEFAULT_PROPERTIES, HEADER_HEIGHT, ROW_HEIGHT } from './constants';

export default class Table extends Component {
  getTotalColumnsWidth() {
    const { keys, itemOptions } = this.props;

    const tableKeyWidth = keys.reduce(
      (totalColumnsWidth, { width = COLUMN_DEFAULT_PROPERTIES.width }) =>
        parseInt(totalColumnsWidth, 10) + parseInt(width, 10),
      0
    );
    const tableItemOptionsWidth = itemOptions.reduce(
      (totalColumnsWidth, { width = COLUMN_DEFAULT_PROPERTIES.width }) =>
        parseInt(totalColumnsWidth, 10) + parseInt(width, 10),
      0
    );

    return tableKeyWidth + tableItemOptionsWidth;
  }

  getTableHeight = ({ calculateWrapperHeight, calculateWith, calculateWrapperWidth }) => {
    const { renderTableFooter, rowHeight } = this.props;

    function handleScrollSize(finalHeight) {
      return calculateWith > calculateWrapperWidth ? finalHeight - 10 : finalHeight;
    }

    return handleScrollSize(renderTableFooter ? calculateWrapperHeight - rowHeight : calculateWrapperHeight);
  };

  getTableWrapperHeight = ({ height: tableWrapperHeight, calculateWith, calculateWrapperWidth }) => {
    const { height, items, rowHeight, renderTableFooter } = this.props;
    const BORDER_SIZE = 2;
    const SCROLLBAR_SIZE = 10;

    if (height) {
      return height + 2;
    }

    function handleScrollSize(finalHeight) {
      return calculateWith > calculateWrapperWidth
        ? finalHeight + BORDER_SIZE + SCROLLBAR_SIZE
        : finalHeight + +BORDER_SIZE;
    }

    const totalRowsHeight = renderTableFooter ? rowHeight * (items.length + 2) : rowHeight * (items.length + 1);

    return handleScrollSize(
      tableWrapperHeight > 0 && tableWrapperHeight < totalRowsHeight ? tableWrapperHeight : totalRowsHeight
    );
  };

  getTableWrapperWidth = tableWrapperWidth => {
    const { width } = this.props;

    if (width) {
      return width;
    }

    return tableWrapperWidth;
  };

  getTableWidth = tableWrapperWidth => {
    const { keys, width } = this.props;

    if (width) {
      return width;
    }

    if (keys.length > 0) {
      const totalColumnsWidth = this.getTotalColumnsWidth();

      if (totalColumnsWidth > tableWrapperWidth) {
        return totalColumnsWidth;
      }
    }
    return tableWrapperWidth;
  };

  renderDefaultTable = ({ firstColumnsOptions, width, height }) => {
    const { cellRenderer, headerHeight, headerRenderer, itemOptions, items, keys, rowHeight } = this.props;

    return (
      <VTable
        {...this.props}
        width={width}
        height={height}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        rowCount={items.length}
        rowGetter={({ index }) => items[index]}
        gridStyle={{ overflow: 'auto' }}
      >
        {firstColumnsOptions &&
          firstColumnsOptions.length > 0 &&
          firstColumnsOptions.map((option, index) => (
            <Column
              className={`tolTableWrapper__firstColumnsOptions col-${index + 1}`}
              key={randomStringId()}
              label={option.label || COLUMN_DEFAULT_PROPERTIES.label}
              headerRenderer={option.headerRender}
              dataKey={option.key || COLUMN_DEFAULT_PROPERTIES.dataKey}
              width={option.width || COLUMN_DEFAULT_PROPERTIES.width}
              cellRenderer={option.render}
              disableSort={option.disableSort || false}
            />
          ))}
        {keys.map((key, index) => (
          <Column
            key={randomStringId()}
            label={key.label}
            dataKey={key.dataKey}
            width={key.width || COLUMN_DEFAULT_PROPERTIES.width}
            className={`${key.label.split(' ').join('-')} col-${index + 1}`}
            cellRenderer={key.cellRenderer || cellRenderer}
            headerRenderer={key.headerRenderer || headerRenderer}
            disableSort={key.disableSort || false}
          />
        ))}
        {itemOptions.length > 0 &&
          itemOptions.map(option => (
            <Column
              className="tolTableWrapper__OptionsColumn"
              key={randomStringId()}
              label={option.label || COLUMN_DEFAULT_PROPERTIES.label}
              dataKey={option.key || COLUMN_DEFAULT_PROPERTIES.dataKey}
              width={option.width || COLUMN_DEFAULT_PROPERTIES.width}
              cellRenderer={option.render}
              headerRenderer={() => {}}
              disableSort
            />
          ))}
      </VTable>
    );
  };

  renderTable = this.renderDefaultTable;

  render() {
    const { items, className, renderTableFooter, rowHeight } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => {
          const calculateWrapperWidth = this.getTableWrapperWidth(width);
          const calculateWith = this.getTableWidth(width);
          const calculateWrapperHeight = this.getTableWrapperHeight({ height, calculateWith, calculateWrapperWidth });
          const calculateHeight = this.getTableHeight({
            calculateWrapperHeight,
            calculateWith,
            calculateWrapperWidth,
          });

          return (
            <TableStyled
              {...this.props}
              width={calculateWrapperWidth}
              height={calculateWrapperHeight}
              className={`tolTableWrapper ${className}`}
            >
              <div style={{ width: `${calculateWrapperWidth}px`, height: `${calculateHeight}px` }} className="table">
                {items.length > 0 ? (
                  this.renderTable({ height: calculateHeight, width: calculateWith })
                ) : (
                  <div>no content</div>
                )}
              </div>
              {renderTableFooter && renderTableFooter({ height: rowHeight, width: calculateWith })}
            </TableStyled>
          );
        }}
      </AutoSizer>
    );
  }
}

Table.defaultProps = {
  cellRenderer: ({ cellData }) => (cellData || cellData === 0 ? cellData : '-'),
  checkbox: 'false',
  className: '',
  height: null,
  headerHeight: HEADER_HEIGHT,
  itemOptions: [],
  items: [],
  rowHeight: ROW_HEIGHT,
  width: null,
  headerRenderer: defaultHeaderRenderer,
  renderTableFooter: null,
};

Table.propTypes = {
  checkbox: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  headerHeight: PropTypes.number,
  itemOptions: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number.isRequired,
      render: PropTypes.func.isRequired,
    })
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  rowHeight: PropTypes.number,
  width: PropTypes.number,
  cellRenderer: PropTypes.func,
  headerRenderer: PropTypes.func,
  renderTableFooter: PropTypes.func,
};
