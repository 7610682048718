import { getGraphqlClient } from './utils';
import { GET_ME, GET_USER_RESOURCES, UPDATE_USER, UPDATE_PASSWORD } from './user.query';

export const getMeApi = () =>
  getGraphqlClient().query({
    name: 'getMe',
    query: GET_ME,
  });

export const getUserResourcesApi = id =>
  getGraphqlClient().query({
    name: 'getUserResources',
    query: GET_USER_RESOURCES,
    variables: { id },
  });

export const updateUserApi = ({ id, firstName, lastName, email }) =>
  getGraphqlClient().mutate({
    name: 'updateUser',
    mutation: UPDATE_USER,
    variables: {
      id,
      input: {
        firstName,
        lastName,
        email,
      },
    },
  });

export const updatePasswordApi = ({ id, password }) =>
  getGraphqlClient().mutate({
    name: 'updatePassword',
    mutation: UPDATE_PASSWORD,
    variables: {
      id,
      input: {
        password,
      },
    },
  });

export default { getMeApi, updateUserApi, updatePasswordApi };
