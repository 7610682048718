import React from 'react';
import PropTypes from 'prop-types';
import ApplicationLayoutContext from 'tol@framework/ApplicationLayout/ApplicationLayout.context';
import BreadcrumbElement from 'tol@elements/Breadcrumb';

const Breadcrumb = props => (
  <ApplicationLayoutContext.Consumer>
    {({ breadcrumb }) => <BreadcrumbElement {...props} breadcrumb={breadcrumb} />}
  </ApplicationLayoutContext.Consumer>
);

Breadcrumb.defaultProps = {
  className: '',
  separator: '>',
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  separator: PropTypes.string,
};

export default Breadcrumb;
