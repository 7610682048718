import { useReducer } from 'react';
import _ from 'lodash';

export const breadcrumbContext = {
  setItems: () => {},
  items: [],
  homeOnClick: null,
  homeTitle: 'Home',
  displayBreadcrumb: true,
};

function handleNewItems(state, newItems) {
  let items = newItems;

  if (!Array.isArray(newItems)) {
    items = items ? [newItems] : [];
  }

  if (_.isEqual(state.items, items)) {
    return state;
  }

  return { ...state, items, displayBreadcrumb: true };
}

function breadcrumbReducer(state, action) {
  switch (action.type) {
    case 'setItems':
      return handleNewItems(state, action.items);
    case 'setHomeOnClick':
      return { ...state, homeOnClick: action.fn };
    case 'setHomeTitle':
      return { ...state, homeTitle: action.title };
    case 'setDisplayBreadcrumb':
      return { ...state, displayBreadcrumb: action.value };
    default:
      return state;
  }
}

export function useBreadcrumb() {
  const [breadcrumb, dispatch] = useReducer(breadcrumbReducer, {
    items: [],
    homeOnClick: null,
    homeTitle: 'Home',
    displayBreadcrumb: true,
  });

  return {
    ...breadcrumb,
    setItems: items => dispatch({ type: 'setItems', items }),
    setHomeOnClick: fn => dispatch({ type: 'setHomeOnClick', fn }),
    setHomeTitle: title => dispatch({ type: 'setHomeTitle', title }),
    setDisplayBreadcrumb: value => dispatch({ type: 'setDisplayBreadcrumb', value }),
  };
}
