import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import OutsideClickHandler from 'react-outside-click-handler';

import { ListItem, PresetListContainer } from './styles';
import Preset from '../Preset';

class PresetsList extends PureComponent {
  state = {
    activePresetId: null,
  };

  handlePresetClick = (presetProps, index) => {
    const { onPresetClick } = this.props;

    this.setActivePresetId(index);

    return onPresetClick({ ...presetProps, index });
  };

  resetState = () => this.setState({ activePresetId: null });

  setActivePresetId = index => this.setState({ activePresetId: index });

  render() {
    const { className, items, position } = this.props;
    const { activePresetId } = this.state;

    return (
      <OutsideClickHandler onOutsideClick={this.resetState}>
        <CSSTransition in appear classNames="expand" timeout={1000}>
          <PresetListContainer position={position} className={`presets-list ${className}`}>
            <TransitionGroup>
              {items.map((presetProps, index) => (
                <CSSTransition key={presetProps.label} in appear classNames="expand" timeout={250}>
                  <ListItem index={index}>
                    <Preset
                      {...presetProps}
                      active={activePresetId === index}
                      onClick={() => this.handlePresetClick(presetProps, index)}
                    />
                  </ListItem>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </PresetListContainer>
        </CSSTransition>
      </OutsideClickHandler>
    );
  }
}

export default PresetsList;

PresetsList.defaultProps = {
  className: '',
  onPresetClick: () => null,
  position: 'left',
};

PresetsList.propTypes = {
  className: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  onPresetClick: PropTypes.func,
};
