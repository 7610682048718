import styled from 'styled-components';

const FormButtonStyled = styled.div`
  display: flex;

  button {
    &.form__last-button {
      margin-left: auto;
    }
  }
`;

export { FormButtonStyled as default, FormButtonStyled };
