import React from 'react';
import PropTypes from 'prop-types';
import LoaderSpinner from 'react-loader-spinner';
import icons from 'tol@assets/icon';

import { HeaderContainer } from './styles';

const { DownloadIcon, RefreshIcon, GroupIcon, FilterIcon } = icons;

function Header(props) {
  const { theme, title, loadingExport, onReset, onExport, onGroup, onFilter, renderDatePicker } = props;

  return (
    <HeaderContainer>
      <h3>{title}</h3>
      {renderDatePicker()}
      <div className="dashboard-header__controls">
        <button type="button" onClick={onReset} title="Refresh data">
          <RefreshIcon />
        </button>
        {onExport && (
          <button
            className={loadingExport ? 'button__loading' : ''}
            type="button"
            onClick={() => loadingExport || onExport()}
            titlte="Export data"
          >
            {!loadingExport ? (
              <DownloadIcon />
            ) : (
              <LoaderSpinner
                className="button__loader"
                type="Grid"
                color={theme.primary}
                width="1.25rem"
                height="1.25rem"
              />
            )}
          </button>
        )}
        <button type="button" onClick={onGroup} title="Choose columns">
          <GroupIcon />
        </button>
        <button type="button" onClick={onFilter} title="Choose filters">
          <FilterIcon />
        </button>
      </div>
    </HeaderContainer>
  );
}

Header.defaultProps = {
  theme: {},
  title: null,
  loadingExport: false,
  onReset: () => {},
  onExport: undefined,
  onGroup: () => {},
  onFilter: () => {},
  renderDatePicker: () => {},
};

Header.propTypes = {
  theme: PropTypes.shape({}),
  title: PropTypes.string,
  loadingExport: PropTypes.bool,
  onReset: PropTypes.func,
  onExport: PropTypes.func,
  onGroup: PropTypes.func,
  onFilter: PropTypes.func,
  renderDatePicker: PropTypes.func,
};

export default Header;
