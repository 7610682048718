import styled from 'styled-components';

export default styled.div`
  div {
    align-items: center;
    background: var(--green);
    color: var(--white);
    display: flex;
    height: 100%;
    justify-content: space-between;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    span {
      margin: 0 1rem;
    }

    svg {
      fill: var(--white);
      height: 1rem;
      margin-right: 1.5rem;
      width: 1rem;
    }
  }
`;
