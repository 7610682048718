export default class PaginationIterator {
  constructor({ numberToDisplayPerPage, limit, offset }, fetchDataSrv) {
    this.biggestIndex = limit + offset;
    this.fetchDataSrv = fetchDataSrv;
    this.index = 0;
    this.initialOffset = offset;
    this.iterableData = [];
    this.limit = limit;
    this.numberToDisplayPerPage = numberToDisplayPerPage;
    this.offset = offset;
    this.totalItem = 0;
  }

  static init = ({ numberToDisplayPerPage, limit, offset }, fetchData) =>
    (async () => {
      const instance = new PaginationIterator({ numberToDisplayPerPage, limit, offset }, fetchData);

      await instance.build();

      return instance;
    })();

  build = async () => {
    try {
      const { items, totalItem } = await this.fetchDataSrv({ offset: this.offset, limit: this.limit });

      this.setIterableData([...items], this.numberToDisplayPerPage);
      this.setTotalItem(totalItem);
    } catch (e) {
      throw new Error('An unexpected network error occured.');
    }
  };

  setIterableData = (dataToIterate, dataChunckSize) => {
    const results = [];

    while (dataToIterate.length) {
      results.push(dataToIterate.splice(0, dataChunckSize));
    }

    this.iterableData = results;

    return results;
  };

  setTotalItem = totalItem => {
    this.totalItem = totalItem;

    return totalItem;
  };

  increaseIndex = () => {
    this.index += 1;

    return this.index;
  };

  decreaseIndex = () => {
    this.index -= 1;

    return this.index;
  };

  increaseOffset = () => {
    this.offset += this.limit;

    return this.offset;
  };

  decreaseOffset = () => {
    this.offset -= this.limit;

    return this.offset;
  };

  next = () => {
    const nextValue =
      this.increaseIndex() < this.iterableData.length ? this.hasNoMoreDataToFetch() : this.shouldFetchNextData();

    return nextValue;
  };

  previous = () => {
    const previousValue = this.decreaseIndex() < 0 ? this.shouldFetchPreviousData() : this.hasNoMorePreviousData();

    return previousValue;
  };

  goTo = (pageIndex = 0) => {
    this.index = pageIndex;
    switch (true) {
      case pageIndex < 1:
        return this.hasNoMorePreviousData();
      case pageIndex >= this.iterableData.length - 1:
        return this.hasNoMoreDataToFetch();
      default:
        return {
          value: this.iterableData[pageIndex],
          hasNextPage: !this.isLatestChunck(),
          hasPreviousPage: !this.isFirstChunck(),
        };
    }
  };

  initialData = () => ({
    value: this.iterableData[0],
    hasPreviousPage: !this.isFirstChunck(),
    hasNextPage: !this.isLatestChunck(),
  });

  getAllFetchedData = () => this.iterableData;

  getCurrentIndex = () => this.index;

  getOffset = () => this.offset;

  hasNoMoreDataToFetch = () => {
    const hasNextPage = !this.isLatestChunck();
    const hasPreviousPage = !this.isFirstChunck();

    return { value: this.iterableData[this.index], hasNextPage, hasPreviousPage };
  };

  shouldFetchNextData = async () => {
    try {
      const { items } = await this.fetchDataSrv({ offset: this.increaseOffset(), limit: this.limit });

      this.index = 0;
      const iterableData = this.setIterableData([...items], this.numberToDisplayPerPage);
      const hasNextPage = !this.isLatestChunck();
      const hasPreviousPage = !this.isFirstChunck();

      return { value: iterableData[this.index], hasNextPage, hasPreviousPage };
    } catch (e) {
      throw new Error('An unexpected network error occured.');
    }
  };

  shouldFetchPreviousData = async () => {
    try {
      const { items } = await this.fetchDataSrv({ offset: this.decreaseOffset(), limit: this.limit });

      this.index = this.numberOfPagesDisplay();
      const iterableData = this.setIterableData([...items], this.numberToDisplayPerPage);

      return { value: iterableData[this.index], hasPreviousPage: true, hasNextPage: true };
    } catch (e) {
      throw new Error('An unexpected network error occured.');
    }
  };

  hasNoMorePreviousData = () => {
    const hasNextPage = !this.isLatestChunck();
    const hasPreviousPage = !this.isFirstChunck();

    return { value: this.iterableData[this.index], hasPreviousPage, hasNextPage };
  };

  numberOfPagesDisplay = () =>
    this.limit < this.totalItem
      ? this.limit / this.numberToDisplayPerPage - 1
      : Math.ceil(this.totalItem / this.numberToDisplayPerPage) - 1;

  isLatestChunck = () => {
    const containLastChunck = this.biggestIndex >= this.totalItem - this.offset;

    return containLastChunck ? this.index === this.iterableData.length - 1 : false;
  };

  isFirstChunck = () => this.index === 0 && this.offset === this.initialOffset;
}
