import React from 'react';
import PropTypes from 'prop-types';
import elements from 'tol@elements';

import { CenteredContainer } from './styles';
import MESSAGES from './constants/messages';

const { Button } = elements;

function Export(props) {
  const { onDownload, downloadURL } = props;

  return (
    <CenteredContainer>
      <span>{MESSAGES.EXPORT_IS_AVAILABLE}</span>
      <a href={downloadURL} onClick={onDownload}>
        <Button appearance="default" fill="primary">
          Download
        </Button>
      </a>
    </CenteredContainer>
  );
}

Export.defaultProps = {
  onDownload: () => {},
  downloadURL: null,
};

Export.propTypes = {
  onDownload: PropTypes.func,
  downloadURL: PropTypes.string,
};

export default Export;
