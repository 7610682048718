import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import Button from 'tol@elements/Button';
import { WizardButtonContainerStyled } from './styles';
import WizardHeader from './components/WizardHeader';

const defaultRenderButtons = ({ previous, page, submitting, validatingStep, invalid, isLastPage }) => (
  <WizardButtonContainerStyled>
    <Button
      primary
      appearance="cancel"
      className={`form__last-button ${page > 0 ? 'visible' : ''}`}
      onClick={previous}
      disabled={submitting || validatingStep}
    >
      Previous
    </Button>
    {!isLastPage && (
      <Button primary type="submit" fill disabled={invalid || validatingStep}>
        {validatingStep ? 'In progress...' : 'Next'}
      </Button>
    )}
    {isLastPage && (
      <Button primary type="submit" fill disabled={submitting || invalid}>
        Submit
      </Button>
    )}
  </WizardButtonContainerStyled>
);

defaultRenderButtons.defaultProps = {
  form: {},
  submitting: null,
  invalid: null,
  validatingStep: () => false,
};

defaultRenderButtons.propTypes = {
  form: PropTypes.shape({}),
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  previous: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  isLastPage: PropTypes.number.isRequired,
  validatingStep: PropTypes.func,
};
export default class Wizard extends Component {
  static Page = ({ children }) => children;

  static propTypes = {
    className: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        onValidate: PropTypes.func,
      })
    ).isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    renderHeader: PropTypes.func,
    initialValues: PropTypes.shape({}),
    mutators: PropTypes.shape({}),
    onSubmit: PropTypes.func.isRequired,
    renderButtons: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    initialValues: {},
    mutators: {},
    renderHeader: null,
    renderButtons: defaultRenderButtons,
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      initialValues: props.initialValues || {},
      mutators: props.mutators || {},
      renderHeader: props.renderHeader,
      validatingStep: false,
    };
  }

  next = async values => {
    const { page } = this.state;
    const { children, steps } = this.props;

    if (steps[page] && steps[page].onValidate) {
      this.setState({ validatingStep: true });
      await steps[page].onValidate(values);
    }

    this.setState(state => ({
      page: Math.min(state.page + 1, children.length - 1),
      values,
      validatingStep: false,
    }));
  };

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
    }));

  validate = values => {
    const { page } = this.state;
    const { children } = this.props;
    const activePage = React.Children.toArray(children)[page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = values => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;

    if (isLastPage) {
      return onSubmit(values);
    }

    return this.next(values);
  };

  render() {
    const { page, initialValues, mutators, renderHeader, validatingStep } = this.state;
    const { children, steps, renderButtons, className } = this.props;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    const stepsName = steps.map(step => step.name);

    return (
      <div>
        {renderHeader ? (
          renderHeader({ stepsName, page, validatingStep })
        ) : (
          <WizardHeader stepsName={stepsName} page={page} validatingStep={validatingStep} />
        )}
        <Form initialValues={initialValues} validate={this.validate} onSubmit={this.handleSubmit} mutators={mutators}>
          {({ handleSubmit, submitting, invalid, form, values }) => (
            <form onSubmit={handleSubmit} className={className}>
              {activePage && activePage.props.render
                ? activePage.props.render({ handleSubmit, submitting, invalid, form, values })
                : activePage}
              {renderButtons({
                next: this.next,
                previous: this.previous,
                page,
                submitting,
                validatingStep,
                steps,
                invalid,
                isLastPage,
                values,
              })}
            </form>
          )}
        </Form>
      </div>
    );
  }
}
