import styled, { css } from 'styled-components';

import { utils } from 'tol@themes';

export default styled.footer.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-items: center;
  background-color: ${({ theme }) => theme.footer.backgroundColor};
  color: ${({ theme }) => theme.footer.fontColor};
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  & > * {
    max-width: 30%;
  }

  ${({ theme }) => leftBlock(theme.footer)};
  ${centerBlock()};
  ${({ theme }) => rightBlock(theme.footer)};
  ${({ customStyle }) => customStyle};
`;

function leftBlock({ fontColor, fontColorSecondary }) {
  return css`
    .footer__left-signature {
      color: ${fontColor};
      font-size: 0.75rem;
      left: 1.5rem;
      padding: 0.75rem 0;
      position: absolute;
      top: auto;

      & > * {
        color: ${fontColor};

        span {
          color: ${fontColorSecondary};
        }
      }
    }
  `;
}

function centerBlock() {
  return css`
    .footer__center-main-logo {
      height: 3rem;
      padding: 0.75rem 0;
      width: 3rem;
    }
  `;
}

function rightBlock({ fontColorSecondary }) {
  return css`
    .footer__right-social {
      display: flex;
      padding: 0.75rem 0;
      position: absolute;
      right: 1.5rem;
      top: auto;

      a.footer__right-social__item {
        align-items: center;
        background-color: ${fontColorSecondary};
        border-radius: 50%;
        display: flex;
        height: 1.75rem;
        justify-content: center;
        margin: 0 0.4rem;
        width: 1.75rem;

        img {
          height: 0.75rem;
          width: 0.75rem;
        }
      }
    }
  `;
}
