import React from 'react';
import { breadcrumbContext as breadcrumb } from 'tol@framework/Breadcrumb/useBreadcrumb';
import { sidebarContext as rightSidebar } from 'tol@framework/Sidebar/useSidebar';
import { applicationLayoutLoaderContext as loader } from './useApplicationLayoutLoader';

const router = {
  history: {},
};

const ApplicationLayoutContext = React.createContext({
  breadcrumb,
  loader,
  rightSidebar,
  router,
});

export default ApplicationLayoutContext;
