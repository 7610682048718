import _ from 'lodash';
import pDebounce from 'p-debounce';

const defaultBaseValidationApiUrl = 'https://lmt.timeonegroup.com/api/validation';
const defaultErrorMessage = 'Merci de saisir une valeur valide';

let baseValidationApiUrl = defaultBaseValidationApiUrl;

function getValidationFn({ setter, validator, errorMessage = defaultErrorMessage, delay = 350 }) {
  const memoizeValidator = _.memoize(value => validator(value, errorMessage));

  return pDebounce(async function debounceFn(value) {
    const validate = await memoizeValidator(value);

    if (!setter) {
      return validate;
    }

    if (validate === undefined) {
      setter(value);
    } else {
      setter(undefined);
    }

    return validate;
  }, delay);
}

export function setBaseValidationApiUrl(url) {
  baseValidationApiUrl = url;
}

export async function validateWithApi(url, value, errorMessage = defaultErrorMessage) {
  const response = await fetch(`${url}?string=${encodeURIComponent(value)}`);
  const { result } = await response.json();

  return result === true ? undefined : errorMessage;
}

export function validateName(value, errorMessage = defaultErrorMessage) {
  return validateWithApi(`${baseValidationApiUrl}/name`, value, errorMessage);
}

export function validateNameDebounce({ setter, errorMessage = defaultErrorMessage, delay }) {
  const validator = value => validateName(value, errorMessage);

  return getValidationFn({ setter, validator, errorMessage, delay });
}

export function validateEmail(value, errorMessage = defaultErrorMessage) {
  return validateWithApi(`${baseValidationApiUrl}/email`, value, errorMessage);
}

export function validateEmailDebounce({ setter, errorMessage = defaultErrorMessage, delay }) {
  const validator = value => validateEmail(value, errorMessage);

  return getValidationFn({ setter, validator, errorMessage, delay });
}

export function validatePhoneNumber(value, errorMessage = defaultErrorMessage) {
  return validateWithApi(`${baseValidationApiUrl}/phonenumber`, value, errorMessage);
}

export function validatePhoneNumberDebounce({ setter, errorMessage = defaultErrorMessage, delay }) {
  const validator = value => validatePhoneNumber(value, errorMessage);

  return getValidationFn({ setter, validator, errorMessage, delay });
}

export function validateZipCode(value, errorMessage = defaultErrorMessage) {
  return validateWithApi(`${baseValidationApiUrl}/zipcode`, value, errorMessage);
}

export function validateZipCodeDebounce({ setter, errorMessage = defaultErrorMessage, delay }) {
  const validator = value => validateZipCode(value, errorMessage);

  return getValidationFn({ setter, validator, errorMessage, delay });
}

export function validateOnlyAlphaNum(value, errorMessage = 'Uniquement des caractères alphanumériques') {
  if (/^[a-zA-ZÀ-ÿ0-9\s-]+$/.test(value)) {
    return undefined;
  }

  return errorMessage;
}
