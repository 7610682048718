import React from 'react';
import PropTypes from 'prop-types';

import InputCheckBox from 'tol@elements/Inputs/InputCheckBox';
import { Form } from 'react-final-form';
import Table from '../Table';
import { InputCheckBoxWprapper } from './styles';

const checkboxPrefix = 'checkboxtable';
const checkboxDefaultRenderFn = rowData => (
  <InputCheckBoxWprapper>
    <InputCheckBox name={`${checkboxPrefix}${rowData.id}`} label="" />
  </InputCheckBoxWprapper>
);

export default class TableSelectable extends Table {
  updateCheckboxes = ([checked], state, { changeValue }) => {
    Object.keys(state.fields).map(key => changeValue(state, key, () => checked));
  };

  checkboxColumnOptions = mutators => {
    const { checkboxRender } = this.props;

    return [
      {
        label: ' ',
        headerRender: () => (
          <InputCheckBoxWprapper>
            <InputCheckBox
              name={`${checkboxPrefix}header`}
              label=""
              onChange={event => {
                mutators.updateCheckboxes(event.target.checked);
              }}
            />
          </InputCheckBoxWprapper>
        ),
        disableSort: true,
        key: 'isChecked',
        width: 75,
        render: (
          { rowData } // eslint-disable-line react/prop-types
        ) => checkboxRender({ rowData, checkboxDefaultRender: checkboxDefaultRenderFn(rowData) }),
      },
    ];
  };

  cleanOnSubmitValues = values => {
    const { onSubmit } = this.props;

    const cleanValues = Object.entries(values)
      .filter(([key]) => key !== `${checkboxPrefix}header`)
      .map(([key, value]) => {
        const cleanKey = key.split(checkboxPrefix)[1];

        return { [cleanKey]: value };
      })
      .reduce((checkboxes, checkbox) => ({ ...checkboxes, ...checkbox }), {});

    return onSubmit(cleanValues);
  };

  // eslint-disable-next-line react/prop-types
  renderTable = ({ height, width }) => {
    const { setExternalSubmit } = this.props;

    return (
      <Form
        onSubmit={this.cleanOnSubmitValues}
        initialValues={{ [`${checkboxPrefix}header`]: false }}
        mutators={{ updateCheckboxes: this.updateCheckboxes }}
      >
        {({ form, handleSubmit }) => {
          setExternalSubmit(handleSubmit);

          return (
            <form onSubmit={handleSubmit}>
              {this.renderDefaultTable({
                firstColumnsOptions: this.checkboxColumnOptions(form.mutators),
                width,
                height,
              })}
            </form>
          );
        }}
      </Form>
    );
  };
}

TableSelectable.defaultProps = {
  ...Table.defaultProps,
  checkboxRender: ({ checkboxDefaultRender }) => checkboxDefaultRender,
};

TableSelectable.propTypes = {
  ...Table.propTypes,
  onSubmit: PropTypes.func.isRequired,
  setExternalSubmit: PropTypes.func.isRequired,
  checkboxRender: PropTypes.func,
};
