import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children, portalId }) => {
  const [portalElement] = useState(document.createElement('div'));
  const portal = document.getElementById(portalId);

  useEffect(() => {
    if (portal) {
      portal.appendChild(portalElement);
    }
    return function cleanup() {
      if (portal) {
        portal.removeChild(portalElement);
      }
    };
  });

  return portal ? createPortal(children, portalElement) : null;
};

export default Portal;
