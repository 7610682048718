import Breadcrumb from './Breadcrumb';
import Button from './Button';
import Inputs from './Inputs';
import Loader from './Loader';
import Paper from './Paper';
import Snackbar from './Snackbar';

export default {
  Breadcrumb,
  Button,
  ...Inputs,
  Loader,
  Paper,
  Snackbar,
};
