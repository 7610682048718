import React, { useState, useEffect, useContext } from 'react';

import ApplicationLayoutContext from 'tol@framework/ApplicationLayout/ApplicationLayout.context';
import Form from 'tol@blocks/Form';
import CommentIcon from 'tol@assets/icon/Comment';
import { getMe, updatePassword } from '../../services/user.service';
import TabsContext from '../../Tabs.context';
import FormButtons from '../FormButtons';
import { InputPassword, InputContainer, PasswordContainer, InformationContainer, Content } from './styles';
import { Container, Title, ErrorMessage } from '../styles';

export function UpdatePasswordTitle() {
  return <div>Connection</div>;
}

export function UpdatePassword() {
  const { loader } = useContext(ApplicationLayoutContext);
  const { setSnackbar } = useContext(TabsContext);
  const [user, setUser] = useState({});
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState(false);

  let localForm;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        loader.start();
        const me = await getMe();

        setUser(me);
      } catch (error) {
        setErrorMessage(error.message);
        console.error(error);
      } finally {
        loader.stop();
      }
    };

    fetchUser();
  }, []);

  function isSamePassword(confirmPassword) {
    return confirmPassword === password ? undefined : 'Password and confirm password should be the same.';
  }

  async function onSave(values) {
    try {
      loader.start();

      await updatePassword(user.id, values);
      setPassword();
      setErrorMessage(false);
      setSnackbar({ active: true, message: 'Password updated' });

      return localForm && localForm.reset && localForm.reset();
    } catch (error) {
      if (error.violations) {
        return error.violations;
      }

      setErrorMessage(error.message);
      return undefined;
    } finally {
      loader.stop();
    }
  }

  return (
    <Container>
      <Title>Connection Information</Title>
      <Form
        onSubmit={onSave}
        renderButtons={FormButtons}
        render={({ form }) => {
          localForm = form;

          return (
            <Content error={!!errorMessage}>
              <PasswordContainer>
                <InputContainer>
                  <InputPassword
                    type="password"
                    name="password"
                    label="New password"
                    onBlur={event => setPassword(event.target.value)}
                    validation={isValidePassword}
                    required
                  />
                  <InputPassword
                    type="password"
                    name="passwordConfirmation"
                    label="Confirmation"
                    validation={isSamePassword}
                    required
                  />
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </InputContainer>
                <InformationContainer>
                  <CommentIcon />
                  <ul>
                    <p>These data are strickly personal. In no case they will be share. </p>
                    <br />
                    <p>Password must contain at least : </p>
                    <li>- 12 characters</li>
                    <li>- 1 special character</li>
                    <li>- 1 uppercase character</li>
                    <li>- 1 lowercase character</li>
                    <li>- 1 number</li>
                  </ul>
                </InformationContainer>
              </PasswordContainer>
            </Content>
          );
        }}
      />
    </Container>
  );
}

function isValidePassword(password) {
  function createErrorMessage(condition) {
    return `Password must contain at least ${condition}.`;
  }

  if (password.length < 12) {
    return createErrorMessage('12 characters');
  }

  if (!/^(?=.*\W)[a-zA-Z\d\W]/.test(password)) {
    return createErrorMessage('1 special character');
  }

  if (!/^(?=.*[A-Z])[a-zA-Z\d\W]/.test(password)) {
    return createErrorMessage('1 uppercase character');
  }

  if (!/^(?=.*[a-z])[a-zA-Z\d\W]/.test(password)) {
    return createErrorMessage('1 lowercase character');
  }

  if (!/^(?=.*\d)[a-zA-Z\d\W]/.test(password)) {
    return createErrorMessage('1 number');
  }

  return undefined;
}
