import { lighten } from 'polished';
import colors from '../../colors';

export default ({ primary }) => ({
  calendarDay: {
    hover: { backgroundColor: lighten(0.6, primary) },
    selected: { fontColor: colors.white, backgroundColor: primary },
  },
  header: {
    fontColor: colors.white,
    backgroundColor: primary,
  },
  preset: {
    borderColor: primary,
    active: {
      fontColor: colors.lightGray,
      backgroundColor: primary,
    },
    hover: {
      fontColor: colors.lightGray,
      backgroundColor: lighten(0.2, primary),
    },
  },
  icon: {
    backgroundColor: primary,
  },
  presets: {
    backgroundColor: colors.graySecondary,
  },
});
