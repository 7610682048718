import React, { useState, useEffect, useContext } from 'react';

import ApplicationLayoutContext from 'tol@framework/ApplicationLayout/ApplicationLayout.context';
import Form from 'tol@blocks/Form';
import { getMe, updateUser } from '../services/user.service';
import TabsContext from '../Tabs.context';
import FormButtons from './FormButtons';
import { InputContainer, InputText, Container, Title, Content, ErrorMessage } from './styles';

export function UpdateInformationsTitle() {
  return <div> General </div>;
}

export function UpdateInformations() {
  const { loader } = useContext(ApplicationLayoutContext);
  const { setSnackbar } = useContext(TabsContext);

  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);

  async function onSave(values) {
    try {
      loader.start();

      const userUpdated = await updateUser(values);

      setUser(userUpdated);
      setErrorMessage(false);
      setSnackbar({ active: true, message: 'Informations are saved' });
    } catch (error) {
      if (error.violations) {
        return error.violations;
      }

      setErrorMessage(error.message);
    } finally {
      loader.stop();
    }

    return undefined;
  }

  useEffect(
    () => {
      const fetchUser = async () => {
        try {
          loader.start();
          const me = await getMe();

          loader.stop();
          setUser(me);
        } catch (error) {
          setErrorMessage(error.message);
          console.error(error);
        }
      };

      fetchUser();
    },
    [errorMessage]
  );

  return (
    <Container>
      <Title>General Informations</Title>
      <Form initialValues={user} onSubmit={values => onSave(values)} renderButtons={FormButtons}>
        <Content error={!!errorMessage}>
          <InputContainer>
            <InputText name="lastName" label="Name" validation={validateTextFields} required />
            <InputText name="firstName" label="First name" validation={validateTextFields} required />
            <InputText name="email" label="Email" validation={validateMailField} required />
          </InputContainer>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Content>
      </Form>
    </Container>
  );
}

const validateTextFields = value => (value && /^[a-zA-Z]+$/.test(value) ? undefined : 'Must contain only letters');
// eslint-disable-next-line no-useless-escape
const regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateMailField = value => (value && regexMail.test(value) ? undefined : 'Your email must be valid');
