import * as themes from './themes';
import components from './components';
import framework from './framework';
import assets from './assets';

import './css';

const {
  TimeOneRoutes,
  TimeOneConfig,
  ApplicationLayout,
  ApplicationLayoutContext,
  Dashboard,
  withBreadcrumb,
  SidebarContainer,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  IsAuthenticate,
  setFrameworkConfig,
} = framework;

const {
  blocks,
  elements,
  // components
  Footer,
  Form,
  Header,
  HeaderNavbar,
  Pagination,
  SidebarNav,
  OldSidebarContent,
  Table,
  TableSelectable,
  Tabs,
  Wizard,
  Button,
  InputAutocomplete,
  InputCheckBox,
  InputButtonRadio,
  InputText,
  InputTextarea,
  InputSelect,
  InputDatePicker,
  InputDateRangePicker,
  InputSelectCreatable,
  Loader,
  Paper,
  RowTotal,
  Snackbar,
  // High Order Components
  IsLogged,
  // utils
  token,
  apolloBoostDefaultConfig,
  createTableKeys,
  formatTableData,
  formatTableTotalValues,
  generateFormFields,
} = components;

export {
  components as default,
  assets,
  blocks,
  elements,
  themes,
  ApplicationLayout,
  ApplicationLayoutContext,
  Button,
  withBreadcrumb,
  Dashboard,
  Footer,
  Form,
  Header,
  HeaderNavbar,
  InputAutocomplete,
  InputCheckBox,
  InputButtonRadio,
  InputDatePicker,
  InputDateRangePicker,
  InputSelect,
  InputSelectCreatable,
  InputText,
  InputTextarea,
  IsLogged,
  Loader,
  Paper,
  Pagination,
  RowTotal,
  SidebarContainer,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  OldSidebarContent,
  SidebarNav,
  Snackbar,
  Table,
  TableSelectable,
  Tabs,
  Wizard,
  token,
  apolloBoostDefaultConfig,
  createTableKeys,
  formatTableData,
  formatTableTotalValues,
  generateFormFields,
  TimeOneRoutes,
  TimeOneConfig,
  IsAuthenticate,
  setFrameworkConfig,
};
