import { css } from 'styled-components';

const buttonType = {
  default: defaultButtonStyle,
  submit: defaultButtonStyle,
  rounded: roundedButtonStyle,
  action: roundedButtonStyle,
  cancel: cancelButtonStyle,
};

export default function buttonStyle() {
  return css`
    appearance: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    margin: 1rem;
    outline: none;
    padding: 0.5rem 1rem;
    transition: all 0.15s ease-in-out;

    &:hover {
      ${({ appearance }) =>
        appearance === 'rounded'
          ? css`
              box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
                0 3px 4px 0 rgba(0, 0, 0, 0.14);
            `
          : ''}
      ${({ fill, theme }) => setColors(!fill || fill === 'false' ? theme.button.hover : theme.button.fill.hover)};
    }

    &:active {
      box-shadow: none;
      opacity: 0.95;
      transform: translateY(1px);
    }

    &:first-letter {
      text-transform: uppercase;
    }
    ${({ appearance }) => (buttonType[appearance] && buttonType[appearance]()) || buttonType.default()};
    ${({ disabled }) => disabled && setDisabled()};
    ${({ appearance, disabled, fill }) => appearance !== 'cancel' && fill !== 'true' && !disabled && setBorder()};
  `;
}

const commonStyle = {
  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  hover: {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px -1px rgba(0, 0, 0, 0.056), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  },
};

function defaultButtonStyle() {
  return css`
    border-radius: 2px;

    ${({ theme, fill }) => setColors(!fill || fill === 'false' ? theme.button : theme.button.fill)}
    ${({ fill }) => (fill && fill !== 'false' ? `box-shadow:${commonStyle.boxShadow};` : '')}
  `;
}

function roundedButtonStyle() {
  return css`
    border-radius: 2.5rem;
    font-size: 0.85rem;
    padding: 0.5rem 1.75rem;

    ${({ theme, fill }) => setColors(!fill || fill === 'false' ? theme.button : theme.button.fill)}
    ${({ appearance, fill }) =>
      fill && fill !== 'false' && appearance === 'rounded' ? `box-shadow:${commonStyle.boxShadow};` : ''}
  `;
}

function cancelButtonStyle() {
  return css`
    box-sizing: border-box;
    font-size: 0.75rem;

    ${({ theme, fill }) => setColors(!fill || fill === 'false' ? theme.button.cancel : theme.button.fill)}

    &:hover {
      ${({ theme, fill }) => setColors(!fill || fill === 'false' ? theme.button.cancel.hover : theme.button.fill.hover)}
    }
  `;
}

function setColors({ fontColor, backgroundColor }) {
  return css`
    background-color: ${({ primary }) =>
      !primary || primary === 'false' ? backgroundColor.secondary : backgroundColor.primary};
    color: ${({ primary }) => (!primary || primary === 'false' ? fontColor.secondary : fontColor.primary)};

    ${({ validation }) =>
      validation &&
      css`
        background: white;
        color: palevioletred;
      `};
  `;
}

function setDisabled() {
  return css`
    cursor: not-allowed;
    pointer-events: none;
    ${({ theme, appearance }) =>
      setColors(appearance === 'cancel' ? theme.button.cancel.disabled : theme.button.disabled)};
  `;
}

function setBorder() {
  return css`
    border: ${({ theme }) => theme.button.border.type} ${({ theme }) => theme.button.border.size}
      ${({ primary, theme }) =>
        !primary || primary === 'false' ? theme.button.border.color.secondary : theme.button.border.color.primary};
  `;
}
