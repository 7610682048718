import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

import { utils } from 'tol@themes';
import token from 'tol@utils/token';
import { TIMEONE_LOGIN_ERROR_TYPE } from 'tol@utils/errors';
import { getConfig } from 'tol@framework/TimeOneConfig/configStore';
import LoaderContainer from './styles';

function IsAuthenticate({ RenderComponent, theme }) {
  const {
    urls: { authenticationUrl, cookieDomain },
  } = getConfig();
  const [authenticate, setAuthenticate] = useState({ success: false, loading: true });

  useEffect(() => {
    async function checkAuthentication() {
      try {
        await token.validateToken();
        localStorage.removeItem('authentificationErrorRetry');

        setAuthenticate({ success: true, loading: false });
      } catch (error) {
        if (error.type === TIMEONE_LOGIN_ERROR_TYPE) {
          token.clear(cookieDomain);
          token.redirectToSSO(authenticationUrl);

          return;
        }

        setAuthenticate({ success: false, loading: false });

        throw error;
      }
    }

    checkAuthentication();
  }, []);

  return (
    <>
      {(!authenticate.success || authenticate.loading) && (
        <LoaderContainer>
          <Loader type="Oval" color={theme.secondary} height="100" width="100" />
        </LoaderContainer>
      )}
      {authenticate.success && !authenticate.loading && <RenderComponent />}
    </>
  );
}

IsAuthenticate.defaultProps = {
  theme: utils.themeFallback(),
};

IsAuthenticate.propTypes = {
  RenderComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]).isRequired,
  theme: PropTypes.shape({}),
};

export default IsAuthenticate;
