import React from 'react';
import PropTypes from 'prop-types';

import DefaultInput from '../DefaultInput';
import Textarea from './styles';

export default class InputTextarea extends DefaultInput {
  inputRender = (inputProps, meta) => {
    const { name, rows, cols } = this.props;
    const { onChange, onFocus, onBlur } = inputProps;

    return (
      <Textarea
        className={`tol-input__textarea ${this.handleIsActive(inputProps, meta) ? 'active' : null}`}
        id={name}
        name={name}
        type="textarea"
        rows={rows}
        cols={cols}
        {...inputProps}
        onChange={this.handleChange(onChange)}
        onFocus={this.handleFocus(onFocus)}
        onBlur={this.handleBlur(onBlur)}
      />
    );
  };
}

InputTextarea.defaultProps = {
  rows: 3,
  cols: 10,
};

InputTextarea.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
};
