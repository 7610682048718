import React from 'react';
import PropTypes from 'prop-types';

import randomStringId from 'tol@utils/random';
import ApplicationLayoutContext from 'tol@framework/ApplicationLayout/ApplicationLayout.context';
import HeaderNavbarStyled from './styles';

const MenuEntry = ({ children, className, entry }) => {
  const { name, url, to, onClick, subEntries } = entry;

  return (
    <ApplicationLayoutContext.Consumer>
      {({ router }) => (
        <li className={className} key={randomStringId()}>
          <a
            href={url}
            onClick={() => {
              if (router && router.history) {
                router.history.push(to);
              }

              return onClick && onClick();
            }}
          >
            {children}
            {name}
          </a>
          {subEntries && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
              <path d="M40.4 121.3c-.8.8-1.8 1.2-2.9 1.2s-2.1-.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8 0l53.9 53.9c1.6 1.6 1.6 4.2 0 5.8l-53.9 53.9z" />
            </svg>
          )}
          {subEntries && <Menu entries={subEntries} />}
        </li>
      )}
    </ApplicationLayoutContext.Consumer>
  );
};

MenuEntry.defaultProps = {
  children: null,
  className: '',
  entry: {},
};

MenuEntry.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  entry: PropTypes.shape({}),
};

const Menu = ({ className, entries, render }) => (
  <ul className={className} key={randomStringId()}>
    {entries.map(entry => {
      if (render) {
        return render({ entry });
      }

      if (entry.children) {
        return entry.children;
      }

      if (entry.render) {
        return entry.render({ entry });
      }

      return <MenuEntry key={entry.id} entry={entry} />;
    })}
  </ul>
);

Menu.defaultProps = {
  className: '',
  entries: [],
  render: null,
};

Menu.propTypes = {
  className: PropTypes.string,
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  render: PropTypes.func,
};

const HeaderNavbar = ({ entries, selectedEntry, colorName, className }) => (
  <HeaderNavbarStyled className={`header__navbar ${className} theme-${colorName}`}>
    <Menu
      entries={entries}
      render={({ entry }) => (
        <li
          className={`nav-menu${selectedEntry.name === entry.name ? ' nav-menu--active' : ''}`}
          key={randomStringId()}
        >
          {entry.render ? (
            entry.render(entry)
          ) : (
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            <a href={!entry.subEntries ? entry.url : undefined} onClick={!entry.subEntries ? entry.onClick : undefined}>
              {entry.children}
              {entry.name}
            </a>
          )}
          {entry.subEntries && <Menu entries={entry.subEntries} />}
        </li>
      )}
    />
  </HeaderNavbarStyled>
);

HeaderNavbar.defaultProps = {
  className: '',
  colorName: 'gray',
  customStyle: null,
  entries: [],
  selectedEntry: null,
};

const entriesPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  subEntries: entriesPropTypes,
});

HeaderNavbar.propTypes = {
  className: PropTypes.string,
  colorName: PropTypes.string, // eslint-disable-line react/no-unused-prop-types, used in StyledHeaderNavbar
  customStyle: PropTypes.string, // eslint-disable-line react/no-unused-prop-types, used in StyledHeaderNavbar
  entries: PropTypes.arrayOf(entriesPropTypes),
  selectedEntry: PropTypes.shape({}),
};

export { HeaderNavbar as default, Menu, MenuEntry };
