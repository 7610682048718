import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { UserHeaderMenu } from '../../../User';
import { NotificationHeaderMenu } from '../../../Notification';

export default class HeaderBlockRight extends PureComponent {
  render() {
    const { notifications, user, onLogout } = this.props;

    return (
      <div className="header__header-right">
        {notifications && <NotificationHeaderMenu notifications={notifications} />}
        {user && <UserHeaderMenu user={user} onLogout={onLogout} />}
      </div>
    );
  }
}

HeaderBlockRight.defaultProps = {
  notifications: null,
  user: null,
  onLogout: () => {},
};

HeaderBlockRight.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      content: PropTypes.string,
      dateTime: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  user: PropTypes.shape({
    name: PropTypes.string,
    editable: PropTypes.any,
    photoUrl: PropTypes.string,
  }),
  onLogout: PropTypes.func,
};
