import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  HeaderContainer,
  DayContainer,
  MonthYearContainer,
  DateContainer,
  DateDay,
  HeaderColumnDateContainer,
  HeaderColumnTitle,
  HeaderColumnContainer,
} from './styles';

const Header = props => {
  const { className, endDate, startDate } = props;

  return (
    <HeaderContainer {...props} className={`header ${className}`}>
      <HeaderColumnContainer className="header__header-column">
        <HeaderColumnTitle className="header__header-column__title">Start Date</HeaderColumnTitle>
        <HeaderColumnDateContainer className="header__header-column__date date">
          <DateDay className="date__day-number">{startDate && moment(startDate).format('DD')}</DateDay>
          <DateContainer className="date__text-container">
            <MonthYearContainer className="date__text-container__month-year">
              {startDate && moment(startDate).format('MMMM YYYY')}
            </MonthYearContainer>
            <DayContainer className="date__text-container__day">
              {startDate && moment(startDate).format('dddd')}
            </DayContainer>
          </DateContainer>
        </HeaderColumnDateContainer>
      </HeaderColumnContainer>
      <HeaderColumnContainer className="header__header-column">
        <HeaderColumnTitle className="header__header-column__title">End Date</HeaderColumnTitle>
        <HeaderColumnDateContainer className="header__header-column__date date">
          <DateDay className="date__day-number">{endDate && moment(endDate).format('DD')}</DateDay>
          <DateContainer className="date__text-container">
            <MonthYearContainer className="date__text-container__month-year">
              {endDate && moment(endDate).format('MMMM YYYY')}
            </MonthYearContainer>
            <DayContainer className="date__text-container__day">
              {endDate && moment(endDate).format('dddd')}
            </DayContainer>
          </DateContainer>
        </HeaderColumnDateContainer>
      </HeaderColumnContainer>
    </HeaderContainer>
  );
};

export default Header;

Header.defaultProps = {
  className: '',
};

Header.propTypes = {
  className: PropTypes.string,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
};
