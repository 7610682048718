import styled from 'styled-components';
import { transparentize } from 'polished';

import { utils } from 'tol@themes';

const LabelContainer = styled.div`
  margin: 0.75rem 0.5rem 0.25rem;
  position: relative;
  text-align: left;
  width: calc(100% - 1rem);
`;

const Label = styled.label.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  appearance: none;
  display: block;
  position: relative;
  width: 100%;

  &::before {
    background-color: ${({ theme, error }) => (error ? theme.input.error.fontColor : theme.input.active.borderColor)};
    bottom: 0;
    content: '';
    height: 0.0625rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::after {
    background: ${({ theme, error }) => (error ? theme.input.error.fontColor : theme.input.active.borderColor)};
    bottom: 0;
    content: '';
    display: block;
    left: 25%;
    height: 0.125rem;
    position: absolute;
    transition: all 0.5s;
    width: 0;
  }

  &.active {
    &::after {
      left: 0;
      width: 100%;
    }
  }
`;

const LabelSpan = styled.span.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  left: 0.25rem;
  position: absolute;
  top: 1.25rem;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.18, 1.04);

  &.active {
    font-size: 0.875rem;
    left: 0.125rem;
    opacity: 0.75;
    top: 0;
  }
`;

const LabelError = styled.span.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  color: ${({ theme }) => theme.input.error.fontColor};
  font-size: x-small;
  left: 0.125rem;
  position: absolute;
  top: calc(100% + 0.125rem);
`;

const Input = styled.input.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  color: ${({ theme }) => theme.input.fontColor};
  font-size: 1rem;
  font-weight: lighter;
  margin-top: 1.5rem;
  padding: 0 0.25rem 0.375rem;
  outline: none;
  transition: all 1s;
  width: calc(100% - 0.5rem);

  &::placeholder {
    visibility: hidden;
  }

  &.active {
    &::placeholder {
      visibility: visible;
    }
  }
`;

const Optional = styled.span.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  bottom: 0.0625rem;
  color: ${({ theme }) => transparentize(0.5, theme.input.fontColor)};
  font-size: small;
  left: 0.25rem;
  position: relative;
`;

export { Label as default, LabelContainer, Label, LabelSpan, LabelError, Input, Optional };
