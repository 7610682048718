import React from 'react';
import PropTypes from 'prop-types';

import { ProgressBarContainer, Progress, Filler } from './styles';

function ProgressBar({ step, maxStep, displayLabel, renderLabel }) {
  return (
    <ProgressBarContainer>
      <Progress>
        <Filler max={maxStep} value={step} />
      </Progress>
      {displayLabel && renderLabel(step, maxStep)}
    </ProgressBarContainer>
  );
}

ProgressBar.defaultProps = {
  renderLabel: (step, maxStep) => (
    <div>
      Etape {step}/{maxStep}
    </div>
  ),
  displayLabel: true,
};

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  renderLabel: PropTypes.func,
  displayLabel: PropTypes.bool,
};

export default ProgressBar;
