import React from 'react';
import PropTypes from 'prop-types';
import elements from 'tol@elements';

import { ContentWrapper, ErrorContainer } from './styles';
import MESSAGES from './constants/messages';

const { Button } = elements;

function Container(props) {
  const { error, onRefresh, children } = props;

  return (
    <ContentWrapper>
      {error ? (
        <ErrorContainer>
          <h4>{MESSAGES.NETWORK_ERROR}</h4>
          <span>{MESSAGES.NETWORK_ERROR_RETRY}</span>
          <Button onClick={onRefresh} appearance="cancel" primary fill="primary">
            Refresh
          </Button>
        </ErrorContainer>
      ) : (
        children
      )}
    </ContentWrapper>
  );
}

Container.defaultProps = {
  error: false,
  onRefresh: () => {},
};

Container.propTypes = {
  error: PropTypes.bool,
  onRefresh: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Container;
