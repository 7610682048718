import moment from 'moment';
import _ from 'lodash';

export default _.memoize(lastAvailableDay => [
  {
    label: 'today',
    startDate: moment(),
    endDate: moment(),
  },
  {
    label: 'yesterday',
    startDate: moment().subtract(1, 'days'),
    endDate: moment().subtract(1, 'days'),
  },
  {
    label: 'current week',
    startDate: moment().startOf('isoWeek'),
    endDate: lastAvailableDay,
  },
  {
    label: 'last week',
    startDate: moment()
      .subtract(1, 'weeks')
      .startOf('isoWeek'),
    endDate: moment()
      .subtract(1, 'weeks')
      .endOf('isoWeek'),
  },

  {
    label: 'current month',
    startDate: moment().startOf('month'),
    endDate: lastAvailableDay,
  },
  {
    label: 'last month',
    startDate: moment()
      .subtract(1, 'months')
      .startOf('month'),
    endDate: moment()
      .subtract(1, 'months')
      .endOf('month'),
  },
  {
    label: 'last 6 months',
    startDate: moment()
      .subtract(6, 'months')
      .startOf('month'),
    endDate: moment()
      .subtract(1, 'months')
      .endOf('month'),
  },
  {
    label: 'this year',
    startDate: moment().startOf('year'),
    endDate: lastAvailableDay,
  },
  {
    label: 'last year',
    startDate: moment()
      .subtract(1, 'year')
      .startOf('year'),
    endDate: moment()
      .subtract(1, 'year')
      .endOf('year'),
  },
]);
