import styled from 'styled-components';
import { utils } from 'tol@themes';

import Paper from '../../../../components/elements/Paper';
import TocInputText from '../../../../components/elements/Inputs/InputText';

export const Content = styled(Paper).attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  border: ${({ error, theme }) => (error ? `solid ${theme.colors.error} 2px` : 'none')};
  text-align: left;
`;

export const InputContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 10px;
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InformationContainer = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  padding: 2rem;
  width: 250px;

  svg {
    height: 1rem;
    margin-right: 1rem;
    min-width: 1rem;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const InputPassword = styled(TocInputText)`
  max-width: 350px;
  padding-right: 2rem;
`;

export default {
  InputContainer,
  PasswordContainer,
  InformationContainer,
  InputPassword,
};
