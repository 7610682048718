import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import PresetContainer from './styles';

const Preset = props => {
  const { active, className, label } = props;

  return (
    <CSSTransition in={active} classNames="active" timeout={200}>
      <PresetContainer {...props} type="button" className={`preset ${className}`}>
        <span>{label}</span>
      </PresetContainer>
    </CSSTransition>
  );
};

export default Preset;

Preset.defaultProps = {
  className: '',
};

Preset.propTypes = {
  className: PropTypes.string,
};
