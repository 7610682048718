import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import defaultTheme from 'tol@themes';
import BurgerIcon from 'tol@assets/icon/Burger';

const HeaderBlockLeft = ({
  noSidebar,
  openSidebar,
  logo,
  title,
  titleSuffix,
  leftHeaderComponent,
  children,
  theme,
}) => (
  <div className="header__header-left">
    {!noSidebar && (
      <button type="button" onClick={openSidebar} className="header__header-left__button-toggle-sidebar">
        <BurgerIcon className="header__header-left__button-toggle-sidebar__icon" />
      </button>
    )}
    {logo.url ? (
      <img className="header__header-left__tool-logo" src={logo.url} alt="tool-logo" />
    ) : (
      <img className="header__header-left__tool-logo" src={theme.header.logo.url} alt="tool-logo" />
    )}
    {leftHeaderComponent({ title, titleSuffix })}
    {children}
  </div>
);

export default withTheme(HeaderBlockLeft);

HeaderBlockLeft.defaultProps = {
  children: '',
  theme: defaultTheme,
  title: 'Header',
  titleSuffix: '',
  logo: {},
  openSidebar: () => {},
  noSidebar: false,
  leftHeaderComponent: defaultLeftComponent,
};

HeaderBlockLeft.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  theme: PropTypes.shape({}),
  openSidebar: PropTypes.func,
  noSidebar: PropTypes.bool,
  title: PropTypes.string,
  titleSuffix: PropTypes.string,
  logo: PropTypes.shape({
    url: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  leftHeaderComponent: PropTypes.func,
};

function defaultLeftComponent({ title, titleSuffix }) {
  return (
    <h1 className="header__header-left__main-title">
      {title}
      <span className="header__header-left__main-title__title-suffix">{titleSuffix}</span>
    </h1>
  );
}

defaultLeftComponent.defaultProps = {
  title: 'Header',
  titleSuffix: '',
};

defaultLeftComponent.propTypes = {
  title: PropTypes.string,
  titleSuffix: PropTypes.string,
};
