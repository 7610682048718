import { COLUMN_DEFAULT_PROPERTIES } from 'tol@blocks/Table/constants';
import formats from '../format';
import FORMATS_PROPERTIES from './constants';

const { align: defaultAlign, width: defaultWidth } = COLUMN_DEFAULT_PROPERTIES;
const { currency: defaultCurrency, locale: defaultLocale } = FORMATS_PROPERTIES.default;

const formatTableTotalValues = (
  { columns, total } = {},
  { currency = defaultCurrency, locale = defaultLocale } = {}
) => {
  const labelWidth = (columns[0] && columns[0].format && columns[0].format.width) || defaultWidth;
  const values = columns.slice(1).map(column => {
    const { format = {}, slug } = column;
    const { name, set } = format;
    const { width = defaultWidth } = format;

    let { align } = format;

    if (!align) {
      align = FORMATS_PROPERTIES[name] ? FORMATS_PROPERTIES[name].align : defaultAlign;
    }

    const value =
      formats[name] && total[slug]
        ? formats[name]({
            value: total[slug],
            currency,
            locale,
            set,
          })
        : total[slug];

    return { align, dataKey: slug, value, width: parseInt(width, 10) };
  });

  return { labelWidth: parseInt(labelWidth, 10), total: values };
};

export default formatTableTotalValues;
