import styled, { css } from 'styled-components';

import { utils } from 'tol@themes';
import { COLUMN_DEFAULT_PROPERTIES } from './constants';

export default styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  &.tolTableWrapper {
    background-color: ${({ theme }) => theme.table.backgroundColor};
    box-shadow: ${({ theme }) => theme.shadows.dp1};
    overflow-y: hidden;
    overflow-x: auto;
    transition: all 0.1s ease;
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    width: ${({ width }) => (width ? `${width}px` : '100%')};

    .table {
      background-color: ${({ theme }) => theme.table.backgroundColor};

      .ReactVirtualized__Table {
        border-radius: 2px;
        font-size: 0.75rem;
        transition: all 0.5s ease;
        ${({ theme }) => virtualizedHeader(theme.table.header)};
        ${({ theme }) => virtualizedTable(theme.table.container)};
      }
    }

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows.dp2};
    }

    &:active {
      box-shadow: ${({ theme }) => theme.shadows.dp1};
    }
  }
`;

function virtualizedHeader({ backgroundColor }) {
  return css`
    .ReactVirtualized__Table__headerRow {
      background-color: ${backgroundColor};
      border-bottom: 1px solid ${({ theme }) => theme.table.container.border.color};
      overflow: visible;
      padding-right: 0 !important;

      .ReactVirtualized__Table__headerColumn {
        align-items: center;
        flex: 1;
        flex-grow: 1 !important;
        height: calc(100% - 15px);
        margin: 0;
        padding: 0 10px;
        padding-top: 15px;
        text-align: center;
        width: 100%;

        &.ReactVirtualized__Table__sortableHeaderColumn {
          padding-top: 20px;
          height: calc(100% - 20px);
          display: block;
        }

        &:last-child {
          margin-right: 1.25rem;
        }

        ${({ keys }) =>
          keys &&
          keys.map(
            (key, index) => `
              &:nth-child(${index + 1}) {
                min-width: calc(${key.width ? key.width : COLUMN_DEFAULT_PROPERTIES.width}px - ${
              keys.length > index + 1 ? '20px' : '35px'
            });
                width: 100%;
                flex-grow: 1!important;
              }
            `
          )}

        .ReactVirtualized__Table__headerTruncatedText {
          font-weight: 700;
          text-transform: capitalize;
        }

        label {
          margin-bottom: 0;
        }
      }
    }
  `;
}

function virtualizedTable({ border, child }) {
  return css`
    .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
      transition: all 0.2s ease-in-out;

      &:focus {
        outline: none;
      }

      .ReactVirtualized__Grid__innerScrollContainer {
        border-right: 1px inset ${border.color};

        .ReactVirtualized__Table__row {
          position: relative;
          transition: all 0.1s ease;
          width: 100%;

          &::after {
            background: ${border.color};
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
          }

          &:last-child {
            &::after {
              height: 0;
            }
          }

          .ReactVirtualized__Table__rowColumn {
            text-align: ${({ align }) => align};
            flex: 1;
            height: calc(100% - 15px);
            align-items: center;
            margin: 0;
            padding: 0 10px;
            padding-top: 15px;

            &:last-child {
              margin-right: 1.25rem;
            }
            /* stylelint-disable-next-line */
            ${({ keys }) =>
              keys &&
              keys.map(
                (key, index) => `
                  &:nth-child(${index + 1}) {
                    text-align: ${key.align || COLUMN_DEFAULT_PROPERTIES.align};
                    min-width:
                    calc(${key.width ? key.width : COLUMN_DEFAULT_PROPERTIES.width}px - ${
                  keys.length > index + 1 ? '20px' : '35px'
                });
                    width: 100%;
                    flex-grow: 1!important;
                  }
                  `
              )};
          }

          .tolTableWrapper__OptionsColumn {
            opacity: 0;
            transition: all 0.1s ease-in-out;
          }

          label {
            margin-bottom: 0;
          }

          & > div {
            overflow: hidden;
            position: relative;
            transition: all 0.25s;
            width: 100%;
          }

          &:hover {
            background-color: ${child.hover.backgroundColor};

            .tolTableWrapper__OptionsColumn {
              opacity: 1;
            }
          }

          &:nth-child(odd) {
            background-color: ${child.odd.backgroundColor};
          }

          &:nth-child(even) {
            background-color: ${child.even.backgroundColor};
          }
        }
      }
    }
  `;
}
