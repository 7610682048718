import styled from 'styled-components';

import { utils } from 'tol@themes';

const PresetListContainer = styled.ul.attrs(({ theme, position }) => ({
  position: position || 'left',
  theme: utils.themeFallback(theme),
}))`
  background-color: ${({ theme }) => theme.input.datepicker.presets.backgroundColor};
  box-shadow: ${({ theme }) => theme.shadows.dp1};
  display: flex;
  flex-direction: column;
  height: calc(100% - 1.75rem);
  opacity: 0;
  overflow: hidden;
  padding: 0.875rem 0.5rem;
  position: absolute;
  ${({ position }) => (position === 'left' ? 'right' : 'left')}: 100%;
  top: 0;
  transition: opacity 0.25s ease-in, width 0.75s cubic-bezier(0.55, 0.14, 0.34, 1.01);
  transition-delay: 0.25s;
  width: 0;

  &.expand-appear {
    opacity: 0;
    width: 0;
  }

  &.expand-appear-active {
    opacity: 1;
    width: 8rem;
  }

  &.expand-enter-done {
    opacity: 1;
    width: 8rem;
  }
`;

const ListItem = styled.li`
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.25s ease-in, width 0.5s;
  transition-delay: ${({ index }) => `${((index + 1) / 2) * 0.25}s`};
  width: 0;

  &.expand-appear {
    opacity: 0;
    width: 0;
  }

  &.expand-enter-done {
    opacity: 1;
    width: 100%;
  }
`;

export { PresetListContainer, ListItem };
