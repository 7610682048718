import React from 'react';
import PropTypes from 'prop-types';

import SnackbarStyled from './style';

const Snackbar = ({ children, className, type }) => (
  <SnackbarStyled type={type} className={`snackbar snackbar--${type} ${className}`}>
    <div className="snackbar__content"> {children} </div>
  </SnackbarStyled>
);

Snackbar.defaultProps = {
  children: null,
  className: '',
  type: 'info',
};

Snackbar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

export default Snackbar;
