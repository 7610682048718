import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';

import { ApplicationLayoutContext } from 'tol@framework/ApplicationLayout';
import Container from './Container';
import Header from './Header';
import PeriodPicker from './PeriodPicker';
import Table from './Table';
import Export from './Export';
import Groups from './Groups';
import Filters from './Filters';

import {
  getConfig as getConfigSrv,
  getData as getDataSrv,
  getExportDownloadURL as getExportDownloadURLSrv,
} from '../services/dashboard';
import MESSAGES from './constants/messages';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const { defaultPeriod } = props;

    this._initialState = {
      locked: false,
      error: false,
      loader: {
        loading: true,
        loadingExport: false,
        message: null,
      },
      exportDownloadURL: null,
      form: {
        groupFields: [],
        filterFields: [],
      },
      table: {
        keys: [],
        items: [],
        totalItems: null,
        order: null,
      },
      columns: [],
      filters: {},
      defaultColumns: [],
      period: defaultPeriod,
    };

    this.state = this._initialState;
  }

  async componentDidMount() {
    const { period } = this.state;
    const { fetchConfig, fetchData, exportConfig } = this.props;

    await this.setState({
      getConfig: getConfigSrv(fetchConfig),
      getData: getDataSrv(fetchData),
      getExportDownloadURL: getExportDownloadURLSrv(exportConfig),
    });

    this._fetchConfigAndData({ period });
  }

  _handlePeriodSubmit = ({ period }) => {
    const { locked, columns, table, filters } = this.state;

    if (!locked) {
      this.setState({ period });
      this._fetchOnlyData({ columns, period, order: table.order, filters });
    }
  };

  _handleOrderChange = ({ order: tableOrder }) => {
    const { locked, columns, period, table, filters } = this.state;

    const order = tableOrder.slug !== table.order.slug ? { ...tableOrder, direction: 'DESC' } : tableOrder;

    if (!locked) {
      this._fetchOnlyData({ columns, period, order, filters });
    }
  };

  _handleRefreshAfterError = async () => {
    await this.setState({ locked: false, error: false });

    this._fetchConfigAndData({ period: this._initialState.period });
  };

  _handleParametersReset = () => {
    const { locked, defaultColumns } = this.state;

    if (!locked) {
      const changes = {
        filters: {},
        table: this._initialState.table,
        columns: defaultColumns,
        period: this._initialState.period,
        error: false,
      };

      this._fetchOnlyData(changes);
    }
  };

  _handleExportTrigger = () => {
    const { locked, columns, filters, period } = this.state;

    if (!locked && columns) {
      this._fetchExportDownloadURL({ columns, filters, period });
    }
  };

  _handleExportDownload = () => {
    this.setState({ exportDownloadURL: null });
  };

  _handleGroupsToggle = () => {
    const { locked, columns, form } = this.state;

    if (!locked) {
      const { rightSidebar } = this.context;

      if (rightSidebar.content.type !== Groups || !rightSidebar.isOpen) {
        rightSidebar.setSidebarContent(
          <Groups
            fields={form.groupFields}
            groups={columns}
            onSubmit={this._handleGroupsSubmit}
            onClose={this._handleGroupsToggle}
          />
        );
      }

      if (!rightSidebar.isOpen || (rightSidebar.isOpen && rightSidebar.content.type === Groups)) {
        rightSidebar.toggle();
      }
    }
  };

  _handleGroupsSubmit = columns => {
    const { period, filters, table } = this.state;
    const { rightSidebar } = this.context;

    rightSidebar.close();

    this._fetchOnlyData({ columns, period, filters, order: table.order });
    this.setState({ columns });
  };

  _handleFiltersToggle = () => {
    const { locked, filters, form } = this.state;
    const { rightSidebar } = this.context;

    if (!locked) {
      if (rightSidebar.content.type !== Filters || !rightSidebar.isOpen) {
        rightSidebar.setSidebarContent(
          <Filters
            fields={form.filterFields}
            filters={filters}
            onSubmit={this._handleFiltersSubmit}
            onClose={this._handleFiltersToggle}
          />
        );
      }

      if (!rightSidebar.isOpen || (rightSidebar.isOpen && rightSidebar.content.type === Filters)) {
        rightSidebar.toggle();
      }
    }
  };

  _handleFiltersSubmit = filters => {
    const { period, columns, table } = this.state;
    const { rightSidebar } = this.context;

    rightSidebar.close();

    this._fetchOnlyData({ columns, filters, period, order: table.order });
    this.setState({ filters });
  };

  _handleNetworkError = () => {
    this.setState({
      error: true,
    });
  };

  async _fetchConfigAndData({ period }) {
    const { getConfig, getData } = this.state;
    const { loader } = this.context;

    try {
      loader.start(MESSAGES.LOADING_DEFAULT_DATA);
      this.setState(() => ({
        locked: true,
      }));
      const { defaultColumns, groupFields, filterFields } = await getConfig();
      const { keys, items, totalItems, order } = await getData({
        columns: defaultColumns,
        period,
      });

      this.setState(() => ({
        locked: false,
        columns: defaultColumns,
        defaultColumns,
        form: {
          groupFields,
          filterFields,
        },
        table: {
          keys,
          items,
          totalItems,
          order,
        },
      }));
      loader.stop();
    } catch (error) {
      console.error('error', error);
      loader.stop();
      this._handleNetworkError();
    }
  }

  async _fetchOnlyData({ columns, period, order, filters }) {
    const { getData } = this.state;

    const { loader } = this.context;

    try {
      loader.start(MESSAGES.LOADING_DATA);

      const { keys, items, totalItems, order: newOrder } = await getData(
        Object.assign(
          {
            columns,
            period,
          },
          order ? { order } : {},
          filters ? { filters } : {}
        )
      );

      this.setState(() => ({
        table: {
          keys,
          items,
          totalItems,
          order: newOrder,
        },
        filters,
        columns,
        period,
      }));
      loader.stop();
    } catch (error) {
      loader.stop();
      console.error('error', error);

      this._handleNetworkError();
    }
  }

  async _fetchExportDownloadURL({ columns, filters, period }) {
    const { getExportDownloadURL } = this.state;

    try {
      this.setState(({ loader }) => ({
        loader: { ...loader, loadingExport: true },
      }));

      const { downloadURL } = await getExportDownloadURL({
        columns,
        filters,
        period,
      });

      this.setState(({ loader }) => ({
        loader: { ...loader, loadingExport: false },
        exportDownloadURL: downloadURL,
      }));
    } catch (error) {
      console.error('error', error);
      this._handleNetworkError();
    }
  }

  render() {
    const { table, loader, period, exportDownloadURL, error } = this.state;
    const { dateDisplayFormat, title, defaultPeriod, exportConfig, className } = this.props;

    return (
      <Container className={className} error={error} onRefresh={this._handleRefreshAfterError}>
        <Header
          title={title}
          loadingExport={loader.loadingExport}
          onReset={this._handleParametersReset}
          onExport={exportConfig && this._handleExportTrigger}
          onGroup={this._handleGroupsToggle}
          onFilter={this._handleFiltersToggle}
          renderDatePicker={() => (
            <PeriodPicker
              period={period}
              lastAvailableDay={defaultPeriod.endDate}
              onSubmit={this._handlePeriodSubmit}
              displayFormat={dateDisplayFormat}
            />
          )}
        />
        {exportDownloadURL ? (
          <Export onDownload={this._handleExportDownload} downloadURL={exportDownloadURL} />
        ) : (
          <Table
            items={table.items}
            keys={table.keys}
            totalItems={table.totalItems}
            order={table.order}
            onOrderChange={this._handleOrderChange}
          />
        )}
      </Container>
    );
  }
}

Dashboard.contextType = ApplicationLayoutContext;

Dashboard.defaultProps = {
  className: '',
  defaultPeriod: {
    startDate: moment()
      .utc()
      .startOf('day'),
    endDate: moment()
      .utc()
      .startOf('day'),
  },
  dateDisplayFormat: 'YY MMM D',
  exportConfig: undefined,
};

Dashboard.propTypes = {
  className: PropTypes.string,
  dateDisplayFormat: PropTypes.string,
  title: PropTypes.string.isRequired,
  fetchConfig: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  exportConfig: PropTypes.shape({
    requestExportFile: PropTypes.func.isRequired,
    getExportStatus: PropTypes.func.isRequired,
    EXPORT_DOWNLOAD_URL: PropTypes.string.isRequired,
  }),
  defaultPeriod: PropTypes.shape({ startDate: moment.isMoment, endDate: moment.isMoment }),
};

export default Dashboard;
