import { createContext } from 'react';

const TabsContext = createContext({
  snackbar: {
    message: '',
    active: false,
  },
  setSnackbar: () => {},
});

export default TabsContext;
