import defaultTheme from './timeone';

export default {
  themeFallback,
};

export function themeFallback(theme) {
  return isAnObject(theme) && Object.keys(theme).length !== 0 ? theme : defaultTheme;
}

function isAnObject(objToCheck) {
  return !Array.isArray(objToCheck) && objToCheck === Object(objToCheck);
}
