const COLUMN_DEFAULT_PROPERTIES = {
  align: 'left',
  label: ' ',
  dataKey: 'id',
  width: 100,
};
const HEADER_HEIGHT = 50;
const ROW_HEIGHT = 48;
const TABLE_DEFAULT_WIDTH = 480;

export { COLUMN_DEFAULT_PROPERTIES, HEADER_HEIGHT, ROW_HEIGHT, TABLE_DEFAULT_WIDTH };
