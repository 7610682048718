import { lighten } from 'polished';
import colors from '../../colors';

export default ({ primary }) => ({
  fontColor: colors.white,
  backgroundColor: colors.white,
  hover: { backgroundColor: lighten(0.2, primary) },
  active: {
    fontColor: colors.white,
    backgroundColor: primary,
  },
});
