import React from 'react';
import PropTypes from 'prop-types';

import { ContentContainer } from './styles';

export default function Content({ children }) {
  return <ContentContainer className="tol-sidebar__content">{children}</ContentContainer>;
}

Content.defaultProps = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};
Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
