import styled from 'styled-components';

import { utils } from 'tol@themes';

export default styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.1s;
  width: 100%;
  z-index: 5;

  .sidebar-layout__overlay {
    height: 100%;
    width: 100%;
  }

  .sidebar {
    background-color: var(--white);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.25s ease-in-out;
    width: 0;
    z-index: 10;

    > ul {
      margin: 1.5rem 0;
      padding: 0;
    }

    .sidebar__header {
      align-items: center;
      display: flex;
      margin: 1rem 0 1rem 1.5rem;
      white-space: nowrap;

      .sidebar__header__arrow-back_icon {
        width: 1.5rem;
        height: 2rem;
        padding: 0.5rem 0 0;
      }

      .sidebar__header__title {
        letter-spacing: 0.025rem;
        font-size: 1.25rem;
        font-weight: 300;
        padding: 0.5rem 1.5rem 0;
        text-transform: capitalize;
      }
    }
  }

  &.sidebar-layout_visible {
    visibility: visible;

    .sidebar {
      width: 16rem;
    }
  }

  &.sidebar-layout_hidden {
    opacity: 0;
    z-index: -1;
  }

  ${({ customStyle }) => customStyle};
`;
