const primary = '#303747';

export default {
  timeone: {
    primary,
    secondary: '#ffc844',
  },
  programmatic: {
    primary,
    secondary: '#ff510d',
  },
  performance: {
    primary,
    secondary: '#eb0045',
  },
  mobile: {
    primary,
    secondary: '#ffa143',
  },
  publishing: {
    primary,
    secondary: '#9b7e67',
  },
  lmt: {
    primary,
    secondary: '#65b2e8',
  },
  native: {
    primary,
    secondary: '#575195',
  },
  data: {
    primary,
    secondary: '#009eb0',
  },
  primary,
  white: '#fff',
  gray: '#adadad',
  graySecondary: '#f2f2f2',
  lightGray: '#f9f9f9',
  green: '#8CBF55',
  orange: '#EA8E00',
  red: '#EC0B43',
  blue: '#4FC1E9',
  error: '#eb0045',
};
