export const TIMEONE_LOGIN_ERROR_TYPE = 'timeOneLogin';
export class LoginError extends Error {
  constructor(message) {
    super(message);
    this.type = TIMEONE_LOGIN_ERROR_TYPE;
  }
}

const defaultErrorOptions = {
  type: 'default',
  message: 'An unexpected error occured. Try again.',
};

export class CustomError extends Error {
  constructor({ message = defaultErrorOptions.message, type = defaultErrorOptions.type, violations }) {
    super(message);
    this.type = type;
    this.violations = violations;
  }
}

export default { LoginError, CustomError };
