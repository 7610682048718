import React from 'react';
import PropTypes from 'prop-types';
import ReactLoader from 'react-loader-spinner';
import { withTheme } from 'styled-components';

import defaultTheme from 'tol@themes';
import { LoaderContainer } from './styles';

export const Loader = ({ color, height, width, theme, className, type, children }) => (
  <LoaderContainer>
    <ReactLoader className={className} type={type} color={color || theme.secondary} height={height} width={width} />
    <div className={`${className} loader-content`}>{children}</div>
  </LoaderContainer>
);

Loader.defaultProps = {
  className: '',
  color: null,
  height: 100,
  theme: defaultTheme,
  type: 'Oval',
  width: 100,
  children: '',
};

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  theme: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  type: PropTypes.oneOf([
    'RevolvingDot',
    'Ball-Triangle',
    'Bars',
    'Circles',
    'Oval',
    'Puff',
    'Rings',
    'TailSpin',
    'ThreeDots',
  ]),
  width: PropTypes.number,
};

export default withTheme(Loader);
