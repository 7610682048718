import formats from '../format';
import FORMATS_PROPERTIES from './constants';

const { currency: defaultCurrency, locale: defaultLocale } = FORMATS_PROPERTIES.default;

const formatTableRows = ({ columns, data } = {}, { currency = defaultCurrency, locale = defaultLocale } = {}) =>
  columns.reduce(
    (rows, { slug, format: { name, set } = {} }) =>
      rows.map(row => {
        const valueFormatted =
          row[slug] && formats[name]
            ? formats[name]({
                value: row[slug],
                currency,
                locale,
                set,
              })
            : row[slug];

        return { ...row, [slug]: valueFormatted };
      }),
    data
  );

export default formatTableRows;
