import styled from 'styled-components';

import { utils } from 'tol@themes';

export default styled.button.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  border: 0.06rem solid ${({ theme }) => theme.input.datepicker.preset.borderColor};
  font-size: 0.75rem;
  margin: 0.25rem 0;
  overflow: hidden;
  padding: 0.5rem;
  text-align: left;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.input.datepicker.preset.hover.backgroundColor};
    border: 0.06rem solid transparent;
    box-shadow: ${({ theme }) => theme.shadows.dp1};
    color: ${({ theme }) => theme.input.datepicker.preset.hover.fontColor};
  }

  &:active,
  &.active-enter,
  &.active-enter-done {
    color: ${({ theme }) => theme.input.datepicker.preset.active.fontColor};
    background-color: ${({ theme }) => theme.input.datepicker.preset.active.backgroundColor};
  }
`;
