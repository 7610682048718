import PaginationIterator from './PaginationIterator';

export default class GraphQLPaginationIterator extends PaginationIterator {
  constructor({ numberToDisplayPerPage, limit, offset }, fetchDataSrv) {
    super({ numberToDisplayPerPage, limit, offset }, fetchDataSrv);

    this.after = undefined;
    this.cursorHistory = new Map();
    this.edges = [];
    this.endCursor = undefined;
    this.first = limit;
    this.hasNextPage = false;
    this.hasPreviousPage = false;
    this.startCursor = undefined;
  }

  static init = ({ numberToDisplayPerPage, limit, offset }, fetchData) =>
    (async () => {
      const instance = new GraphQLPaginationIterator({ numberToDisplayPerPage, limit, offset }, fetchData);

      await instance.build();

      return instance;
    })();

  build = async () => {
    try {
      const { edges, pageInfo } = await this.fetchDataSrv({ first: this.first, after: this.after });
      const { endCursor, hasNextPage } = pageInfo;

      this.setIterableData([...edges], this.numberToDisplayPerPage);
      this.after = [...edges].pop().cursor;
      this.startCursor = edges[0].cursor;
      this.endCursor = endCursor;
      this.hasNextPage = hasNextPage;
      this.edges = edges;
      this.hasPreviousPage = !this.isFirstChunck();
      this.setCursorHistory([...edges].pop().cursor);
    } catch (e) {
      throw new Error('An unexpected network error occured.');
    }
  };

  isFirstChunck = () => this.index === 0 && this.edges.some(({ cursor }) => cursor === this.startCursor);

  isLatestChunck = () => this.index === this.iterableData.length - 1 && !this.hasNextPage;

  shouldFetchNextData = async () => {
    try {
      const { edges, pageInfo } = await this.fetchDataSrv({ first: this.first, after: this.after });
      const { hasNextPage } = pageInfo;

      this.index = 0;
      this.increaseOffset();
      this.setIterableData([...edges], this.numberToDisplayPerPage);
      this.after = [...edges].pop().cursor;

      this.setCursorHistory([...this.edges].pop().cursor, edges);
      this.hasNextPage = hasNextPage;
      this.edges = edges;
      this.hasPreviousPage = !this.isFirstChunck();

      return {
        value: this.iterableData[this.index],
        hasPreviousPage: !this.isFirstChunck(),
        hasNextPage: !this.isLatestChunck(),
      };
    } catch (e) {
      throw new Error('An unexpected network error occured.');
    }
  };

  setCursorHistory = cursor => {
    const key = this.first + this.offset;

    return this.cursorHistory.set(key) && this.cursorHistory.set(key, cursor);
  };

  getCursorFromHistory = () => {
    if (this.offset === 0) {
      return null;
    }

    return this.cursorHistory.get(this.offset);
  };

  cleanCursorHistory = () => this.cursorHistory.clear();

  shouldFetchPreviousData = async () => {
    this.decreaseOffset();
    const after = this.getCursorFromHistory();

    if (!after) {
      this.cleanCursorHistory();
    }
    try {
      const { edges, pageInfo } = await this.fetchDataSrv({ first: this.first, after });
      const { hasNextPage } = pageInfo;

      this.setCursorHistory([...edges].pop().cursor);
      this.setIterableData([...edges], this.numberToDisplayPerPage);
      this.index = this.iterableData.length - 1;
      this.after = [...edges].pop().cursor;
      this.hasNextPage = hasNextPage;
      this.edges = edges;
      this.hasPreviousPage = !this.isFirstChunck();

      return {
        value: this.iterableData[this.index],
        hasPreviousPage: !this.isFirstChunck(),
        hasNextPage: !this.isLatestChunck(),
      };
    } catch (e) {
      throw new Error('An unexpected network error occured.');
    }
  };
}
