import React from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/initialize';

import icons from 'tol@assets/icon';
import DefaultInput from '../DefaultInput';
import {
  DatePickerContainer,
  DayContainer,
  MonthYearContainer,
  DateContainer,
  DateDay,
  HeaderColumnDateContainer,
} from './styles';
import { isRequiredDatePicker } from '../utils';

import 'react-dates/lib/css/_datepicker.css';

const { CalendarIcon } = icons;

export default class InputDatePicker extends DefaultInput {
  handleChange = defaultOnChange => selectedOption => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(selectedOption);
    }

    this.setState({ value: selectedOption });

    return defaultOnChange({ target: { value: selectedOption } });
  };

  handleFocus = (defaultOnFocus, defaultOnBlur) => ({ focused }) => {
    const { onFocus, onBlur } = this.props;

    if (focused) {
      if (onFocus) {
        onFocus({ target: { value: focused } });
      }

      return defaultOnFocus && defaultOnFocus({ target: { value: focused } });
    }

    if (onBlur) {
      onBlur(focused);
    }

    return defaultOnBlur && defaultOnBlur({ target: { value: focused } });
  };

  handleValidate = value => {
    const { validation, required } = this.props;

    if (required && isRequiredDatePicker(value)) {
      return isRequiredDatePicker(value);
    }

    return validation ? validation(value) : undefined;
  };

  handleIsActive = ({ value }, { active }) => active || Object.keys(value).length > 0;

  renderCalendarHeader = value => () => {
    const { headerInitialTitle } = this.props;
    const date = moment(value);

    return (
      <HeaderColumnDateContainer className="header__header-column__date date">
        <DateDay className="date__day-number">{date.isValid() && moment(date).format('DD')}</DateDay>
        <DateContainer className="date__text-container">
          <MonthYearContainer className="date__text-container__month-year">
            {date.isValid() ? moment(date).format('MMMM YYYY') : headerInitialTitle}
          </MonthYearContainer>
          <DayContainer className="date__text-container__day">
            {date.isValid() && moment(date).format('dddd')}
          </DayContainer>
        </DateContainer>
      </HeaderColumnDateContainer>
    );
  };

  setConfig = ({ value }, config) => ({
    customInputIcon: <CalendarIcon />,
    daySize: 34,
    displayFormat: 'DD / MM / YYYY',
    hideKeyboardShortcutsPanel: true,
    noBorder: true,
    numberOfMonths: 1,
    readOnly: true,
    firstDayOfWeek: 1,
    renderCalendarInfo: this.renderCalendarHeader(value),
    reopenPickerOnClearDate: false,
    showClearDate: true,
    block: true,
    isDayBlocked: () => false,
    isOutsideRange: () => false,

    ...config,
  });

  inputRender = (inputProps, meta) => {
    const { name, config } = this.props;
    const { onChange, onFocus, onBlur } = inputProps;

    const configGenerated = this.setConfig(inputProps, config);

    return (
      <DatePickerContainer
        id={name}
        name={name}
        className={`tol-input__datepicker ${this.handleIsActive(inputProps, meta) ? 'active' : null}`}
      >
        <SingleDatePicker
          id={`singleDatePicker-${name}`}
          placeholder=""
          date={inputProps.value ? moment(inputProps.value) : null}
          onDateChange={this.handleChange(onChange)}
          focused={meta.active}
          onFocusChange={this.handleFocus(onFocus, onBlur)}
          {...configGenerated}
        />
      </DatePickerContainer>
    );
  };
}

InputDatePicker.defaultProps = {
  headerInitialTitle: 'Pick a date',
  config: {},
};

InputDatePicker.propTypes = {
  config: PropTypes.shape({}),
  headerInitialTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
