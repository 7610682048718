import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { utils } from 'tol@themes';

import buttonStyle from './styles';

const Button = styled(({ primary, fill, type, ...props }) => <button type="button" {...props} {...{ type }} />).attrs(
  ({ theme }) => ({
    theme: utils.themeFallback(theme),
  })
)`
  ${props => buttonStyle(props)};
`;

Button.defaultProps = {
  appearance: 'default',
  children: null,
  className: '',
  disabled: false,
  fill: false,
  onClick: null,
  primary: false,
  type: 'button',
};

Button.propTypes = {
  appearance: PropTypes.oneOf(['default', 'rounded', 'action', 'cancel']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  validation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.oneOf(['button', 'submit']),
};

export default Button;
