import styled from 'styled-components';
import { Content as DefaultContent } from '../styles';

export const Content = styled(DefaultContent)`
  margin-bottom: 0.875rem;
`;

export const PlatformTitle = styled.div`
  align-items: center;
  display: flex;
  padding: 0.75rem;
  text-transform: uppercase;

  svg {
    height: 2.5rem;
    margin-right: 0.5rem;
    width: 2.5rem;
  }
`;

export const ResourceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ResourceTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResourceTypeTitle = styled.div`
  font-weight: 600;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export default {
  PlatformTitle,
  ResourceTypeContainer,
  ResourceTypeTitle,
  ResourceContainer,
};
