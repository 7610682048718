import InputAutocomplete from './InputAutocomplete';
import InputCheckBox from './InputCheckBox';
import InputButtonRadio from './InputButtonRadio';
import InputDatePicker from './InputDatePicker';
import InputDateRangePicker from './InputDateRangePicker';
import InputSelect from './InputSelect';
import InputSelectCreatable from './InputSelectCreatable';
import InputText from './InputText';
import InputTextarea from './InputTextarea';

export default {
  InputAutocomplete,
  InputCheckBox,
  InputButtonRadio,
  InputDatePicker,
  InputDateRangePicker,
  InputSelect,
  InputSelectCreatable,
  InputText,
  InputTextarea,
};
