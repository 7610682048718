import defaultTheme from './theme';

function isAnObject(objToCheck) {
  return !Array.isArray(objToCheck) && objToCheck === Object(objToCheck);
}

export function themeFallback(theme) {
  return isAnObject(theme) && Object.keys(theme).length !== 0 ? { ...defaultTheme, ...theme } : defaultTheme;
}

export default {
  themeFallback,
};
