import React from 'react';
import PropTypes from 'prop-types';

import icons from 'tol@assets/icon';
import LabelContainer from './styles';

const { CloseIcon } = icons;

const ErrorLabel = ({ className, label }) => (
  <LabelContainer className={className}>
    <div>
      <span>
        Error &ldquo;
        <strong>{label}</strong>
        &rdquo; refused
      </span>
      <CloseIcon />
    </div>
    Error
  </LabelContainer>
);

export default ErrorLabel;

ErrorLabel.defaultProps = {
  className: '',
  label: '',
};

ErrorLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};
