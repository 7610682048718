// USEFULL TO DEV ON PAGINATION WITH CURSOR AND GRAPHQL
// export default {
//   itemPerPage: 1,
//   maxPageToShow: 2,
//   limit: 5,
// };

export default {
  itemPerPage: 50,
  maxPageToShow: 5,
  limit: 250,
};
