import styled from 'styled-components';
import { utils } from 'tol@themes';

const WizardHeaderStyled = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
`;

const WizardHeaderWrapperStyled = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  margin-top: auto;
  width: 100%;
`;

const WizardHeaderStepStyled = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  color: ${({ theme }) => theme.form.header.lightenFontColor};
  height: 100%;
  overflow: hidden;
  width: 100%;

  .tol-wizard-header-step-number {
    align-items: center;
    display: flex;
    font-weight: 500;
    text-transform: capitalize;
    justify-content: center;

    span {
      font-size: 1.5rem;
      font-weight: 700;
      padding-right: 10px;
    }
  }

  &.active-in,
  &.actived {
    color: ${({ theme }) => theme.form.header.borderColor};
  }

  &.active-in {
    animation-duration: 1.5s;
    animation-name: fontfadein;
  }

  &.active-out {
    animation-duration: 1.5s;
    animation-name: fontfadeout;
  }

  .progressbar {
    background-color: ${({ theme }) => theme.form.header.lightBorderColor};
    margin-top: 10px;

    .progress {
      height: 10px;
      width: 100%;

      &.active-in {
        animation-duration: 1.5s;
        animation-name: slidein;
      }

      &.active-out {
        animation-duration: 1.5s;
        animation-name: slideout;
      }

      &.active-in,
      &.actived {
        background-color: ${({ theme }) => theme.form.header.borderColor};
      }

      @keyframes slidein {
        from {
          background-color: ${({ theme }) => theme.form.header.borderColor};
          width: 0%;
        }

        to {
          background-color: ${({ theme }) => theme.form.header.borderColor};
          width: 100%;
        }
      }

      @keyframes slideout {
        from {
          background-color: ${({ theme }) => theme.form.header.borderColor};
          width: 100%;
        }

        to {
          background-color: ${({ theme }) => theme.form.header.borderColor};
          width: 0%;
        }
      }

      @keyframes fontfadein {
        from {
          color: ${({ theme }) => theme.form.header.lightenFontColor};
        }

        to {
          color: ${({ theme }) => theme.form.header.fontColor};
        }
      }

      @keyframes fontfadeout {
        from {
          color: ${({ theme }) => theme.form.header.fontColor};
        }

        to {
          color: ${({ theme }) => theme.form.header.lightenFontColor};
        }
      }
    }
  }
`;

export { WizardHeaderStyled as default, WizardHeaderWrapperStyled, WizardHeaderStepStyled };
