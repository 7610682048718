import styled, { css } from 'styled-components';

import { utils } from 'tol@themes';

export default styled.header.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-items: center;
  background-color: ${({ theme }) => theme.header.backgroundColor};
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  height: 4.5rem;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => headerLeft(theme.header)}
  ${headerRight()}
  ${({ customStyle }) => customStyle};
`;

function headerLeft({ logo }) {
  return css`
    .header__header-left {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: 0 2rem;

      button.header__header-left__button-toggle-sidebar {
        margin: 0 2rem 0 0;

        svg.header__header-left__button-toggle-sidebar__icon {
          height: 1.25rem;
          width: 1.25rem;
        }
      }

      .header__header-left__tool-logo {
        height: 2.5rem;
        width: 2.5rem;
        margin: 0 2rem 0 0;

        &.header__header-left__tool-logo--undefined {
          background-color: ${logo.backgroundColor};
          border-radius: 50%;
          opacity: 0.25;
        }
      }

      .header__header-left__main-title {
        display: flex;
        font-size: 1.25rem;
        font-weight: lighter;
        text-transform: capitalize;

        .header__header-left__main-title__title-suffix {
          font-weight: normal;
          margin: 0 0.3rem;
        }
      }
    }
  `;
}

function headerRight() {
  return css`
    .header__header-right {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
    }
  `;
}
