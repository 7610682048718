import React from 'react';

import UserInformationsLink from '../users/UserInformationsLink';

function TimeOneConfig() {
  return (
    <>
      <UserInformationsLink />
    </>
  );
}

export default TimeOneConfig;
