import { map } from 'lodash';

import { logger } from '../utils';

const requiredUrls = {
  minimal: ['authenticationUrl', 'oauthTokenUrl', 'cookieDomain'],
  userInformations: ['graphqlUserUrl'],
};
const modulesWithRequirement = Object.keys(requiredUrls);

function initConfigStore() {
  let isConfigSet = false;

  let config = {
    modules: {
      userInformations: true,
    },
  };

  function getConfig() {
    if (isConfigSet) {
      return config;
    }

    logger.error(
      'Using the TimeOne framework require some configuration. Please refer to the documentation: %chttps://gitlab.timeonegroup.com/timeone/lib/react/timeone-components/blob/develop/src/framework/README.md ',
      'color:#65b1d9'
    );

    throw new Error('Using the TimeOne framework require some configuration. Please refer to the documentation');
  }

  function setConfig({ modules = {}, urls = {} } = {}) {
    if (isConfigSet) {
      throw new Error('Framework configuration: can be set only one time.');
    }

    isConfigSet = true;
    config = checkRequirement({ modules: { ...config.modules, ...modules }, urls });
  }

  function checkRequirement({ modules, urls }) {
    checkMinimalRequirement(urls);

    const activeModules = map(modules, (isActive, moduleName) =>
      isActive && checkActiveModuleRequirement(moduleName, urls) ? { [moduleName]: isActive } : false
    )
      .filter(Boolean)
      .reduce((validateModules, module) => ({ ...validateModules, ...module }), {});

    return { modules: activeModules, urls };
  }

  function checkMinimalRequirement(urls) {
    if (
      requiredUrls.minimal
        .map(requiredUrl => Object.prototype.hasOwnProperty.call(urls, requiredUrl))
        .every(isDefined => isDefined === true)
    ) {
      return true;
    }

    isConfigSet = false;
    logger.error(
      `The minimum required is missing. Be sure that the following urls are set: ${requiredUrls.minimal.join(
        ', '
      )}. Please refer to the documentation: %chttps://gitlab.timeonegroup.com/timeone/lib/react/timeone-components/blob/develop/src/framework/README.md `,
      'color:#65b1d9'
    );
    throw new Error(
      `Framework configuration: The minimum required is missing. Be sure that the following urls are set: ${requiredUrls.minimal.join(
        ', '
      )}`
    );
  }

  function checkActiveModuleRequirement(moduleName, urls) {
    if (!modulesWithRequirement.includes(moduleName)) {
      return true;
    }

    if (
      requiredUrls[moduleName]
        .map(requiredUrl => Object.prototype.hasOwnProperty.call(urls, requiredUrl))
        .every(isDefined => isDefined === true)
    ) {
      return true;
    }
    logger.warn(
      `Framework configuration: The minimum required for the module ${moduleName} is missing. It has been desactivated. Be sure that the following urls are set: ${requiredUrls[
        moduleName
      ].join(', ')}`
    );

    return false;
  }

  return { getConfig, setConfig };
}

export const { getConfig, setConfig: setFrameworkConfig } = initConfigStore();
export default initConfigStore;
