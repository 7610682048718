import styled from 'styled-components';
import { transparentize } from 'polished';
import { utils } from 'tol@themes';

export default styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  position: relative;
  height: 100%;

  .backdrop {
    background-color: ${transparentize(0.5, '#000')};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .tolPagination {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .tolPagination__block {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 15px;

    .tolPagination__icon {
      height: 15px;
      margin: 0 2.5px;
      width: 15px;

      svg {
        height: 15px;
        width: 15px;
      }

      &:disabled {
        visibility: hidden;
      }
    }

    .tolPagination__button {
      margin: 0 2.5px;

      &.active {
        background-color: ${({ theme }) => theme.pagination.button.active.backgroundColor};
        color: ${({ theme }) => theme.pagination.button.active.fontColor};
        font-weight: 700;
        min-height: 1.1rem;
        min-width: 1.1rem;
      }
    }
  }
`;
