import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import arrowIcon from 'tol@assets/icon/arrow.svg';
import SidebarItemStyled from './styles';

export default class SidebarItem extends PureComponent {
  handleClick = event => {
    const { index, itemOnClick } = this.props;

    return itemOnClick(event, index);
  };

  _createClassName() {
    const { name, className } = this.props;
    const classNameProps = className ? ` ${className}` : '';

    return `sidebar__list__item sidebar-item sidebar-item--${name}${classNameProps}`;
  }

  render() {
    const { logo, title, subEntries, url } = this.props;
    const className = this._createClassName();

    if (subEntries) {
      return (
        <SidebarItemStyled className={className}>
          {logo}
          <span className="sidebar-item__name">{title}</span>
          <div className="sidebar-item__sub-menu-list__container">
            <img className="sidebar-item__sub-menu-list__arrow" src={arrowIcon} alt="icon subproduct" />
            <ul className="sidebar-item__sub-menu-list">
              {subEntries.map((entryName, index) => (
                // eslint-disable-next-line
                <li className="sidebar-item__sub-menu-list__list-item" key={`product${index}-${entryName}`}>
                  <button type="button" onClick={this.handleClick}>
                    {entryName}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </SidebarItemStyled>
      );
    }

    return (
      <SidebarItemStyled className={className}>
        <a className="sidebar-item__link-overlay" href={url} onClick={this.handleClick}>
          {logo}
          <span className="sidebar-item__name">{title}</span>
        </a>
      </SidebarItemStyled>
    );
  }
}

SidebarItem.defaultProps = {
  name: '',
  logo: null,
  title: '',
  className: '',
  subEntries: null,
  url: null,
  index: null,
  itemOnClick: null,
};

SidebarItem.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.element,
  title: PropTypes.string,
  subEntries: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  url: PropTypes.string,
  index: PropTypes.number,
  itemOnClick: PropTypes.func,
};
