import React from 'react';
import { Route } from 'react-router-dom';

import { getConfig } from 'tol@framework/TimeOneConfig/configStore';
import withBreadcrumb from 'tol@framework/Breadcrumb/withBreadcrumb';
import UserInformationsPage from 'tol@framework/users/UserInformationsPage';

import { routePath } from './constants';

function TimeOneRoutes() {
  const {
    modules: { userInformations },
  } = getConfig();

  return (
    <>
      {userInformations && (
        <Route
          exact
          path={routePath.userInformations}
          component={withBreadcrumb(UserInformationsPage, { name: 'Informations', to: routePath.userInformations })}
        />
      )}
    </>
  );
}

export default TimeOneRoutes;
