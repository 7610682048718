const LETTERS = 'abcdefghijklmnopqrstuvwxyz';

function randomStringId() {
  /**
   * Generate a random word, compatible with dom.
   * @returns {String} A random string.
   */
  return ([1e7] + -1e3 + -1e3).replace(/[01]/g, () => LETTERS.charAt(Math.floor(Math.random() * LETTERS.length)));
}

export default randomStringId;
