import token from './token';
import throwIfMissing from './throwIfMissing';

const apolloBoostDefaultConfig = (authentificationUrl = throwIfMissing('authentificationUrl')) => ({
  request: operation => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token.get()}`,
      },
    });
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.error('graphQLErrors', graphQLErrors);
      const notLogged = graphQLErrors.some(
        ({ extensions: { code = null, message = {} } = {} }) => code === 'UNAUTHENTICATED' || message.status === 401
      );

      if (notLogged) {
        token.redirectToSSO(authentificationUrl);
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  },
});

export default apolloBoostDefaultConfig;
