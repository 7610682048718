import blocks from './blocks';
import elements from './elements';
import hoc from './hoc';
import modifiers from './modifiers';
import utils from './utils';

export default {
  blocks,
  ...blocks,
  elements,
  ...elements,
  hoc,
  ...hoc,
  ...modifiers,
  ...utils,
};
