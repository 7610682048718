import formats from './format';

const {
  text: formatText,
  int: formatInt,
  float: formatFloat,
  currency: formatCurrency,
  percentage: formatPercentage,
  date: formatDate,
} = formats;

export { formats as default, formatText, formatInt, formatFloat, formatCurrency, formatPercentage, formatDate };
