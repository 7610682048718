import styled, { css } from 'styled-components';

import { themeFallback } from '../utils';

export const ProgressBarContainer = styled.div.attrs(({ theme }) => ({
  theme: themeFallback(theme),
}))`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.progressBar.containerPadding};
`;

export const Progress = styled.div.attrs(({ theme }) => ({
  theme: themeFallback(theme),
}))(
  ({ theme }) => css`
    height: ${theme.progressBar.height};
    border-radius: ${theme.progressBar.borderRadius};
    background-color: ${theme.progressBar.backgroundColor};
    margin-bottom: ${theme.progressBar.marginBottom};
  `
);

export const Filler = styled.div.attrs(({ theme }) => ({
  theme: themeFallback(theme),
}))`
  background-color: ${({ theme }) => theme.progressBar.fillerBackgroundColor};
  height: 100%;
  border-radius: inherit;
  width: ${({ value, max }) => (value < max ? (value * 100) / max : 100)}%;
  transition: width 1s ease-in;
`;
