import React from 'react';
import PropTypes from 'prop-types';

import { FooterContainer } from './styles';

export default function Footer({ children }) {
  return <FooterContainer className="tol-sidebar__footer">{children}</FooterContainer>;
}

Footer.defaultProps = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};
Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};
