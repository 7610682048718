import styled from 'styled-components';

import { utils } from 'tol@themes';

const Label = styled.label.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  margin-bottom: 1rem;
  position: relative;
  user-select: none;

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  &:hover {
    .input-check-mark {
      background-color: ${({ theme }) => theme.input.checkbox.hover.backgroundColor};
      box-shadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px -1px rgba(0, 0, 0, 0.056), 0 1px 1px 0 rgba(0, 0, 0, 0.14)';
    }
  }
`;

const LabelError = styled.span.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  color: ${({ theme }) => theme.input.error.fontColor};
`;

const InputCheckmark = styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  background-color: ${({ theme }) => theme.input.checkbox.backgroundColor};
  border: solid ${({ theme }) => theme.input.checkbox.active.backgroundColor} 2px;
  border-radius: 50%;
  box-shadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)';
  height: 1rem;
  margin-right: 0.5rem;
  position: relative;
  width: 1rem;

  /* &:after {
    border: solid 2px ${({ theme }) => theme.input.checkbox.active.fontColor};
    border-radius: 50%;
    content: ' ';
    display: none;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
  } */

  &.active {
    background-color: ${({ theme }) => theme.input.checkbox.active.backgroundColor};

    &:after {
      display: block;
    }
  }
`;

export { Label as default, Label, LabelError, InputCheckmark };
