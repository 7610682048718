import React, { Component } from 'react';
import PropTypes from 'prop-types';
import arrowBackIcon from 'tol@assets/icon/arrow_back.svg';
import SidebarItem from './Components/SidebarItem';
import SidebarNavStyled from './styles';

export default class SidebarNav extends Component {
  _createClassName() {
    const { className, isSidebarOpen } = this.props;
    const classNameProps = ` ${className}`;
    const classModifierVisible = isSidebarOpen ? ' sidebar-layout_visible' : ' sidebar-layout_hidden';

    return `sidebar-layout${classNameProps}${classModifierVisible}`;
  }

  render() {
    const { title, children, entries, toggleSidebar, itemOnClick } = this.props;
    const className = this._createClassName();

    return (
      <SidebarNavStyled className={className}>
        <div
          className="sidebar-layout__overlay"
          onClick={toggleSidebar}
          onKeyPress={toggleSidebar}
          role="button"
          tabIndex={-1}
        />
        <div className="sidebar-layout__sidebar sidebar">
          <button type="button" className="sidebar__header sidebar__header__arrow-back_button" onClick={toggleSidebar}>
            <img className="sidebar__header__arrow-back_icon" src={arrowBackIcon} alt="icon arrow_back" />
            <p className="sidebar__header__title">{title}</p>
          </button>
          <ul className="sidebar__list">
            {entries.map((entry, index) => (
              // eslint-disable-next-line
              <SidebarItem {...entry} key={index} index={index} itemOnClick={itemOnClick} />
            ))}
          </ul>
          {children}
        </div>
      </SidebarNavStyled>
    );
  }
}

SidebarNav.defaultProps = {
  title: 'Close Sidebar',
  isSidebarOpen: false,
  entries: [
    {
      name: '',
      logoUrl: '',
      title: '',
      colorName: '',
      url: '/entries',
    },
  ],
  itemOnClick: () => {},
  className: '',
  toggleSidebar: () => null,
  children: null,
};

SidebarNav.propTypes = {
  title: PropTypes.string,
  isSidebarOpen: PropTypes.bool,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logoUrl: PropTypes.string,
      title: PropTypes.string,
      colorName: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  toggleSidebar: PropTypes.func,
  itemOnClick: PropTypes.func,
  children: PropTypes.element,
};
