import styled from 'styled-components';

import SidebarContent from './SidebarContent';

const defaultProps = {
  width: '25%',
  position: 'right',
};

export default styled(SidebarContent).attrs(({ width, position }) => ({
  width: width || defaultProps.width,
  sidebarPosition: position || defaultProps.position,
}))`
  &.sidebar-content-layout {
    height: 100%;
    max-height: inherit;
    overflow: scroll;
    position: absolute;
    top: 0;
    width: 0;

    &.sidebar-content--no-backdrop {
      background-color: #fff;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
      ${({ sidebarPosition }) => (sidebarPosition.includes('left') ? 'left: 0' : 'right: 0')};
      transition: all 0.25s ease;

      &.sidebar-content--open {
        width: ${({ width }) => width};
      }
    }

    &.sidebar-content--with-backdrop {
      visibility: hidden;

      .sidebar-content-layout__backdrop {
        background-color: rgba(0, 0, 0, 0.25);
        bottom: 0;
        left: 0;
        max-height: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.5s;
      }

      .sidebar-content {
        background-color: #fff;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
        height: 100%;
        max-height: inherit;
        overflow: scroll;
        position: absolute;
        ${({ sidebarPosition }) => (sidebarPosition.includes('left') ? 'left: 0' : 'right: 0')};
        top: 0;
        transition: all 0.25s ease;
        width: 0;
      }

      &.sidebar-content--open {
        visibility: visible;
        width: 100%;

        .sidebar-content-layout__backdrop {
          opacity: 1;
        }

        .sidebar-content {
          width: ${({ width }) => width};
        }
      }
    }
  }

  ${({ customStyle }) => customStyle};
`;
