import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatPercentage, formatFloat } from 'tol@utils/format';

const MetricLongLayout = styled.div`
  background: #fafafa;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  height: 3rem;
  justify-content: space-between;
  margin: 0.5rem;
  padding: 1rem;
  position: relative;
  transition: all 0.3s;
  width: 16em;

  .metric-long__header {
    display: flex;
    justify-content: space-between;

    .metric-long__header__title {
      text-transform: capitalize;
    }

    .metric-long__header__evolution {
      opacity: 0.75;
      margin-right: -0.25rem;
    }
  }

  .metric-long__value {
    text-align: right;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ color }) => color};
  }

  ${({ customStyle }) => customStyle};
`;

class MetricLong extends React.PureComponent {
  render() {
    const { name, value, color, evolution, formatValue, formatEvolution, className, customStyle } = this.props;

    return (
      <MetricLongLayout className={`metric-long ${className}`} color={color} customStyle={customStyle}>
        <div className="metric-long__header">
          <span className="metric-long__header__title">{name}</span>
          {evolution && (
            <span className="metric-long__header__evolution">{evolution && formatEvolution({ value: evolution })}</span>
          )}
        </div>
        <span className="metric-long__value">{value && formatValue({ value })}</span>
      </MetricLongLayout>
    );
  }
}

MetricLong.defaultProps = {
  name: '',
  value: null,
  evolution: null,
  formatEvolution: formatPercentage,
  formatValue: formatFloat,
  className: '',
  color: null,
  customStyle: null,
};

MetricLong.propTypes = {
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.number,
  evolution: PropTypes.number,
  formatEvolution: PropTypes.func,
  formatValue: PropTypes.func,
  className: PropTypes.string,
  // style formatted for styled components inside ``
  customStyle: PropTypes.string,
  color: PropTypes.string,
};

export default MetricLong;
