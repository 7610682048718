import Dashboard from './Dashboard';
import Footer from './Footer';
import Form from './Form';
import Header from './Header';
import HeaderNavbar from './HeaderNavbar';
import Input from './Input';
import { Metric, MetricEvolution, MetricLong } from './Metrics';
import Pagination from './Pagination';
import SidebarNav from './SidebarNav';
import OldSidebarContent from './SidebarContent';
import Table from './Table';
import TableSelectable from './TableSelectable';
import Tabs from './Tabs';
import Wizard from './Wizard';

export default {
  Footer,
  Form,
  Header,
  HeaderNavbar,
  Input,
  Metric,
  MetricEvolution,
  MetricLong,
  Pagination,
  ...Dashboard,
  SidebarNav,
  OldSidebarContent,
  Table,
  TableSelectable,
  Tabs,
  Wizard,
};
