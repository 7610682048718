import styled from 'styled-components';

import { utils } from 'tol@themes';

export default styled.li.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  align-items: center;
  cursor: pointer;
  display: flex;
  list-style: none;
  max-width: 100%;
  min-height: 100%;
  padding: 0.75rem 0 0.75rem 1.5rem;
  position: relative;
  text-align: left;
  transition: all 0.25s ease;

  &:hover {
    background-color: ${({ theme }) => theme.sidebarNav.hover.backgroundColor};
  }

  .sidebar-item__link-overlay {
    align-items: center;
    display: flex;
  }

  svg {
    height: 2rem;
    width: 2rem;
  }

  .sidebar-item__name {
    color: var(--dark-gray);
    font-size: 0.6rem;
    font-weight: 600;
    margin: 0 1.5rem;
    max-width: 8rem;
    text-transform: uppercase;
  }

  .sidebar-item__sub-menu-list__container {
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    right: 2rem;
    top: auto;

    .sidebar-item__sub-menu-list__arrow {
      height: 1rem;
      width: 1rem;
    }

    .sidebar-item__sub-menu-list {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
      opacity: 0;
      position: absolute;
      right: -10rem;
      top: -0.5rem;
      transition: opacity 0.1s ease, right 0.1s ease;
      width: 10rem;
      z-index: -1;

      li.sidebar-item__sub-menu-list__list-item {
        background-color: var(--white);
        border-bottom: 2px solid var(--white);
        position: relative;
        transition: all 0.1s ease-in-out;

        button {
          align-items: center;
          color: var(--gray);
          display: flex;
          font-size: 0.6rem;
          font-weight: 600;
          padding: 1rem;
          text-transform: uppercase;
        }

        &:hover {
          background-color: ${({ theme }) => theme.sidebarNav.hover.backgroundColor};
        }
      }
    }
  }

  &:hover .sidebar-item__sub-menu-list {
    opacity: 1;
    right: -11rem;
    z-index: 15;
  }

  ${({ customStyle }) => customStyle};
`;
