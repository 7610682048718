import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { withTheme } from 'styled-components';

import token from 'tol@utils/token';
import { TIMEONE_LOGIN_ERROR_TYPE, LoginError } from 'tol@utils/errors';
import LoaderContainer from './styles';

class IsLogged extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    const { cookieDomain, authentificationUrl } = this.props;

    try {
      const accessToken = token.get();

      await this.validateToken(accessToken);
      localStorage.removeItem('authentificationErrorRetry');

      return this.setState({ logged: true, loading: false });
    } catch (error) {
      if (error.type === TIMEONE_LOGIN_ERROR_TYPE) {
        token.clear(cookieDomain);
        token.redirectToSSO(authentificationUrl);

        return this.setState({ logged: false, loading: false });
      }

      throw error;
    }
  };

  validateToken = async accessToken => {
    const { oauthTokenUrl, bearer } = this.props;

    let url = `${oauthTokenUrl}/token-verification/${accessToken}`;
    const options = {};

    if (bearer && bearer !== false) {
      url = oauthTokenUrl;
      options.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    try {
      const fetchResponse = await fetch(url, options);

      if (!fetchResponse.ok) {
        throw new LoginError('Token invalid');
      }

      const response = await fetchResponse.json();

      if (response.errors) {
        throw new LoginError('Token invalid');
      }

      return response;
    } catch (error) {
      throw new LoginError('Token invalid');
    }
  };

  onLogout = () => {
    const { cookieDomain, authentificationUrl } = this.props;

    token.clear(cookieDomain);

    return token.redirectToSSO(authentificationUrl);
  };

  render() {
    const { logged, loading } = this.state;
    const { RenderComponent, theme } = this.props;

    return (
      <>
        {(!logged || loading) && (
          <LoaderContainer>
            <Loader type="Oval" color={theme.secondary} height="100" width="100" />
          </LoaderContainer>
        )}
        {logged && !loading && <RenderComponent />}
      </>
    );
  }
}

export default withTheme(IsLogged);

IsLogged.defaultProps = {
  theme: {},
  bearer: false,
};

IsLogged.propTypes = {
  authentificationUrl: PropTypes.string.isRequired,
  oauthTokenUrl: PropTypes.string.isRequired,
  cookieDomain: PropTypes.string.isRequired,
  RenderComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]).isRequired,
  theme: PropTypes.shape({}),
  bearer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
