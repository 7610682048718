import React from 'react';
import DefaultIcon from './DefaultIcon';

export default props => (
  <DefaultIcon
    {...props}
    viewBox="0 0 129 129"
    d="M34.6 67.4c-1.6-1.6-1.6-4.2 0-5.8L88.5 7.7c1.6-1.6 4.2-1.6 5.8 0s1.6 4.2 0 5.8l-51 51 51 51c1.6 1.6 1.6 4.2 0 5.8-.8.8-1.8 1.2-2.9 1.2-1.1 0-2.1-.4-2.9-1.2L34.6 67.4z"
  />
);
