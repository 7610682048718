import {
  Applications,
  Dashboard,
  Facebook,
  LeadManagement,
  Linkedin,
  Mobile,
  Nativious,
  PerformanceAdvertiser,
  PerformanceEMailing,
  PerformancePublisher,
  Performance,
  TimeoneComplet,
  TimeoneFooter,
  TimeoneHeaderBlue,
  TimeoneHeader,
  Twitter,
} from './logo';

import icons from './icon';

const logos = {
  Applications,
  Dashboard,
  Facebook,
  LeadManagement,
  Linkedin,
  Mobile,
  Nativious,
  PerformanceAdvertiser,
  PerformanceEMailing,
  PerformancePublisher,
  Performance,
  TimeoneComplet,
  TimeoneFooter,
  TimeoneHeaderBlue,
  TimeoneHeader,
  Twitter,
};

export default {
  logos,
  icons,
};
