import logo from 'tol@assets/logo/timeone_footer.svg';
import facebookLogo from 'tol@assets/logo/facebook.svg';
import twitterLogo from 'tol@assets/logo/twitter.svg';
import linkedinLogo from 'tol@assets/logo/linkedin.svg';
import colors from '../colors';

const socials = {
  facebook: {
    name: 'facebook',
    url: 'https://www.facebook.com/timeonegroup/',
    logo: facebookLogo,
  },
  twitter: {
    name: 'twitter',
    url: 'https://twitter.com/timeonegroup/',
    logo: twitterLogo,
  },
  linkedin: {
    name: 'linkedin',
    url: 'https://www.linkedin.com/company/timeone/',
    logo: linkedinLogo,
  },
};

export default ({ primary, secondary }) => ({
  fontColor: colors.white,
  fontColorSecondary: secondary,
  backgroundColor: primary,
  logo,
  socials,
});
