export default {
  progressBar: {
    backgroundColor: '#ececec',
    borderRadius: '10px',
    containerPadding: '2rem 0',
    fillerBackgroundColor: '#007ab3',
    height: '0.5rem',
    marginBottom: '1rem',
  },
};
