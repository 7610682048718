import dinero from 'dinero.js';
import numeral from 'numeral';
import moment from 'moment';

const defaultDineroLocale = 'en-US';

export default {
  text: ({ value }) => value,
  int: ({ value, locale = defaultDineroLocale, set = '0,0' }) =>
    dinero({ amount: Math.round(value * 100) })
      .setLocale(locale)
      .toFormat(set),
  float: ({ value, locale = defaultDineroLocale, set = '0,0.00' }) =>
    dinero({ amount: Math.round(value * 100) })
      .setLocale(locale)
      .toFormat(set),
  currency: ({ value, currency, locale = defaultDineroLocale, set = '$0,0.00' }) =>
    dinero({ amount: Math.round(value * 100), currency })
      .setLocale(locale)
      .toFormat(set),
  percentage: ({ value, set = '0.000%' }) => numeral(value).format(set),
  date: ({ value, locale = 'en', set = 'l' }) => {
    moment.locale(locale.slice(0, 2));
    return moment(value).format(set);
  },
};
