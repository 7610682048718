import colors from '../../colors';
import checkbox from './checkbox';
import datepicker from './datepicker';

export default ({ primary, secondary }) => ({
  fontColor: primary,
  borderColor: primary,
  active: {
    borderColor: primary,
  },
  error: {
    fontColor: colors.error,
  },
  datepicker: datepicker({ primary }),
  checkbox: checkbox({ primary, secondary }),
});
