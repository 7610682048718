import React from 'react';
import PropTypes from 'prop-types';

import { MenuContainer, MenuButton } from './styles';

function Menu({ activeKey, tabs, onClick, menuPosition }) {
  return (
    <MenuContainer menuPosition={menuPosition}>
      {tabs.map(tab => (
        <MenuButton
          type="button"
          key={tab.key}
          menuPosition={menuPosition}
          onClick={() => onClick(tab.key)}
          className={tab.key === activeKey ? 'active' : ''}
        >
          {tab.title}
        </MenuButton>
      ))}
    </MenuContainer>
  );
}

Menu.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  menuPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
      component: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    })
  ).isRequired,
};

export default Menu;
