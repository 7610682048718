const MESSAGES = {
  LOADING_DEFAULT_DATA: 'Loading data with default parameters...',
  LOADING_DATA: 'Reloading data with new parameters...',
  LOADING_EXPORT: 'Building your export...',
  SORT_WARNING: 'Are you sure to change the order ? It will reload data.',
  NO_COLUMNS_WARNING: 'You must choose at least 1 column !',
  NO_DATA_TO_DISPLAY: 'There is no rows to display...',
  ROWS_LIMIT: 'Only 1000 rows of data are available. In order to have the complete data set, use the export.',
  NETWORK_ERROR: 'An unexpected network error occured.',
  NETWORK_ERROR_RETRY: 'Try to refresh the whole dashboard in few seconds...',
  EXPORT_IS_AVAILABLE: 'Your export is now available.',
};

export default MESSAGES;
