import styled from 'styled-components';

import DefaultSnackbar from 'tol@elements/Snackbar';
import { utils } from 'tol@themes';

export const Snackbar = styled(DefaultSnackbar).attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  color: ${({ theme }) => theme.colors.white};

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const Container = styled.div`
  padding: 0 4rem;
`;

export default { Container, Snackbar };
