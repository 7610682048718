import React from 'react';

import elements from 'tol@elements';
import token, { handleFetchAuthenticationError } from '../token';

const { InputAutocomplete, InputCheckBox, InputSelect } = elements;

export default async (fieldsList, { withBearer = true } = {}) => {
  if (!Array.isArray(fieldsList)) {
    throw new Error(`fieldsList should be an array`);
  }

  const fieldsMap = {
    autocomplete: hasAutocompleteRequiredProps(InputAutocomplete),
    checkbox: hasRequiredProps(InputCheckBox),
    select: hasSelectRequiredProps(InputSelect),
  };

  const generatedFields = await Promise.all(
    fieldsList.map((field, index) => {
      const { type, slugs } = field;
      const props = { ...field, withBearer, key: index };

      if (slugs) {
        return fieldsMap.select({ ...props, options: slugs });
      }
      return type ? fieldsMap[type](props) : fieldsMap.checkbox(props);
    })
  );

  return generatedFields;
};

const generateComponent = ({ InputComponent, slug, ...fieldProps }) => <InputComponent {...fieldProps} name={slug} />;

const hasRequiredProps = InputComponent => (
  { slug = throwIfMissing('slug'), ...fieldProps } = throwIfMissing('slug')
) => {
  const props = {
    slug,
    ...fieldProps,
    className: `${fieldProps.className ? fieldProps.className : ''} tol-generate-form__checkbox`,
  };

  return slug && generateComponent({ InputComponent, ...props });
};

const generateSearchOptions = ({ providerUrl, labelMapping, valueMapping, withBearer }) => term =>
  fetch(`${providerUrl}${term}`, {
    mode: 'cors',
    headers: {
      Authorization: `${withBearer ? 'Bearer' : ''} ${token.get()}`,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  })
    .then(response => handleFetchAuthenticationError(response))
    .then(response =>
      // if (!response.ok) {
      //   throw new Error('Enable to fetch options');
      // }

      response.json()
    )
    .then(results => results && results.map(data => ({ label: data[labelMapping], value: data[valueMapping] })));

// const generateOptions = ({ providerUrl, labelMapping, valueMapping, withBearer }) =>
//   fetch(`${providerUrl}`, {
//     headers: {
//       Authorization: `${withBearer ? 'Bearer' : ''} ${token.get()}`,
//       accept: 'application/json',
//     },
//   })
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Enable to fetch options');
//       }

//       return response.json();
//     })
//     .then(results => results && results.map(data => ({ label: data[labelMapping], value: data[valueMapping] })));

const hasAutocompleteRequiredProps = (InputComponent, options) => (
  {
    slug = throwIfMissing('slug'),
    providerUrl = throwIfMissing('providerUrl'),
    optionsMapping: { label = throwIfMissing('label'), value = throwIfMissing('value') } = throwIfMissing(
      'optionsMapping'
    ),
    withBearer,
    ...fieldProps
  } = throwIfMissing('slug')
) => {
  const searchOptions = generateSearchOptions({
    options,
    providerUrl,
    labelMapping: label,
    valueMapping: value,
    withBearer,
  });
  const props = {
    slug,
    searchOptions,
    ...fieldProps,
    isMulti: true,
    className: `${fieldProps.className ? fieldProps.className : ''} tol-generate-form__autocomplete`,
  };

  return slug && label && value && generateComponent({ InputComponent, ...props });
};

const hasSelectRequiredProps = InputComponent => async (
  { label = throwIfMissing('label'), options = throwIfMissing('options'), ...fieldProps } = throwIfMissing('slug')
) => {
  const formattedOptions = options.map(val => ({ label: val, value: val }));

  const props = {
    slug: label === 'Date' ? 'date' : fieldProps.slug,
    label,
    options: label === 'Date' ? [{ label: 'No display', value: false }, ...formattedOptions] : formattedOptions,
    ...fieldProps,
    className: `${fieldProps.className ? fieldProps.className : ''} tol-generate-form__select`,
  };

  return label && generateComponent({ InputComponent, ...props });
};

function throwIfMissing(parameter) {
  throw new Error(`Missing props ${parameter}`);
}
