import React from 'react';
import PropTypes from 'prop-types';

import defaultTheme from 'tol@themes';
import randomStringId from 'tol@utils/random';
import FooterStyled from './styles';

class Footer extends React.PureComponent {
  render() {
    const { signature, logo, socials, className, customStyle } = this.props;

    return (
      <FooterStyled className={`footer ${className}`} customStyle={customStyle}>
        <span className="footer__left-signature">{signature}</span>
        <img className="footer__center-main-logo" src={logo} alt="logo timeone footer" />
        <div className="footer__right-social">
          {socials.map(social => (
            <a
              key={`social-item${randomStringId()}`}
              href={social.url}
              target="_blank"
              rel="noreferrer noopener"
              className={`footer__right-social__item footer__right-social__item_${social.name}`}
            >
              <img src={social.logo} alt={`logo ${social.name}`} />
            </a>
          ))}
        </div>
      </FooterStyled>
    );
  }
}

Footer.defaultProps = {
  signature: (
    <p>
      Product realized by
      <span>
        {' '}
        <a href="https://www.timeonegroup.com/" target="_blank" rel="noopener noreferrer">
          Time
          <strong>One</strong>
        </a>
      </span>
    </p>
  ),
  logo: defaultTheme.footer.logo,
  socials: [
    defaultTheme.footer.socials.facebook,
    defaultTheme.footer.socials.twitter,
    defaultTheme.footer.socials.linkedin,
  ],
  className: '',
  customStyle: '',
};

Footer.propTypes = {
  signature: PropTypes.element,
  logo: PropTypes.string,
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      logoUrl: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  customStyle: PropTypes.string,
};

export default Footer;
