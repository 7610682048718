import logoUrl from 'tol@assets/logo/publishing.svg';
import themeGenerator from '../default';
import colors from '../colors';

const defaultTheme = themeGenerator(colors.publishing);

export default {
  ...defaultTheme,
  name: 'Publishing',
  header: {
    ...defaultTheme.header,
    logo: {
      url: logoUrl,
    },
  },
};
