import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';

import defaultTheme from 'tol@themes';
import Header from 'tol@blocks/Header';
import HeaderNavbar from 'tol@blocks/HeaderNavbar';
import SidebarNav from 'tol@blocks/SidebarNav';
import { Sidebar } from 'tol@framework/Sidebar';
import { Breadcrumb } from 'tol@framework/Breadcrumb';
import Footer from 'tol@blocks/Footer';
import ApplicationLayoutLoader from './ApplicationLayoutLoader';
import ApplicationLayoutProvider from './ApplicationLayout.provider';
import ApplicationLayoutStyled from './styles';

class ApplicationLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSidebarOpen: false,
      user: null,
      error: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let derivedState = null;

    if (props.user && props.user !== state.user) {
      derivedState = { ...derivedState, user: props.user };
    }

    return derivedState;
  }

  handleOnLogout = () => {
    const { onLogout } = this.props;

    this.setState({ user: null });
    return onLogout && onLogout();
  };

  toggleSidebar = () => {
    const { isSidebarOpen } = this.state;

    this.setState(prevState => ({ isSidebarOpen: !prevState.isSidebarOpen }));

    return !isSidebarOpen;
  };

  componentDidCatch(error) {
    this.setState({ error: error.message });
  }

  render() {
    const { user, isSidebarOpen, error } = this.state;
    const {
      className,
      customStyle,
      notifications,
      sidebarProps,
      headerProps,
      headerNavbarProps,
      children,
      footerProps,
      theme,
      withBreadcrumb,
    } = this.props;

    return (
      <BrowserRouter>
        <ApplicationLayoutStyled
          className={`default-layout theme-${theme.name} ${className}`}
          customStyle={customStyle}
        >
          <ApplicationLayoutProvider>
            <>
              <Header
                notifications={notifications}
                openSidebar={sidebarProps ? this.toggleSidebar : undefined}
                user={user}
                onLogout={user ? this.handleOnLogout : undefined}
                {...headerProps}
              />
              {headerNavbarProps && (
                <HeaderNavbar
                  selectedEntry={headerNavbarProps.entries[0]}
                  entries={headerNavbarProps.entries}
                  {...headerNavbarProps}
                />
              )}
              {sidebarProps && (
                <SidebarNav
                  title="TimeOne Tools"
                  entries={sidebarProps.entries}
                  isSidebarOpen={isSidebarOpen}
                  toggleSidebar={this.toggleSidebar}
                  itemOnClick={sidebarProps.itemOnClick}
                  {...sidebarProps}
                />
              )}
              <div className="default-layout__content">
                <ApplicationLayoutLoader />
                <div className="default-layout__children">
                  {withBreadcrumb ? (
                    <>
                      <Breadcrumb />
                      {error || children}
                    </>
                  ) : (
                    error || children
                  )}
                </div>
                <Sidebar />
              </div>
              <Footer {...footerProps} />
            </>
          </ApplicationLayoutProvider>
        </ApplicationLayoutStyled>
      </BrowserRouter>
    );
  }
}

export default ApplicationLayout;

const entriesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
    subEntries: entriesPropTypes,
  })
);

ApplicationLayout.defaultProps = {
  theme: defaultTheme,
  customStyle: null,
  user: null,
  headerProps: null,
  headerNavbarProps: null,
  sidebarProps: null,
  footerProps: {},
  className: '',
  notifications: null,
  onLogout: () => {},
  children: null,
  withBreadcrumb: true,
};
ApplicationLayout.propTypes = {
  theme: PropTypes.shape({}),
  user: PropTypes.shape({}),
  headerProps: PropTypes.shape({}),
  customStyle: PropTypes.string,
  headerNavbarProps: PropTypes.shape({
    className: PropTypes.string,
    customStyle: PropTypes.string,
    entries: entriesPropTypes,
    selectedEntry: PropTypes.shape({}),
  }),
  sidebarProps: PropTypes.shape({
    entries: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        logoUrl: PropTypes.string,
        title: PropTypes.string,
        colorName: PropTypes.string,
        url: PropTypes.string,
        subItems: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    toggleSidebar: PropTypes.func,
  }),
  footerProps: PropTypes.shape({}),
  className: PropTypes.string,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      content: PropTypes.string,
      dateTime: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  onLogout: PropTypes.func,
  children: PropTypes.element,
  withBreadcrumb: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
