import { lighten } from 'polished';

import colors from '../colors';

export default ({ primary, secondary }) => ({
  fontColor: secondary,
  backgroundColor: primary,
  active: {
    fontColor: colors.white,
    backgroundColor: secondary,
  },
  subEntry: {
    hover: {
      backgroundColor: lighten(0.72, primary),
    },
  },
});
