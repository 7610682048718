import styled, { css } from 'styled-components';

import { utils } from 'tol@themes';

export default styled.div.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  display: block;
  position: relative;
  text-align: center;

  &:hover {
    .header__header-right__notifications__index {
      opacity: 1;
      top: 3rem;
      visibility: visible;
    }

    .header__header-right__notifications__icon {
      fill: ${({ theme }) => theme.notification.header.menu.svg.hover.fontColor};
    }
  }

  ${({ theme }) => notificationIcon(theme.notification.header.menu)};
  ${({ theme }) => notificationList(theme.notification.header.menu)};
`;

function notificationIcon({ status, svg }) {
  return css`
    .header__header-right__notifications__icon {
      fill: ${svg.fontColor};
      height: 1.75rem;
      margin: 0.25rem 1rem 0;
      width: 1.75rem;
    }

    .header__header-right__notifications__status {
      align-items: center;
      background-color: ${status.backgroundColor};
      border-radius: 50%;
      color: ${status.fontColor};
      display: flex;
      font-size: 0.75rem;
      font-weight: 700;
      height: 1.1rem;
      justify-content: center;
      position: absolute;
      right: 0.75rem;
      top: 0.25rem;
      width: 1.1rem;
    }
  `;
}

function notificationList({ author, date, list }) {
  return css`
    .header__header-right__notifications__index {
      background-color: ${list.backgroundColor};
      border-radius: 3px;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
      max-height: 32rem;
      overflow: scroll;
      padding: 0.5rem;
      position: absolute;
      opacity: 0;
      right: 0;
      top: 3rem;
      transition: all 0.25s ease;
      visibility: hidden;
      width: 24rem;
      z-index: 3;

      .header__header-right__notifications__index__item.notification {
        width: 100%;

        div {
          display: block;
          height: 100%;
          padding: 0.5rem;
          position: relative;
          text-align: left;
          width: 100%;

          &:hover {
            cursor: pointer;
            background-color: ${list.hover.backgroundColor};
          }

          .notification__author {
            background-color: ${author.backgroundColor};
            border-radius: 3px;
            color: ${author.fontColor};
            display: inline;
            font-size: 0.85rem;
            font-weight: bold;
            margin: 0.25rem 0.75rem 0 0;
            padding: 0.2rem 0.4rem;
            text-transform: uppercase;
          }

          .notification__date {
            color: ${date.fontColor};
            font-size: 0.9rem;
            margin-top: 0.75rem;
          }

          .notification__since {
            font-size: small;
          }

          .notification__title {
            font-weight: 700;
            padding-top: 0.5rem;
          }

          .notification__content {
            font-size: 0.8rem;
            margin: 0.5rem 1rem 1.25rem 0;
            opacity: 0.75;
          }
        }
      }
    }
  `;
}
