import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Content({ activeKey, tabsHash }) {
  return <Container>{tabsHash[activeKey] && tabsHash[activeKey].component}</Container>;
}

Content.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tabsHash: PropTypes.shape({}).isRequired,
};

export default Content;
