import { gql } from 'apollo-boost';

export const GET_ME = gql`
  query getMe {
    getMe {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_USER_RESOURCES = gql`
  query getUserResources($id: ID!) {
    getUserResources(id: $id) {
      platform {
        id
        name
        logoPath
      }
      resources {
        publisher {
          id
          firstname
          lastname
          civility
          company
          address
          postalCode
          city
          country
          legacyId
        }
        advertiser {
          id
          name
          servicePlatformId
          legacyId
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserInput) {
    updateUser(id: $id, input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($id: ID!, $input: PasswordInput) {
    updatePassword(id: $id, input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`;

export default { GET_ME, UPDATE_USER, UPDATE_PASSWORD, GET_USER_RESOURCES };
