import React, { Component } from 'react';
import PropTypes from 'prop-types';

import randomStringId from 'tol@utils/random';
import WizardHeaderStyled, { WizardHeaderWrapperStyled, WizardHeaderStepStyled } from './styles';

export default class WizardHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      move: 'next',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.page < prevState.page) {
      return { move: 'previous', page: nextProps.page };
    }

    if (nextProps.page > prevState.page) {
      return { move: 'next', page: nextProps.page };
    }

    return null;
  }

  getActiveClass(index) {
    const { page, validatingStep } = this.props;
    const { move } = this.state;

    switch (true) {
      case validatingStep:
        return index === page ? 'actived' : '';
      case index === page && move === 'next':
        return 'active-in';

      case index === page && move !== 'next':
        return 'actived';

      case index - 1 === page && move !== 'next':
        return 'active-out';

      case index < page:
        return 'actived';

      default:
        return '';
    }
  }

  render() {
    const { stepsName } = this.props;

    return (
      <WizardHeaderStyled className="tol-wizard-header">
        {stepsName.map((stepName, index) => (
          <WizardHeaderWrapperStyled key={randomStringId()} className="tol-wizard-header-wrapper">
            <WizardHeaderStepStyled className={`tol-wizard-header-step ${this.getActiveClass(index)}`}>
              <div className="tol-wizard-header-step-number">
                <span>{index + 1}</span>
                {stepName}
              </div>
              <div className="progressbar">
                <div className={`progress ${this.getActiveClass(index)}`} />
              </div>
            </WizardHeaderStepStyled>
          </WizardHeaderWrapperStyled>
        ))}
      </WizardHeaderStyled>
    );
  }
}

WizardHeader.propTypes = {
  stepsName: PropTypes.arrayOf(PropTypes.string).isRequired,
  page: PropTypes.number.isRequired,
  validatingStep: PropTypes.bool.isRequired,
};
