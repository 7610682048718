import moment from 'moment';
import _ from 'lodash';

const isRequired = _.memoize(val => !(!!val && val.length > 0) && 'Should not be empty');
const isRequiredSelect = _.memoize(
  val =>
    !(
      !!val &&
      !Array.isArray(val) &&
      Object.keys(val).length === 2 &&
      Object.prototype.hasOwnProperty.call(val, 'label') &&
      Object.prototype.hasOwnProperty.call(val, 'value')
    ) && 'Should not be empty'
);
const isNumber = _.memoize(val => !(val === 0 || (val && !Number.isNaN(Math.floor(val)))) && 'Should be a number');
const isRequiredDatePicker = _.memoize(
  val => !(!!val && (moment.isMoment(val) ? val.isValid() : isValidMoment(val))) && 'Should not be empty'
);

const isRequiredDateRangePicker = _.memoize(
  val =>
    !(
      !!val &&
      !!val.startDate &&
      !!val.endDate &&
      (moment.isMoment(val.startDate) ? val.startDate.isValid() : isValidMoment(val.startDate)) &&
      (moment.isMoment(val.endDate) ? val.endDate.isValid() : isValidMoment(val.endDate))
    ) && 'Should not be empty'
);

export {
  isRequired as default,
  isRequired,
  isRequiredSelect,
  isRequiredDatePicker,
  isRequiredDateRangePicker,
  isNumber,
};

function isValidMoment(val) {
  const momentValue = moment(val);

  return momentValue.isValid();
}
