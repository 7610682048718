import { getConfig } from 'tol@framework/TimeOneConfig/configStore';
import { LoginError } from './errors';

const get = () => {
  const token = document.cookie
    .split('; ')
    .map(attribute => {
      const [key, value] = attribute.split('=');

      return key === 'token' ? value : null;
    })
    .filter(Boolean)
    .pop();

  if (token) {
    return token;
  }

  throw new LoginError('Not logged');
};

const clear = (cookieDomain = throwIfMissing('cookieDomain')) => {
  document.cookie = `token=; domain=${cookieDomain}`;
};

const redirectToSSO = (authenticationUrl = throwIfMissing('authenticationUrl')) => {
  const ssoRedirection = `${authenticationUrl}?redirect_uri=${window.location.href}`;

  window.location.assign(ssoRedirection);
};

export const handleFetchAuthenticationError = response => {
  const {
    urls: { cookieDomain, authenticationUrl },
  } = getConfig();

  if (![401].includes(response.status)) {
    localStorage.removeItem('authentificationErrorRetry');

    return response;
  }

  const authErrorRetried = localStorage.getItem('authentificationErrorRetry');

  if (authErrorRetried) {
    return response;
  }

  localStorage.setItem('authentificationErrorRetry', true);
  clear(cookieDomain);

  redirectToSSO(authenticationUrl);

  return response;
};

async function validateToken() {
  const {
    urls: { oauthTokenUrl },
  } = getConfig();
  const accessToken = get();

  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const fetchResponse = await fetch(oauthTokenUrl, options);

    if (!fetchResponse.ok) {
      throw new LoginError('Token invalid');
    }

    const response = await fetchResponse.json();

    if (response.errors) {
      throw new LoginError('Token invalid');
    }

    if (!response.active) {
      throw new LoginError('Token invalid');
    }

    return response;
  } catch (error) {
    throw new LoginError('Token invalid');
  }
}

export default { get, clear, redirectToSSO, handleFetchAuthenticationError, validateToken };

const throwIfMissing = parameter => {
  throw new LoginError(`Missing parameter ${parameter}`);
};
