import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// import DatePicker from './DatePicker';
// import Select from './Select';

const InputLayout = styled.label`
  appearance: none;
  display: block;
  margin: 0.75rem 0.5rem 0.25rem;
  position: relative;
  width: calc(100% - 1rem);

  .input__label {
    position: absolute;
    top: 1.25rem;
    left: 0.5rem;
    text-transform: capitalize;
    transition: all 0.5s cubic-bezier(0.42, 0, 0.18, 1.04);
  }

  .input__field {
    appearance: none;
    background: none;
    border: none;
    border-bottom: 1px solid var(--font-color);
    border-width: 1px !important;
    box-shadow: none;
    color: var(--font-color);
    font-size: 1rem;
    font-weight: lighter;
    margin-top: 1.5rem;
    padding: 0 0.25rem 0.375rem;
    outline: none;
    transition: all 1s;
    width: calc(100% - 1rem);

    &::placeholder {
      visibility: hidden;
    }
  }

  &::after {
    background: ${({ colorName }) => `var(--${colorName})`};
    bottom: 0;
    content: '';
    display: block;
    left: 25%;
    height: 2px;
    position: absolute;
    transition: all 0.5s;
    width: 0;
  }

  &.input--date {
    .input__label {
      top: 0;
      font-size: 0.9em;
      opacity: 1;
    }

    .input__field {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &.input--number {
    .input__label {
      text-transform: none !important;
    }

    .input__field {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  &.input--active {
    .input__label {
      top: 0;
      font-size: 0.9em;
      opacity: 0.75;
    }

    .input__field {
      border-bottom: 1px solid transparent;

      &::placeholder {
        visibility: visible;
      }
    }

    &::after {
      left: 0;
      width: 100%;
    }
  }

  &.input--range {
    height: 2.5rem;
    margin: 0 0.5rem 1rem !important;
    width: calc(100% - 1rem);

    .input__label {
      font-size: 1em;
      left: 0;
      opacity: 1;
      top: 0;
    }

    .input__field {
      border: none;
      bottom: 0;
      margin: 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      z-index: 2;
      width: 100% !important;
    }

    &::before {
      background: var(--font-color);
      border-radius: 4px;
      bottom: 0.25rem;
      content: '';
      display: block;
      height: 8px;
      left: 0;
      min-width: 5%;
      position: absolute;
      width: ${({ position }) => position * 0.96}%;
      z-index: 0;
    }

    &::after {
      background: rgba(48, 55, 71, 0.25);
      border-radius: 4px;
      bottom: 0.25rem;
      content: '';
      display: block;
      height: 8px;
      left: 0;
      position: absolute;
      transition: all 0.5s;
      width: calc(100% - 0.75rem);
      z-index: 0;
    }

    div {
      background-color: var(--font-color);
      border-radius: 50%;
      bottom: -0.125rem;
      height: 1.25rem;
      left: ${({ position }) => position * 0.92}%;
      position: absolute;
      width: 1.25rem;

      &::after {
        bottom: -1.25rem;
        color: var(--font-size);
        content: ${({ value }) => `"${value}"`};
        font-size: 0.75rem;
        left: ${({ position }) => position * 0.2}%;
        position: absolute;
      }
    }
  }

  &.input--hidden {
    visibility: collapse;
  }

  &.input--invalid {
    .input__label {
      color: var(--red);
    }

    &::after {
      background: var(--red);
    }
  }

  &.required {
    .input__label::after {
      color: ${({ colorName }) => (colorName ? `var(--${colorName})` : 'var(--theme-color)')};
      content: '*';
      font-size: 1.25rem;
      position: absolute;
      right: -0.625rem;
      top: 0;
    }
  }

  ${({ customStyle }) => customStyle};
`;

export default class Input extends React.PureComponent {
  constructor(props) {
    super(props);

    const { defaultValue } = this.props;

    if (defaultValue) {
      this.state = {
        value: defaultValue,
        active: true,
      };
    } else {
      this.state = {
        value: '',
        active: false,
      };
    }
  }

  componentDidUpdate() {
    const { type } = this.props;

    return type === 'number' && this._renderNumberLabel();
  }

  onInputBlur = () => {
    const { active, value } = this.state;

    return (
      active &&
      value.length <= 0 &&
      this.setState({
        active: false,
      })
    );
  };

  static getDerivedStateFromProps(props, state) {
    if ((typeof props.value === 'string' || typeof props.value === 'number') && props.value !== state.value) {
      if (props.value.length >= 1) {
        return { active: true, value: props.value };
      }
      return { active: false, value: props.value };
    }
    return null;
  }

  handleChange = event => {
    const { handleChange, onChange } = this.props;

    if (handleChange) {
      return handleChange(event);
    }
    this.setState({ value: event.target.value });

    return onChange && onChange(event);
  };

  handleSelectChange = (newValue, action, inputId) => {
    const { handleChange } = this.props;

    return handleChange ? handleChange(newValue, action, inputId) : this.setState({ value: newValue });
  };

  _renderNumberLabel = () => {
    const regex = /[^\.\d\,]/m;
    const { active, value } = this.state;
    const { label } = this.props;

    if (active && value && value.length >= 1) {
      const numberLabel = regex.test(value) ? 'Must be a number' : label;

      // FIXME:
      // numberLabel state is never used, WHY ?
      return this.setState({ numberLabel });
    }
    return this.setState({ label });
  };

  // FIXME:
  // We do not want chained conditional. Instead create a simple component with the logic.
  // Then wrap it with a component with deal with the render
  // AKA render props
  render() {
    const { value, active } = this.state;
    const {
      // global input
      label,
      type,
      onInput,
      required,
      disabled,
      // range & number
      step,
      min,
      max,
      // date
      onDateChange,
      // select
      options,
      // others
      className,
      colorName,
      customStyle,
    } = this.props;

    // if (type === 'date') {
    //   return (
    //     <DatePicker
    //       label={label}
    //       colorName={colorName}
    //       customStyle={customStyle}
    //       date={value}
    //       {...this.props}
    //       onDateChange={onDateChange || undefined}
    //       className={`input ${className}${active ? ' input--active' : ''} input--${type} ${
    //         required ? ' required' : ''
    //       }`}
    //     />
    //   );
    // }
    // if (type === 'select') {
    //   return (
    //     <Select
    //       options={options}
    //       classNamePrefix="select"
    //       colorName={this.props.colorName}
    //       placeholder={label}
    //       defaultValue={this.state.value}
    //       closeMenuOnSelect
    //       onChange={(newValue, action) => this.handleSelectChange(newValue, action, this.props.index)}
    //       {...this.props}
    //       className={`input input--${this.props.type} ${this.props.className}${
    //         this.state.active ? ' input--active' : ''
    //       } ${this.props.required ? ' required' : ''}`}
    //     />
    //   );
    // }
    if (type === 'number') {
      return (
        <InputLayout
          onClick={() => this.setState({ active: true })}
          className={`input ${this.props.className}${this.state.active ? ' input--active' : ''} input--${
            this.props.type
          } ${this.props.required ? ' required' : ''}${
            this.state.label && this.state.label === 'Must be a number' ? ' input--invalid' : ''
          }`}
          colorName={this.props.colorName}
          customStyle={this.props.customStyle}
        >
          <span className="input__label">{this.state.label || label}</span>
          <input
            type="text"
            value={this.state.value}
            onChange={this.handleChange}
            onInput={onInput || undefined}
            onFocus={() => this.setState({ active: true })}
            onBlur={this.onInputBlur}
            className="input__field"
            required={required || (this.state.label && this.state.label === 'Must be a number') ? required : undefined}
            disabled={disabled || undefined}
            step={step || undefined}
            min={min || undefined}
            max={max || undefined}
          />
        </InputLayout>
      );
    }
    if (type === 'range') {
      return (
        <InputLayout
          onClick={() => this.setState({ active: true })}
          className={`input ${this.props.className}${this.state.active ? ' input--active' : ''} input--${
            this.props.type
          } ${this.props.required ? ' required' : ''}`}
          colorName={this.props.colorName}
          customStyle={this.props.customStyle}
          position={Math.round(((this.state.value - min) / (max - min)) * 100)}
          value={this.state.value}
        >
          <span className="input__label">{label}</span>
          <input
            type={type}
            value={this.state.value}
            onChange={this.handleChange}
            onInput={onInput || undefined}
            onFocus={() => this.setState({ active: true })}
            onBlur={this.onInputBlur}
            className="input__field"
            required={required || undefined}
            disabled={disabled || undefined}
            step={step || undefined}
            min={min || undefined}
            max={max || undefined}
          />
          <div />
        </InputLayout>
      );
    }
    return (
      <InputLayout
        onClick={() => this.setState({ active: true })}
        className={`input ${this.props.className}${this.state.active ? ' input--active' : ''} input--${
          this.props.type
        } ${this.props.required ? ' required' : ''}`}
        colorName={this.props.colorName}
        customStyle={this.props.customStyle}
      >
        <span className="input__label">{label}</span>
        <input
          type={type}
          value={this.state.value}
          onChange={this.handleChange}
          onInput={onInput || undefined}
          onFocus={() => this.setState({ active: true })}
          onBlur={this.onInputBlur}
          className="input__field"
          required={required || undefined}
          disabled={disabled || undefined}
          step={step || undefined}
          min={min || undefined}
          max={max || undefined}
        />
      </InputLayout>
    );
  }
}

Input.defaultProps = {
  label: '',
  type: 'text',
  className: '',
  colorName: 'gray',
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  handleChange: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  colorName: PropTypes.string,
  // style formatted for styled components inside ``
  customStyle: PropTypes.string,
};
