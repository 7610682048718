import styled from 'styled-components';
import { utils } from 'tol@themes';

export default styled.textarea.attrs(({ theme }) => ({
  theme: utils.themeFallback(theme),
}))`
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: lighter;
  margin: 1.5rem 0 0.25rem 0;
  outline: none;
  padding: 0 0.25rem 0.375rem;
  transition: all 1s;
  width: calc(100% - 0.5rem);

  &::placeholder {
    visibility: hidden;
  }

  &.active::placeholder {
    visibility: visible;
  }
`;
