import React from 'react';
import { Field } from 'react-final-form';

import { Label, LabelError, InputCheckmark } from './styles';
import DefaultInput from '../DefaultInput';

export default class InputCheckBox extends DefaultInput {
  render() {
    const { name, label, required, className } = this.props;

    return (
      <Field name={name} subscription={{ touched: true, active: true, data: true, initial: true, error: true }}>
        {({ meta }) => (
          <Field type="checkbox" name={name} validate={this.handleValidate}>
            {({ input }) => (
              <Label className={`tol-input__checkbox ${input.value ? 'active' : ''}${className}`} htmlFor={name}>
                <InputCheckmark className={`input-check-mark ${input.value ? 'active' : ''}`} />
                <input
                  id={name}
                  name={name}
                  type="checkbox"
                  {...input}
                  onChange={this.handleChange(input.onChange, input.onBlur)}
                  onFocus={this.handleFocus(input.onFocus)}
                  onBlur={this.handleBlur(input.onBlur)}
                />
                <span required={required} className={input.value ? 'active' : ''}>
                  {this.handleIsInValide(meta) ? <LabelError>{meta.error}</LabelError> : label}
                </span>
              </Label>
            )}
          </Field>
        )}
      </Field>
    );
  }
}
