import React from 'react';
import PropTypes from 'prop-types';

import { GreenSelectActionButton, LabelContainer, RedSelectActionButton } from './styles';

const CreateLabel = ({ className, label, onYesClick }) => (
  <LabelContainer className={className}>
    <span>
      Add
      <strong>{` ${label} `}</strong>?
    </span>
    <div>
      <GreenSelectActionButton type="button" onClick={onYesClick}>
        Yes
      </GreenSelectActionButton>
      <RedSelectActionButton type="button" onClick={() => null}>
        No
      </RedSelectActionButton>
    </div>
  </LabelContainer>
);

export default CreateLabel;

CreateLabel.defaultProps = {
  className: '',
  label: '',
};

CreateLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onYesClick: PropTypes.func.isRequired,
};
